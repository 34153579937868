import React from "react";

import { Col, Row, Form, Button } from "react-bootstrap";


export const CustomerSupportForm = (props) => {

  let customerSupportFormData = { 'user': props.currentUserData };

  function setFormValues(e) {
    customerSupportFormData[e.target.name] = e.target.value;
  }

  function formSubmit() {
    props.submitForm(customerSupportFormData);
    customerSupportFormData = {};
  }

  return (
    <>
      <Row>
        <Col>

            <Form id="customerSupportForm" onSubmit={ (e) => formSubmit(e.preventDefault()) }>

              <Row>
                <Col className="mb-3">
                  <Form.Group id="message">
                    <Form.Label>Message</Form.Label>
                    <Form.Control name="message" required as="textarea" rows={10} type="text" onChange={setFormValues} onBlur={setFormValues} />
                  </Form.Group>
                </Col>
              </Row>

              <div className="mt-3 d-flex justify-content-evenly">
                <Button variant="success" type="submit" className="px-4">Submit</Button>
              </div>

            </Form>

        </Col>
      </Row>
    </>
  );
};
