import React from "react";
import S3 from "react-aws-s3";
import Dropzone from "react-dropzone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row } from "react-bootstrap";

export const ManageImageGallery = (props) => {

  // const awsS3url = 'https://'+process.env.REACT_APP_AWS_S3_BUCKET_NAME+'.s3-'+process.env.REACT_APP_AWS_S3_BUCKET_LOCATION+'.amazonaws.com/';
  const ReactS3Client = new S3({
    bucketName: process.env.REACT_APP_AWS_S3_BUCKET_NAME,
    dirName: 'gallery/'+props.data._id,
    region: process.env.REACT_APP_AWS_S3_BUCKET_LOCATION,
    accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY
  });

  const fileUpload = (acceptedFiles) => {
    let gallery = props.data.hasOwnProperty('gallery') ? props.data.gallery : [];
    for (let i = 0; i < Object.keys(acceptedFiles).length; i++) {
      if ( ['image/jpeg', 'image/jpg', 'image/png'].includes(acceptedFiles[i].type) ) {
        if ( acceptedFiles[i].size < 1e+7 ) {
          ReactS3Client
            .uploadFile( acceptedFiles[i] )
            .then(
              res => {
                gallery.push('https://'+process.env.REACT_APP_AWS_S3_BUCKET_NAME+'.s3-'+process.env.REACT_APP_AWS_S3_BUCKET_LOCATION+'.amazonaws.com/'+res.key);
                if ( (i+1) === Object.keys(acceptedFiles).length ) {
                  props.updateData({gallery});
                }
              }
            )
            .catch(
              err => {
                // console.error(err);
              }
            );
        } else {
          window.alert('File size is too large, please upload images smaller than 2MB.');
        }
      } else {
        window.alert('Invalid file format: '+acceptedFiles[i].type+'. Please use JPG or PNG');
      }
    }
  }

  const deleteImage = (i) => {
    let formData = {};
    formData.gallery = props.data.hasOwnProperty('gallery') ? props.data.gallery : [];
    ReactS3Client
      .deleteFile( props.data.gallery[i].replace('https://'+process.env.REACT_APP_AWS_S3_BUCKET_NAME+'.s3-'+process.env.REACT_APP_AWS_S3_BUCKET_LOCATION+'.amazonaws.com/gallery/'+props.data._id+'/', '') )
      .then(
        res => {
            formData.gallery.splice(i, 1);
            props.updateData(formData);
        }
      )
      .catch(
        err => {
          // console.error(err);
        }
      );
  }

  const selectFeaturedImage = (i) => {
    let formData = {};
    formData.gallery = props.data.hasOwnProperty('gallery') ? props.data.gallery : [];
    let selectedImageURL = formData.gallery[i];
    formData.gallery = formData.gallery.filter(image => image !== selectedImageURL);
    formData.gallery.unshift(selectedImageURL);
    props.updateData(formData);
  }

  return (
    <div className="manageImageGallery mb-4">

      <h4>Photo Gallery</h4>

      <Dropzone onDrop={acceptedFiles => fileUpload(acceptedFiles)}>
        {
          ({getRootProps, getInputProps}) => (
            <section className="dropzone text-center border border-3 border-dashed rounded-3 mb-4">
              <div {...getRootProps()} className="py-5">
                <input {...getInputProps()} />
                <strong>
                  Place images here or click to select images 
                  <br />
                  (JPG or PNG)
                </strong>
              </div>
            </section>
          )
        }
      </Dropzone>

      {props.data && props.data.gallery &&
        <>
          <h6 className="text-center">Click to select the Featured Image</h6>
          <Row className="image-gallery d-flex">
            {
              props.data.gallery.map(function (imageURL, i) {
                return (
                  <Col md={4} key={i} className={`image image-${i} position-relative`}>
                    <div 
                      className={`image-selector border border-2 ${!i && 'border-secondary'} rounded-3 my-2`} 
                      title="Set this as Featured Image" 
                      onClick={() => selectFeaturedImage(i)}>
                      <img src={imageURL} alt="" />
                    </div>
                    <div className="toggles">
                      <FontAwesomeIcon 
                        icon={faTrashAlt} 
                        className={`image-delete delete-${i} position-absolute top-0 end-0`}
                        title="Delete Image"
                        onClick={() => deleteImage(i)} 
                      />
                    </div>
                  </Col>
                );
              })
            }
          </Row>
        </>
      }

    </div>
  );
}
