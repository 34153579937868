import React, { useState, useEffect } from "react";
import { useParams, Redirect, Link } from "react-router-dom";
import { Routes } from "../../routes";
import Cookies from "js-cookie";

import UserService from "../../services/user.service";

import { PropertiesTable, ListingsTable } from "../../components/Tables";
import { ManageUserInfo, ManageUserAddress, ManageUserPassword } from "../../components/forms/manageUser";
import { BookingsCalendar } from "../../components/Calendars";
import { statesList } from "../../components/data/statesList";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkedAlt, faChartPie, faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Card, Nav, Tab } from "react-bootstrap";


const UserProfile = (props) => {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const thisUserID = useParams(); // Query Variable = user id

  const [queryStatusUser, setQueryStatusUser] = useState(false);
  const [thisUserData, setThisUserData] = useState([]);
  const [propertiesList, setPropertiesList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  // const [bookingsList, setBookingsList] = useState([]);
  const [guestBookingsList, setGuestBookingsList] = useState([]);

  useEffect(() => {
    if ( !queryStatusUser ) {
      UserService.getUserFromId(thisUserID.id, authToken)
        .then(res => {
          setQueryStatusUser(res.status);

          if ( res.status === 200 ) {

            setThisUserData(res.body);
            setPropertiesList(res.body.properties);

            // compile listings & bookings hosted by user
            let hostListings = [];
            let hostBookings = [];
            for ( let iA = 0; iA < res.body.properties.length; iA++ ) {
              if ( res.body.properties[iA].listings.length ) {
                for ( let iB = 0; iB < res.body.properties[iA].listings.length; iB++ ) {
                  // compile listings
                  hostListings.push(res.body.properties[iA].listings[iB]);

                  if ( res.body.properties[iA].listings[iB].bookings.length ) {
                    for ( let iC = 0; iC < res.body.properties[iA].listings[iB].bookings.length; iC++ ) {
                      // compile bookings
                      hostBookings.push(res.body.properties[iA].listings[iB].bookings[iC]);
                    }
                  }

                }
              }
            }
            setListingsList(hostListings);
            // setBookingsList(hostBookings);
            setGuestBookingsList(res.body.guest_bookings);

            // TODO : compile & present guest_bookings
          } else {
            props.history.push(Routes.NotFound.path);
          }

        })
    }
  }, [thisUserID, currentUserData]);


  useEffect(() => {
    let name = ( thisUserData.firstName || thisUserData.lastName ) ? thisUserData.firstName+" "+thisUserData.lastName : thisUserData.email;
    if (name) {
      document.title = name+" | User Profile | REC-X";
    }
  }, [thisUserData]);


  const [activeKey, setActiveKey] = useState('user-info');
  useEffect(() => {
    if ( currentUserData.roles.includes('ROLE_ADMIN') ) setActiveKey('user-host');
  }, [thisUserData]);



  // Check User viewing Profile is an admin or the User themself
  if ( currentUserData.id === thisUserID.id || currentUserData.roles.includes('ROLE_ADMIN') ) {

    const calEventSelected = (e) => {
      props.history.push("/dashboard/booking/"+e.event.id);
      window.location.reload();
    }

    let updateUserFormData = {};
    const updateUser = (e) => {

      // Handle User Info update
      if ( Object.keys(updateUserFormData).length > 0 ) {
        UserService.updateUser(thisUserData._id, updateUserFormData, authToken)
          .then( async (res) => {
/*
            // update Cookie data so protected-route.js won't redirect to UserProfile after required fields are populated
            if ( 'firstName' in updateUserFormData || 'lastName' in updateUserFormData || 'address' in updateUserFormData ) {
console.log(Cookies.get('authToken'));
              let updatedCurrentUserData = Cookies.get('authToken');
              updatedCurrentUserData = updatedCurrentUserData ? JSON.parse(updatedCurrentUserData).data : null;
console.log(updatedCurrentUserData);
              if ( 'firstName' in updateUserFormData ) updatedCurrentUserData.firstName = updateUserFormData.firstName;
              if ( 'lastName' in updateUserFormData ) updatedCurrentUserData.lastName = updateUserFormData.lastName;
              if ( 'address' in updateUserFormData ) updatedCurrentUserData.address = updateUserFormData.address;
              Cookies.set('authToken', JSON.stringify(updatedCurrentUserData));
console.log(Cookies.get('authToken'));
            }
*/
            window.alert( res.data.message + '\nFields updated: ' + Object.keys(updateUserFormData) );
          },
          err => { /*console.log(err);*/ }
        );
      }

    }


    const removeUser = (id) => {
      if ( window.confirm("Remove user "+thisUserData.email+"?") ) {
        UserService.removeUser(id, authToken)
          .then(res => {
              if ( currentUserData.id === thisUserID.id ) { // Logout if deleted self
                Cookies.set('authToken', null);
                window.location.replace(Routes.Homepage.path);
              } else {
                alert("User " + thisUserData.email + " was successfully removed. All Properties, Listings, and Bookings associated with this User have been removed.");
                window.location.replace(Routes.DashboardOverview.path);
              }
          })
          .catch(err => {
            alert(err.response.data.message ? err.response.data.message : "User " + thisUserData.email + " was not removed.");
          });
      }
    }


    if ( queryStatusUser && queryStatusUser !== 204 ) { // Check User ID Exists

      return (
        <>
          <Row className="justify-content-center mb-4">
            <Col>
              <Card>
                <Card.Img variant="top" src="/images/smith-river-mt-paradise-bend.jpg" className="h-15vw" />
                <Card.Body>

                  <Row>
                    <Col className="d-md-flex justify-content-between align-items-center">
                      <h5>
                        <FontAwesomeIcon icon={faMapMarkedAlt} className="me-2" />
                        User Profile: <span className="text-secondary">{ (thisUserData.firstName || thisUserData.lastName) ? thisUserData.firstName+" "+thisUserData.lastName : thisUserData.email }</span>
                      </h5>

                      <a href="/dashboard" className="btn btn-outline-secondary px-3">
                        <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2" />
                        Dashboard
                      </a>
                    </Col>
                  </Row>

                  <Row>
                    <Col className="py-4">
                      <Tab.Container
                        activeKey={activeKey}
                        onSelect={(e) => setActiveKey(e)}
                        id="user-profile-tabs"
                        className="my-3"
                      >

                        <Row>
                          <Col lg={2}>
                            <Nav variant="tabs"className="flex-lg-column" fill>
                              { currentUserData.roles.includes('ROLE_ADMIN') &&
                                <>
                                  <Nav.Item>
                                    <Nav.Link eventKey="user-host">Host</Nav.Link>
                                  </Nav.Item>
                                <Nav.Item>
                                  <Nav.Link eventKey="user-trips">Guest Trips</Nav.Link>
                                </Nav.Item>
                                </>
                              }
                              <Nav.Item>
                                <Nav.Link eventKey="user-info">User Information</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="user-address">Address</Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="user-password">Password</Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </Col>

                          <Col lg={10}>
                            <Card>
                              <Card.Body>
                                <Tab.Content>

                                  { currentUserData.roles.includes('ROLE_ADMIN') &&
                                    <>
                                      <Tab.Pane eventKey="user-host">
                                        <h2 className="fs-4 text-dark text-decoration-underline mb-4">Host Listings & Properties</h2>
                                        <ListingsTable queryStatus={queryStatusUser} listingsList={listingsList} />
                                        <hr className="my-5" />
                                        <PropertiesTable queryStatus={queryStatusUser} propertiesList={propertiesList} />
                                      </Tab.Pane>
                                      <Tab.Pane eventKey="user-trips">
                                        <h2 className="fs-4 text-dark text-decoration-underline mb-4">Guest Trips</h2>
                                        <BookingsCalendar heading="User's Trips" queryStatus={queryStatusUser} bookingsList={guestBookingsList} thisUserData={thisUserData} calEventSelected={calEventSelected} />
                                      </Tab.Pane>
                                    </>
                                  }

                                  <Tab.Pane eventKey="user-info">
                                    <h2 className="fs-4 text-dark text-decoration-underline mb-4">User Info</h2>
                                    <ManageUserInfo queryStatus={queryStatusUser} adminPermissions={currentUserData.roles.includes('ROLE_ADMIN')} thisUserData={thisUserData} formData={updateUserFormData} updateUser={updateUser} removeUser={removeUser} />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="user-address">
                                    <h2 className="fs-4 text-dark text-decoration-underline mb-4">Address</h2>
                                    <ManageUserAddress queryStatus={queryStatusUser} adminPermissions={currentUserData.roles.includes('ROLE_ADMIN')} thisUserData={thisUserData} statesList={statesList} formData={updateUserFormData} updateUser={updateUser} />
                                  </Tab.Pane>

                                  <Tab.Pane eventKey="user-password">
                                    <h2 className="fs-4 text-dark text-decoration-underline mb-4">Update Password</h2>
                                    <ManageUserPassword queryStatus={queryStatusUser} adminPermissions={currentUserData.roles.includes('ROLE_ADMIN')} thisUserData={thisUserData} formData={updateUserFormData} updateUser={updateUser} />
                                  </Tab.Pane>

                                </Tab.Content>
                              </Card.Body>
                            </Card>
                          </Col>
                        </Row>

                      </Tab.Container>
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    } else if ( queryStatusUser && queryStatusUser === 204 ) {
      return (
        <>
          <Row className="justify-content-center">
            <Col xl={4}>
              <Card>
                <Card.Body className="text-center py-5">
                  <h4>User Not Found</h4>
                  <h5 className="mb-0 text-danger">{thisUserID.id}</h5>
                  <Link to={Routes.DashboardOverview.path} className="btn btn-outline-primary mt-4">
                    <FontAwesomeIcon icon={faChartPie} className="me-2" /> Return to Dashboard
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <div className="mt-5" style={{height: '40vh'}}>
          <Preloader message="Loading..." logoSize={50} show={true} />
        </div>
      );
    }

  } else {
    return <Redirect to={Routes.NotFound.path} />;
  }

};
export default UserProfile;
