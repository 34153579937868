import React from "react";
import _ from "lodash";

import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";


export const ImageCarousel = (props) => {

	return (
		<>
			{props.galleryData &&
				<div className="recx-image-carousel">
					<Carousel interval={null} controls={Object.keys(props.galleryData).length > 4 ? true : false}>
						{ _.chunk(props.galleryData, 5).map(function (groupedImgURLs, iA) {
							let imgCount = Object.keys(groupedImgURLs).length;
							let featureImgURL = groupedImgURLs.shift();

							return (
								<Carousel.Item key={iA} className={`img-count-${imgCount}`}>
									<Row className="equal-height-row justify-content-center g-2">
										<Col>
											<a href={featureImgURL} target="_blank" rel="noreferrer" className="bg-img d-block w-100" style={{height: '33vw', backgroundImage: `url(${featureImgURL})`}}></a>
										</Col>

										{ Object.keys(groupedImgURLs).length > 0 &&
											<Col>
												<Row className={`h-100 ${Object.keys(groupedImgURLs).length > 1?'g-2':''} ${Object.keys(groupedImgURLs).length > 3?'row-cols-2':'row-cols-1'}`}>

													{ groupedImgURLs.map(function (imageURL, iB) {
														return (
															<Col key={iB}>
																<a href={imageURL} target="_blank" rel="noreferrer" className="bg-img d-block h-100 w-100" style={{backgroundImage: `url(${imageURL})`}}></a>
															</Col>
														);
													}) }

												</Row>
											</Col>
										}

									</Row>
								</Carousel.Item>
							);
						}) }
					</Carousel>
				</div>
			}
		</>
	);
}
