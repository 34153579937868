import React, { useState } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faEnvelope, faDollarSign } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const AddBooking = (props) => {

  const [calcPrice, setCalcPrice] = useState(props.listingData.base_rate * props.listingData.min_days);
  props.formData.calcPrice = calcPrice;

  const [occupants, setOccupants] = useState(1);
  props.formData.occupants = occupants;

  function setFormValues(e) {
    props.formData[e.target.name] = e.target.value;
    if ( e.target.name !== 'final_price' && beginDate && endDate ) { calcFinalPrice(); }
    if ( 'occupants' === e.target.name ) { setOccupants(e.target.value); }
    if ( 'final_price' === e.target.name ) { setCalcPrice(e.target.value); }
  };

  function calcFinalPrice() {
    if ( beginDate && endDate ) {
      let duration = moment.duration(moment(endDate).diff(beginDate)).asDays();
      setCalcPrice( Math.ceil(duration) * occupants * props.listingData.base_rate );
    }
  };

  const [beginDate, setBeginDate] = useState("");
  const changeBeginDate = (e) => {
    let startDateTime = moment( e.startOf('day').format('MM/DD/YYYY') + ' ' + (props.listingData.check_in_time?moment(props.listingData.check_in_time).format('HH:mm'):'00:01'), 'MM/DD/YYYY HH:mm');
    setBeginDate(startDateTime.format());
    props.formData.start = startDateTime.format();

    // auto-set endDate to match min_days for listing
    changeEndDate(moment(e).add((props.listingData.min_days - 1), 'day'));
  };

  const [endDate, setEndDate] = useState("");
  const changeEndDate = (e) => {
    let endDateTime = e?moment( e.startOf('day').format('MM/DD/YYYY') + ' ' + (props.listingData.check_out_time?moment(props.listingData.check_out_time).format('HH:mm'):'23:59'), 'MM/DD/YYYY HH:mm'):null; // handle clearing endDate when beginDate is selected
    setEndDate(endDateTime.format());
    props.formData.end = endDateTime.format();
    calcFinalPrice();
  };

  var isValidEndDate = function(current) {
    // check date is AFTER START DATE
    if ( beginDate || props.listingData.startAvailable ) {
      let startAvailable = beginDate?moment(beginDate).add(-1, 'day'):moment(props.listingData.startAvailable);
      if ( current < startAvailable ) return false;
    }
    return true;
  };

  return (
    <>
      <Row>
        <Col>
          <Form id="addBooking" onSubmit={ (e) => props.addBooking(e.preventDefault()) }>

            <Row>
              <Col className="mb-3">
                <Form.Group id="title">
                  <Form.Control disabled type="text" className="form-control-lg text-center fw-bolder" defaultValue={props.listingData.title} placeholder="Listing Title" />
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={4} className="mb-3">
                <Form.Group id="status">
                  <Form.Label className="d-block text-center">Booking Status</Form.Label>
                  <Form.Select name="status" onChange={setFormValues} onBlur={setFormValues}>
                    <option value="pending">Pending</option>
                    <option value="approved">Approved</option>
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <hr />

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="start">
                  <Form.Label>Start Date</Form.Label>
                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeBeginDate}
                    closeOnSelect={true}

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={ ( beginDate ) ? moment(beginDate).format("MM/DD/YYYY") : "" }
                          placeholder="MM/DD/YYYY"
                          onFocus={openCalendar}
                          onChange={(e) => { setBeginDate(e.target.value) }}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="end">
                  <Form.Label>End Date</Form.Label>
                  <Datetime
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeEndDate}
                    closeOnSelect={true}
                    isValidDate={isValidEndDate}

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          required
                          type="text"
                          defaultValue={ ( endDate ) ? moment(endDate).format("MM/DD/YYYY") : "" }
                          placeholder="MM/DD/YYYY"
                          onFocus={openCalendar}
                          onChange={(e) => { setEndDate(e.target.value) }}
                          onBlur={calcFinalPrice()}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group id="guest_email">
                  <Form.Label>Guest Email Address</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control required autoFocus type="email" name="guest_email" placeholder="guest@email.com" onChange={setFormValues} onBlur={setFormValues} />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col md={6} className="mb-3">
                <Form.Group id="numberGuests">
                  <Form.Label>Party Size</Form.Label>
                  <Form.Control required type="number" name="occupants" value={occupants} min={1} onChange={setFormValues} />
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="finalPrice">
                  <Form.Label>Total Price</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faDollarSign} />
                    </InputGroup.Text>
                    <Form.Control type="number" name="final_price" placeholder={calcPrice} step="0.01" onChange={setFormValues} />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Add Booking</Button>
            </div>

          </Form>
        </Col>
      </Row>
    </>
  );
}
