import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import _, { filter, set } from 'lodash';
import queryString from 'query-string';
import moment from 'moment-timezone';

import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import * as turf from '@turf/turf';

import { Routes } from '../../routes';

import ListingService from '../../services/listing.service';

import { AdvancedSearchListings } from '../../components/forms/searchPublicListingsAdvanced';
import { listingTypesDefinitions, masterCategoryDefinitions } from '../../listingTypes/listingTypes';
import { statesList } from "../../components/data/statesList";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSearch, faTimes, faSlidersH } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Button, Container, Card, Navbar, Form, InputGroup, Modal } from 'react-bootstrap';

import PublicFooter from '../../components/PublicFooter';
import Preloader from '../../components/Preloader';

import DeerCircleWhite from '../../assets/img/rec-x/deer-circle-white.png'

const Search = (props) => {

  const [queryStatusListings, setQueryStatusListings] = useState(false);
  const [unfilteredListingsList, setUnfilteredListingsList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  const [visibleListingIds, setVisibleListingIds] = useState([])
  const defaultMapCenter = [-110.454353, 47.052698];
  let handlePopupClick

  useEffect(() => {
    document.title = "Search Listings | REC-X";

    if ( !queryStatusListings ) {
      ListingService.getListingsPublic()
        .then(res => {
          setQueryStatusListings(res.status);
          setListingsList(res.body);
          fitBoundsToListings(res.body);
          setUnfilteredListingsList(res.body)
          setVisibleListingIds(res.body.map(listing => listing._id))
          map.current.once('idle', () => map.current.on('moveend', () => handleMapChange()))
        });
      }
  }, []);

  let searchListingsFormData = {};
  let parsedHash = queryString.parse(window.location.hash);
  if ( parsedHash && Object.keys(parsedHash).length > 0 ) {
    // Filter out all falsy values ( "", 0, false, null, undefined )
    searchListingsFormData = Object.entries(parsedHash).reduce((a,[k,v]) => (v ? (a[k]=v, a) : a), {});
    if (typeof searchListingsFormData.category === 'string') searchListingsFormData.category = [searchListingsFormData.category];
  }

  // set activity or default to empty array
  searchListingsFormData.activity = parsedHash.activity || [];

  // Run searchListings algo if hash parameters included in URL
  useEffect(() => {
    if ( parsedHash && Object.keys(parsedHash).length > 0 ) searchListings();
  }, [unfilteredListingsList]);

  const searchListings = () => {
    // setSearchFiltersModal(false);

    // let queryHash = queryString.stringify(searchListingsFormData); // Stringify form array into hash parmeters
    // window.location.hash = queryHash; // Update search page URL

    let filteredListingsList = [];

    if ( Object.keys(searchListingsFormData).length > 0 ) {
      // // Handle 'activity' checkbox filters
      Object.keys(searchListingsFormData).some(searchTerm => {
        if ( searchTerm === 'activity' ) {
          // when other parameter included in URL, eg. searchText, parser stringifies array into first array element - check, then split if that happens

          let activities = searchListingsFormData.activity;
          if (activities.length > 0) {
            if (typeof activities === 'string') activities = activities.split(',').map(item => item.trim())

            if (searchListingsFormData && searchListingsFormData.category) {
              // Use OR query if category is selected
              activities.map( (activity) => {
                _.filter(unfilteredListingsList, function(listing) {
                  if ( listing.listingType && Object.keys(listing.listingType).length > 0 ) {
                    let listing_type = listing.listingType;
                    // Check if arrays match for least one 'activity'
                    if ( Object.keys(_.intersectionWith([activity], listing_type, _.isEqual)).length ) {
                      // thisSearchMatches used to display 'activity' match(es)
                      listing.thisSearchMatches = _.intersectionWith([activity], listing_type, _.isEqual);
                      // thisSearchRanking used to sort strongest 'activity' matches to top
                      listing.thisSearchRanking = Object.keys(listing.thisSearchMatches).length;
                      filteredListingsList.push(listing);
                    }
                  }
                });
              });
            } else {
              // Use AND query if no category selected
              _.filter(unfilteredListingsList, function(listing) {
                if (listing.listingType && Object.keys(listing.listingType).length > 0) {
                  let listing_type = listing.listingType;
                  // Check if all activities are present in the listing
                  if (activities.every(activity => listing_type.includes(activity))) {
                    // thisSearchMatches used to display 'activity' match(es)
                    listing.thisSearchMatches = activities.filter(activity => listing_type.includes(activity));
                    // thisSearchRanking used to sort strongest 'activity' matches to top
                    listing.thisSearchRanking = listing.thisSearchMatches.length;
                    filteredListingsList.push(listing);
                  }
                }
              });
            }
          }
        }

        filteredListingsList = _.orderBy(filteredListingsList, ['thisSearchRanking'], ['desc']); // Sort multiple 'activity' matches to top using thisSearchRanking
        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        fitBoundsToListings(filteredListingsList);
        setListingsList(filteredListingsList);
        return null;
      });

      // Handle State filter
      if ( searchListingsFormData.state && Object.values(statesList).includes(searchListingsFormData.state) ) { // restrict to valid states
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {
          return ( listing.state === undefined || listing.state !== searchListingsFormData.state );
        });

        filteredListingsList = _.uniq(listingList); // Remove duplicates
        fitBoundsToListings(filteredListingsList);
        setListingsList(filteredListingsList);
      } else { // remove invalid state value
        delete searchListingsFormData.state;
      }

      // Handle Date Range filter
      if ( searchListingsFormData.start && searchListingsFormData.end ) {
        let listingList = _.reject(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), function(listing) {

          let rejectListing = false;
          for (var m = moment(searchListingsFormData.start); m.diff(searchListingsFormData.end, 'days') <= 0; m.add(1, 'days')) {

            if ( listing.availableBookingDates.includes(m.format('YYYY-MM-DD')) ) {
              rejectListing = false;
              break;
            } else {
              rejectListing = true;
            }
          }

          return rejectListing;

        });

        filteredListingsList = _.uniq(filteredListingsList); // Remove duplicates
        fitBoundsToListings(filteredListingsList);
        setListingsList(filteredListingsList);
      }

      // Handle text search term
      if ( searchListingsFormData.searchText ) {
        let newFilteredListingsList = []; // second level of filtering : 'AND' query
        _.filter(( Object.keys(filteredListingsList).length ? filteredListingsList : unfilteredListingsList ), (listing) => {
          let selected = false; // prevent multiple positive hits on same a single listing object
          Object.values(listing).map((dataPoint) => {
            if ( selected !== true ) { // prevent unneccessary looping after positive match
              if ( typeof dataPoint === 'string' ) {
                dataPoint = dataPoint.toLowerCase();
                if ( dataPoint.includes(searchListingsFormData.searchText.toLowerCase()) ) {
                  newFilteredListingsList.push(listing);
                  selected = true;
                }
              }
              if ( typeof dataPoint === 'object' ) {
                if ( _.some(dataPoint, searchListingsFormData.searchText) ) {
                  newFilteredListingsList.push(listing);
                  selected = true;
                }
              }
            }
            return null;
          });
        });

        newFilteredListingsList = _.uniq(newFilteredListingsList); // Remove duplicates
        fitBoundsToListings(newFilteredListingsList);
        setListingsList(newFilteredListingsList);
      }

      map.current && map.current.once('idle', () => map.current.setZoom(map.current.getZoom()+0.0001))

    } else { // If search parameters are removed, remove previous filters w/o requiring page refresh
      clearSearch();
    }
  }


  const clearSearch = () => {
    window.location.href = Routes.Search.path;
/*
    const geojson = createListingsSource(unfilteredListingsList)
    map.current.getSource('listings').setData(geojson)
    map.current.easeTo({
      center: defaultMapCenter,
      zoom: 5,
    })

    searchListingsFormData = {};
    setListingsList(unfilteredListingsList);
    // update URL
    window.history.replaceState(null, null, '#');
    // re-run search algo
    searchListings();
*/
  }



  const [newSearchFormData, setNewSearchFormData] = useState({});
  const setNewSearchFormValues = (e) => {
    // update state of searchListingsFormData
    let currentSearchListingsFormData = searchListingsFormData;
    currentSearchListingsFormData[e.target.name] = e.target.value;
    setNewSearchFormData(currentSearchListingsFormData);
  }

  const newSearch = (searchListingsFormData) => {
    let queryHash = queryString.stringify(newSearchFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Reload search page w/ new hash parameters
    window.location.reload();
    // console.log(Routes.Search.path+'#'+queryHash);
  }


  const [textSearchValue, setTextSearchValue] = useState('');
  const textSearch = () => {
    let parsedHash = queryString.parse(window.location.hash);

    // set searchText for URL
    textSearchValue.length > 0 ? parsedHash.searchText = textSearchValue : delete parsedHash.searchText 
    // update URL
    window.location.hash = `#${queryString.stringify(parsedHash)}`;
    // re-run search algo
    searchListingsFormData = parsedHash;
    searchListings();
  }

  const selectCategory = (categoryID, button) => {
    // selected button .active
    let categoryButtons = document.querySelectorAll('.category-button');
    for ( let i=0; i < categoryButtons.length; i++ ) {
      categoryButtons[i].classList.remove('active');
      if ( categoryButtons[i].id == categoryID ) {
        categoryButtons[i].classList.add('active');
      }
    }

    let parsedHash = queryString.parse(window.location.hash);

    // set category for URL
    parsedHash.category = categoryID;

    // delete parsedHash.activity; // clear Activity value(s) b/c they will be set upon reoload to match Category

    // set activities for URL
    let category = _.find(masterCategoryDefinitions, {'id': categoryID});
    parsedHash.activity = category.listingTypes;

    // update URL
    window.location.hash = `#${queryString.stringify(parsedHash)}`;
    // re-run search algo
    searchListingsFormData = parsedHash;
    searchListings();
  }

  const fitBoundsToListings = (listings) => {
    if (listings.length === 0) map.current && map.current.setZoom(4)
    else {
      const centroids = listings.map(listing => {
        return turf.point(listing.propertyData.property_centroid);
      });
      const bounds = turf.bbox(turf.featureCollection(centroids));
      map.current && map.current.fitBounds(bounds, { padding: 30, maxZoom: 8 });
    }

  }

  const handleMapChange = async () => {    
    if (!map.current || !map.current.getLayer('unclustered-point')) return

    const features = map.current.queryRenderedFeatures({ layers: ['unclustered-point', 'clusters'] }) || []
    const validFeatures = features.filter(feature => {
      const coordinates = feature?.geometry?.coordinates;
      return Array.isArray(coordinates) && coordinates.length === 2 && coordinates.every(coord => typeof coord === 'number' && !isNaN(coord));
    });
    if (validFeatures.length === 0) return;
  
    const clusterFeatures = validFeatures.filter(f => f.properties.cluster)
    const pointFeatures = validFeatures.filter(f => !f.properties.cluster)
    const pointListingIds = pointFeatures.map(f => f.properties.id)
    let clusterListingIds = [];

    const getClusterLeavesRecursive = async (clusterId) => {
      const source = map.current.getSource('listings');
      try {
        const leaves = await new Promise((resolve, reject) => {
          source.getClusterLeaves(clusterId, Infinity, 0, (err, leaves) => {
            if (err) reject(err);
            resolve(leaves);
          });
        });
  
        const nestedClusterIds = leaves.filter(leaf => leaf.properties.cluster).map(leaf => leaf.properties.cluster_id);
        const listingsInCluster = leaves.filter(leaf => !leaf.properties.cluster).map(leaf => leaf.properties.id);
  
        for (const nestedClusterId of nestedClusterIds) {
          const nestedListings = await getClusterLeavesRecursive(nestedClusterId);
          listingsInCluster.push(...nestedListings);
        }
  
        return listingsInCluster;
      } catch (error) {
        // console.error(`Error getting cluster leaves for cluster ID ${clusterId}:`, error);
        return [];
      }
    };
    
    for (const cluster of clusterFeatures) {
      const clusterId = cluster.properties.cluster_id;
      const listingsInCluster = await getClusterLeavesRecursive(clusterId);
      clusterListingIds = clusterListingIds.concat(listingsInCluster);
    }

    const visibleListings = [...new Set([...clusterListingIds, ...pointListingIds])];
    setVisibleListingIds(visibleListings)
  }

  function generateCircleImage(imageUrl, imageName) {
    if (map.current.hasImage(imageName)) return
  
    map.current.loadImage(imageUrl, (error, image) => {
      if (error) generateCircleImage(DeerCircleWhite, imageName)

      if (error || !image || image.width === 0 || image.height === 0) return
  
      const size = 100
      const borderSize = 10
      const resolution = 2
      const canvas = document.createElement('canvas')
      canvas.width = canvas.height = (size + borderSize * 2) * resolution
      const ctx = canvas.getContext('2d')
  
      // Draw border
      ctx.beginPath()
      ctx.arc(canvas.width / 2, canvas.height / 2, (size + borderSize) * resolution / 2, 0, Math.PI * 2)
      ctx.closePath()
      ctx.fillStyle = 'white'
      ctx.fill()
  
      // Draw image as circle
      ctx.beginPath()
      ctx.arc(canvas.width / 2, canvas.height / 2, size * resolution / 2, 0, Math.PI * 2)
      ctx.closePath()
      ctx.clip()
      try {
        ctx.drawImage(image, borderSize * resolution, borderSize * resolution, size * resolution, size * resolution)
      } catch (e) {
        return
      }
  
      const circleImage = new Image()
      circleImage.src = canvas.toDataURL()
      circleImage.onload = () => {
        if (!map.current.hasImage(imageName)) {
          map.current.addImage(imageName, circleImage, { width: canvas.width, height: canvas.height })
        }
      }
    })
  }

  function createListingsSource(listings) {
    const geojson = {
      type: 'FeatureCollection',
      features: listings.map((listing, index) => {

        // offset icons for multiple listings at a single property
        const radius = 0.0005
        const angle = index * ((2 * Math.PI) / (1.25))
        const xOffset = Math.cos(angle) * radius
        const yOffset = Math.sin(angle) * radius

        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [listing.propertyData.property_centroid[0] + xOffset, listing.propertyData.property_centroid[1] + yOffset],
          },
          properties: {
            id: listing._id,
            imageName: `image-${listing._id}`,
            imageUrl: listing.gallery[0] || DeerCircleWhite,
          },
        }
      }),
    };
    return geojson
  }

  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/mapbox/streets-v12`,
        center: defaultMapCenter, 
        zoom: 3,
        pitch: 0
      });

      map.current.on('style.load', () => {
        map.current.addSource('mapbox-dem', {
          type: 'raster-dem',
          url: 'mapbox://mapbox.mapbox-terrain-dem-v1',
          tileSize: 512,
          maxzoom: 10
        });
        map.current.setTerrain({ source: 'mapbox-dem', exaggeration: 1.5 });
      });

      map.current.addControl(new mapboxgl.NavigationControl());
      map.current.once('idle', () => map.current.on('moveend', () => handleMapChange()))
    };

    if ( queryStatusListings === 200 && listingsList && (listingsList.length < unfilteredListingsList.length) || !Object.keys(parsedHash).length ) {
      if ( listingsList.length === 0 ) return

      const geojson = createListingsSource(listingsList)
      const source = map.current.getSource('listings');

      if (!source) {
        map.current.addSource('listings', {
          type: 'geojson',
          data: geojson,
          cluster: true,
          clusterMaxZoom: 13, 
        })
      } else {
        source.setData(geojson);
      }

      // Load listing images to map
      listingsList.forEach((listing) => {
        const imageUrl = listing.gallery[0]
        const imageName = `image-${listing._id}`
        generateCircleImage(imageUrl, imageName)
      })

      // Add cluster layer
      map.current.getLayer('clusters') && 
        map.current.removeLayer('clusters')
      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'listings',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#61DAFB',
          'circle-radius': 24,
          'circle-stroke-width': 2.5,
          'circle-stroke-color': '#ffffff',
        }
      })

      // Add cluster count layer
      map.current.getLayer('cluster-count') && 
        map.current.removeLayer('cluster-count')
      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'listings',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 16,
        }
      })

      // Add unclustered point layer
      map.current.getLayer('unclustered-point') && 
        map.current.removeLayer('unclustered-point')
      map.current.addLayer({
          id: 'unclustered-point',
          type: 'symbol',
          source: 'listings',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': ['get', 'imageName'],
            'icon-size': 0.25,
            'icon-allow-overlap': true,
          },
        })
    
      map.current.on('mouseenter', 'clusters', () => map.current.getCanvas().style.cursor = 'pointer')
      map.current.on('mouseleave', 'clusters', () => map.current.getCanvas().style.cursor = '')

      map.current.on('click', 'clusters', (e) => {
        const features = map.current.queryRenderedFeatures(e.point, { layers: ['clusters'] })
        const clusterId = features[0].properties.cluster_id
        
        map.current.getSource('listings').getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.current.flyTo({
            center: features[0].geometry.coordinates,
            zoom: zoom + 1,
            duration: 1000,
          });

          map.current.once('idle', () => {
            map.current.setZoom(zoom + 1)
          })
        });
      })

      if (handlePopupClick) map.current.off('click', 'unclustered-point', handlePopupClick)

      handlePopupClick = (e) => {
        const feature = map.current.queryRenderedFeatures(e.point, { layers: ['unclustered-point'] })[0]
        if (map.current.getZoom() < 10) {
          map.current.flyTo({
            center: feature.geometry.coordinates,
            zoom: 10
          })
        } 

        const listing = listingsList.find(listing => listing._id === feature.properties.id)
    
        const popup = new mapboxgl.Popup({ offset: 25, className: 'search-popup' })
          .setLngLat(feature.geometry.coordinates)
          .setHTML(`
            <div class="text-center m-2 p-2">
              <a href="/listing/${listing?._id}">             
                <img src="${feature.properties.imageUrl}" alt="${feature.properties.title}" class="w-100 h-auto object-fit-cover rounded-1"/>
              </a>
              <a href="/listing/${listing?._id}" class="text-gray">             
                <h3 class="fs-5 mt-2 mb-0">${listing?.title || "Title"}</h3>
              </a>
            </div>
          `)
          .addTo(map.current);
      };

      map.current.on('click', 'unclustered-point', handlePopupClick);
    };
    return () => {
      map.current.off('click', 'unclustered-point', handlePopupClick);
      handlePopupClick = null;
    }
  }, [listingsList]);


  const [searchFiltersModal, setSearchFiltersModal] = useState(false);


  return (
    <>
      <Row className="my-4">
        <Col>
          <Navbar id="searchResults-categorySelector" className="bg-body d-flex flex-wrap align-items-start justify-content-evenly">
            {
              Object.values(masterCategoryDefinitions).map(function (c, i) {
                return (
                  <span
                    key={c.id}
                    id={c.id}
                    role="button" 
                    className={`${(searchListingsFormData.hasOwnProperty('category') && searchListingsFormData.category.includes(c.id))?'active border-bottom':''} category-button small text-dark fw-bold text-center lh-sm border-2 border-dark mx-1 mb-2`}
                    onClick={(button) => selectCategory(c.id, button)}
                  >
                    {c.title}
                  </span>
                );
              })
            }
          </Navbar>
        </Col>
      </Row>
      <Row className="px-4 my-4">
        <Col id="searchResults" xs={6} className="px-0">

          <div className="d-flex mb-2">
            <Form onSubmit={ (e) => textSearch(e.preventDefault()) } className="d-flex w-100">
              <Form.Group id="searchText" className="w-100">
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                  <Form.Control 
                    name="searchText" 
                    defaultValue={searchListingsFormData.searchText && searchListingsFormData.searchText} 
                    type="text" 
                    placeholder="Search Term" 
                    className="rounded-0" 
                    onChange={(e) => setTextSearchValue(e.target.value)} 
                    onBlur={(e) => setTextSearchValue(e.target.value)} 
                  />
                  { searchListingsFormData.hasOwnProperty('searchText') &&
                    <InputGroup.Text 
                      className="rounded-0 text-danger bg-light" 
                      role="button" 
                      title="Clear Search"
                      onClick={clearSearch}
                    >
                      <FontAwesomeIcon icon={faTimes} className="fs-6" />
                    </InputGroup.Text>
                  }
                  </InputGroup>
              </Form.Group>

              <Button variant="primary" type="submit" title="Search" className="rounded-0 rounded-end">
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Form>

            <div className="ps-2">
              <Button
                variant="outline-primary"
                title="Search Filters"
                onClick={() => {
                  setNewSearchFormData({})
                  setSearchFiltersModal(true)
                }}
              >
                <FontAwesomeIcon icon={faSlidersH} />
              </Button>
            </div>
          </div>

          <div className="px-2 pb-1">
            {listingsList && listingsList.length} Listings Found
          </div>

          <Modal
            id="advancedSearchFilters"
            show={searchFiltersModal}
            size="xl"
            centered
            onHide={() => setSearchFiltersModal(false)}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <FontAwesomeIcon icon={faSearch} className="me-2" /> Filter Search Results
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{minHeight: '88vh'}}>
              <AdvancedSearchListings stickyFooter={true} formData={searchListingsFormData} setNewSearchFormData={setNewSearchFormData} newSearchFormData={newSearchFormData} newSearch={newSearch} clearSearch={clearSearch} />
            </Modal.Body>
          </Modal>

          {(queryStatusListings !== 200)
            ?
              <div className="mt-6" style={{height: '60vh'}}>
                <Preloader message="Loading..." logoSize={75} show={true} />
              </div>
            :
              <div id="searchResults-list" style={{height: '73vh', overflow: 'scroll'}}>
                <Row className="row-cols-1 row-cols-md-2 g-3 pt-3 px-0 mx-0">
                  {
                    listingsList.map(function (listing) {

                      let primaryImage = ( listing.hasOwnProperty('gallery') && listing.gallery.length ) 
                        ? listing.gallery[0] 
                        : `https://picsum.photos/200/100?${Math.floor(Math.random()*100)}`;

                      if (visibleListingIds.includes(listing._id)) {
                        return (
                          <Col key={listing._id} id={`listing-${listing._id}`} className="mb-4">
                            <Link to={"/listing/"+listing._id}>
                              <Card className="h-100 overflow-hidden bg-white shadow-sm border-3">
                                <Card.Img variant="top" className="rounded-0" src={primaryImage} />
                                <Card.Body className="py-3">
                                  <h5 className="fw-bolder mb-0" style={{textShadow: '0 0 3px #fff'}}>{listing.title}</h5>
                                  <div className="price small">Starting Price ${listing.base_rate} / Guest</div>

                                  {listing.thisSearchMatches &&
                                    <div className="activities badges mb-1">
                                      {
                                        Object.values(listing.thisSearchMatches).map(function (activity) {
                                          return (
                                            <span className="activity badge bg-secondary py-1 m-1" key={activity}>{listingTypesDefinitions[activity].type.title}</span>
                                          );
                                        })
                                      }
                                    </div>
                                  }
                                  {listing.description}
                                </Card.Body>
                              </Card>
                            </Link>
                          </Col>
                        )
                      }
                    })
                  }
                </Row>
              </div>
          }
        </Col>

        <Col>
          <div style={{height: '80vh', width: '100%'}}>
            <div ref={mapContainer} className="w-100 h-100 rounded" id="listingSearchMap"></div>
          </div>
        </Col>
      </Row>

      <PublicFooter />

    </>
  );
};
export default Search;
