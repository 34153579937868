import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class ListingService {

  getListings(token) {
    return fetch(API_URL + '/listings/', { headers: authHeader(token) })
      .then(data => data.json())
  }

  getListingsPublic() {
    return fetch(API_URL + '/public/listings')
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }
/*
  async getListingsByPropertyOwner(id, token) {
    if (token) {
      return await fetch(API_URL + '/listings/host/' + id, { headers: authHeader(token) })
        .then(res => 
          res.json()
          .then(data => (
            {
              status: res.status,
              body: data
            }
          ))
          .catch(err => (
            {
              status: res.status
            }
          ))
        )
        .catch(err => err)
    }
  }

  getListingsByProperty(id, token) {
    return fetch(API_URL + '/listings/property/' + id, { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }

  getListingsByPropertyPublic(id, token) {
    return fetch(API_URL + '/public/listings/property/' + id, { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }
*/
  getListing(id, token) {
    return fetch(API_URL + '/listing/' + id, { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }

  getListingPublic(id, token) {
    return fetch(API_URL + '/public/listing/' + id, { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }

  addListing(data, token) {
    return axios.post(API_URL + '/listing/', data, { headers: authHeader(token) });
  }

  updateListing(id, data, token) {
    return axios.put(API_URL + '/listing/' + id, data, { headers: authHeader(token) });
  }

  removeListing(id, token) { // ToDo : Check for errors on API
    return axios.delete(API_URL + '/listing/' + id, { headers: authHeader(token) });
  }

}

export default new ListingService();