import React from "react";
import { Routes } from "../../routes";
import queryString from "query-string";

import homepageHero from "../../assets/img/rec-x/hero/homepage-hero-1.jpg";
import homepageHeroVideo from "../../assets/img/rec-x/hero/homepage-hero-1.mp4";

import { SearchListings } from "../../components/forms/searchPublicListings";

import PublicFooter from '../../components/PublicFooter';

/*
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faChartPie } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
*/

import { Col, Row, Image, Container, Card, Button } from "react-bootstrap";


const Homepage = (props) => {

  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Send to search page w/ hash parameters
    // console.log(Routes.Search.path+'#'+queryHash);
  }

  return (
    <>
      <section id="homepage-hero" className="position-relative overflow-hidden d-flex align-items-center justify-content-center">
        <video 
          id="hero-video" 
          poster={homepageHero}
          autoPlay 
          muted 
          loop 
        >
          <source src={homepageHeroVideo} type="video/mp4" />
        </video>

        <div className="position-absolute top-0 right-0 bottom-0 left-0 d-flex align-items-center">
          <div className="h-lg-75 d-flex flex-column flex-md-row align-items-center justify-content-center gap-5 gap-lg-7 p-4 p-lg-6">

            <Card className="border-3 h-100">
              <Card.Body>
                <Card.Title className="text-center fs-3 text-decoration-underline pb-2">
                    Recreational Guests
                </Card.Title>
                <Card.Text>Your next adventure is waiting — join RecX today and discover exclusive, private outdoor experiences like never before. Sign up, explore, and start your journey into nature now!</Card.Text>
              </Card.Body>
              <Card.Footer className="text-center border-0 pb-4">
                <a href="/search" className="btn btn-primary px-5 py-3">
                  Find Your Next Adventure Today
                </a>
              </Card.Footer>
            </Card>

            <Card className="border-3 h-100">
              <Card.Body>
                <Card.Title className="text-center fs-3 text-decoration-underline pb-2">
                    Property Owners
                </Card.Title>
                <Card.Text>Unlock your land's potential — partner with RecX to share your property responsibly and earn income with confidence and control. Sign up now to get started!</Card.Text>
              </Card.Body>
              <Card.Footer className="text-center border-0 pb-4">
                <a href="/sign-up" className="btn btn-primary px-5 py-3">
                  List Your Property
                </a>
              </Card.Footer>
            </Card>

          </div>
        </div>
      </section>

      <div className="section pt-0 mt-5">
        <Container>

          <Row className="justify-content-center mt-5 mt-lg-6">
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">20</h3>
              <p className="text-tertiary">Blue Ribbon Fishing</p>
            </Col>
            <Col xs={6} md={4} className="text-center mb-4">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-deer"></span>
              </div>
              <h3 className="fw-bolder">50+</h3>
              <p className="text-tertiary">Big Game Hunting</p>
            </Col>
            <Col xs={6} md={4} className="text-center">
              <div className="icon icon-shape icon-xl bg-white shadow-lg border-light rounded-circle icon-primary mb-4">
                <span className="flaticon-fishing"></span>
              </div>
              <h3 className="fw-bolder">22</h3>
              <p className="text-tertiary">Private Pond Fishing</p>
            </Col>
          </Row>

          <hr className="mt-4 mb-5" />

          <Row>
            <Col>
              <SearchListings formData={searchListingsFormData} searchListings={searchListings} />
            </Col>
          </Row>

        </Container>
      </div>


      <PublicFooter />
    </>
  );
};
export default Homepage;
