import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt, faWindowClose } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup, Alert } from "react-bootstrap";

import {formDataFormatter} from "../data/formDataFormatter";

import Preloader from "../Preloader";


export const ManageUserInfo = (props) => {

  function setFormValues(e) {
    props.formData[e.target.name] = formDataFormatter(e);
  }

  function clearCCemails() {
    props.formData.cc_emails = '';
    document.getElementsByName('cc_emails')[0].value = '';
  }


  const [requiredDataMissing, setRequiredDataMissing] = useState(false);
  useEffect(() => {
    const requiredUserData = ['firstName', 'lastName', /*'street_address', 'city', 'state', 'zipcode'*/];
    for ( let i = 0; i < requiredUserData.length; i++ ) {
      let requiredUserDataField = requiredUserData[i];
      if ( !props.thisUserData[requiredUserDataField] ) {
        setRequiredDataMissing(true);
        break;
      } else {
        setRequiredDataMissing(false);
        continue;
      }
    }
  }, [props.thisUserData])


  return (
    <>
      {(props.queryStatus !== 200)
        ?
          <Preloader message="Loading..." logoSize={50} show={true} />
        :
          <Form id="updateUser" onSubmit={ (e) => props.updateUser(e.preventDefault()) }>

            {requiredDataMissing &&
              <Row className="justify-content-center">
                <Col>
                  <Alert variant="danger" className="text-center">
                    You must enter your Name & Address to proceed
                  </Alert>
                </Col>
              </Row>
            }

            <Row className="justify-content-center">
              <Col md={6} className="mb-3">
                <Form.Group id="emal">
                  <Form.Label>Email</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control name="email" disabled type="email" defaultValue={props.thisUserData.email} placeholder="name@company.com" />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col md={6} className="mb-3">
                <Form.Group id="phone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control name="phone" type="tel" placeholder="(406) 555-1200" defaultValue={props.thisUserData.phone} onChange={setFormValues} />
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={6} className="mb-3">
                <Form.Group id="firstName">
                  <Form.Label>First Name</Form.Label>
                  <Form.Control required={!props.adminPermissions} name="firstName" type="text" className={(!props.adminPermissions && !props.thisUserData.firstName)?'border border-danger':null} defaultValue={props.thisUserData.firstName} placeholder="First name" onChange={setFormValues} />
                </Form.Group>
              </Col>
              <Col md={6} className="mb-3">
                <Form.Group id="lastName">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control required={!props.adminPermissions} name="lastName" type="text" className={(!props.adminPermissions && !props.thisUserData.lastName)?'border border-danger':null} defaultValue={props.thisUserData.lastName} placeholder="Last name" onChange={setFormValues} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group id="cc_emails">
                  <Form.Label>CC Email Addresses</Form.Label>
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faEnvelope} />
                    </InputGroup.Text>
                    <Form.Control name="cc_emails" type="text" placeholder="eg. sample@email.com, sample2@email.net, etc." defaultValue={props.thisUserData.cc_emails} onChange={setFormValues} />
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faWindowClose} role="button" onClick={clearCCemails} title="Clear field" className="text-danger fs-4" />
                    </InputGroup.Text>
                  </InputGroup>
                  <div className="small fst-italic ps-2">Comma separated email addresses will receive copies of all Host emails.</div>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Update</Button>
              <Button variant="danger" className="px-4" onClick={() => props.removeUser(props.thisUserData._id)}>Delete</Button>
            </div>

          </Form>
      }
    </>
  );
};


export const ManageUserAddress = (props) => {

  function setFormAddressValues(e) {
    props.formData.address = props.formData.address ? props.formData.address : props.thisUserData.address;
    props.formData.address[e.target.name] = formDataFormatter(e);
  }

  const [userState, setUserState] = useState(null);
  useEffect(() => {
    if (props.thisUserData.address) setUserState(props.thisUserData.address.state);
  }, [props.thisUserData]);

  function setStateValue(e) {
    props.formData.address.state = formDataFormatter(e);
    setUserState(e.target.value);
  }


  const [requiredDataMissing, setRequiredDataMissing] = useState(false);
  useEffect(() => {
    const requiredUserData = ['firstName', 'lastName', /*'street_address', 'city', 'state', 'zipcode'*/];
    for ( let i = 0; i < requiredUserData.length; i++ ) {
      let requiredUserDataField = requiredUserData[i];
      if ( !props.thisUserData[requiredUserDataField] ) {
        setRequiredDataMissing(true);
        break;
      } else {
        setRequiredDataMissing(false);
        continue;
      }
    }
  }, [props.thisUserData])


  return (
    <>
      {(props.queryStatus !== 200)
        ?
          <Preloader message="Loading..." logoSize={50} show={true} />
        :
          <Form id="updateUser" onSubmit={ (e) => props.updateUser(e.preventDefault()) }>

            {requiredDataMissing &&
              <Row className="justify-content-center">
                <Col>
                  <Alert variant="danger" className="text-center">
                    You must enter your Name & Address to proceed
                  </Alert>
                </Col>
              </Row>
            }

            <h5 className="my-4">Mailing Address</h5>
            <Row>
              <Col sm={12} className="mb-3">
                <Form.Group id="street_address">
                  <Form.Label>Street Address</Form.Label>
                  <Form.Control required={!props.adminPermissions} name="street_address" type="text" className={(!props.adminPermissions && props.thisUserData.hasOwnProperty('address') && (!props.thisUserData.address.hasOwnProperty('street_address') || props.thisUserData.address.street_address === null))?'border border-danger':null} defaultValue={props.thisUserData.address ? props.thisUserData.address.street_address : null} placeholder="Street Address" onChange={setFormAddressValues} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={4} className="mb-3">
                <Form.Group id="city">
                  <Form.Label>City</Form.Label>
                  <Form.Control required={!props.adminPermissions} name="city" type="text" className={(!props.adminPermissions && props.thisUserData.hasOwnProperty('address') && !props.thisUserData.address.hasOwnProperty('city'))?'border border-danger':null} defaultValue={props.thisUserData.address ? props.thisUserData.address.city : null} placeholder="City" onChange={setFormAddressValues} />
                </Form.Group>
              </Col>
              <Col sm={4} className="mb-3">
                <Form.Group id="state" className="mb-2">
                  <Form.Label>State</Form.Label>
                  <Form.Select required={!props.adminPermissions} name="state" id="state" className={(!props.adminPermissions && !userState)?'border border-danger':null} value={userState?userState:''} onChange={setStateValue} onBlur={setStateValue}>
                    {!userState &&
                      <option value="">State</option>
                    }
                    {
                      Object.keys(props.statesList).map(function (key, i) {
                        return (
                          <option value={props.statesList[key]} key={i}>{key}</option>
                        );
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group id="zipcode">
                  <Form.Label>Zip Code</Form.Label>
                  <Form.Control required={!props.adminPermissions} name="zipcode" type="tel" className={(!props.adminPermissions && props.thisUserData.hasOwnProperty('address') && !props.thisUserData.address.hasOwnProperty('zipcode'))?'border border-danger':null} defaultValue={props.thisUserData.address ? props.thisUserData.address.zipcode : null} placeholder="Zip Code" onChange={setFormAddressValues} />
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Update Address</Button>
            </div>

          </Form>
      }
    </>
  );
};


export const ManageUserPassword = (props) => {

  function setFormValues(e) {
    props.formData[e.target.name] = formDataFormatter(e);
  }


  return (
    <>
      {(props.queryStatus !== 200)
        ?
          <Preloader message="Loading..." logoSize={50} show={true} />
        :
          <Form id="updateUser" onSubmit={ (e) => props.updateUser(e.preventDefault()) }>

            <Row className="justify-content-center align-items-center">
              <Col md={6} className="mb-3">
                <Form.Group id="password" className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faUnlockAlt} />
                    </InputGroup.Text>
                    <Form.Control name="password" type="password" placeholder="New Password" onChange={setFormValues} />
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Update Password</Button>
            </div>

          </Form>
      }
    </>
  );
};
