export const Routes = {
    // public
    Homepage: { path: "/" },
    Search: { path: "/search" },
    PropertyInfo: { path: "/property/:id" },
    ListingInfo: { path: "/listing/:id" },

    // login
    Signin: { path: "/sign-in" },
    Signup: { path: "/sign-up" },
    ForgotPassword: { path: "/forgot-password" },
    ResetPassword: { path: "/reset-password" },

    // errors
    NotFound: { path: "/404" },
    ServerError: { path: "/500" },


    // dashboard
    DashboardOverview: { path: "/dashboard" },
    UserProfile: { path: "/dashboard/user/:id" },
    PropertyDetail: { path: "/dashboard/property/:id" },
    ListingDetail: { path: "/dashboard/listing/:id" },
    BookingDetail: { path: "/dashboard/booking/:id" },

    GuestDashboard: { path: "/dashboard/guest" },

    CustomerSupport: { path: "/dashboard/support" }
};