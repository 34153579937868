import React, { useState, useEffect } from "react";
import { Routes } from "../../routes";
import { Link, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import Alert from "react-bootstrap/Alert";

import AuthService from "../../services/auth.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faUnlockAlt, faUser } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, Container, InputGroup } from "react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";


const SignIn = (props) => {


  const {search} = useLocation();
  const searchParams = new URLSearchParams(search);
  const route = searchParams.get('route');
  const autoLogin = searchParams.get('autoLogin');


  // if user is already logged in, route to Dashboard or query variable "route"
  let currentUserData = Cookies.get('authToken');
  currentUserData = (currentUserData && currentUserData !== 'null') ? JSON.parse(currentUserData).data : null;
  if ( currentUserData && currentUserData.hasOwnProperty('accessToken') && currentUserData.accessToken ) {
    props.history.push(route?route:Routes.DashboardOverview.path);
  }


  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);


  const doLogin = (e) => {
    e.preventDefault();

    AuthService.login(username, password)
      .then(res => {
          Cookies.set('authToken', JSON.stringify(res));
          props.history.push(route?route:Routes.DashboardOverview.path);
      },
      err => {
        setErrorMessage('Invalid username or password.');
      });
  }

  useEffect(() => {
    if ( autoLogin && autoLogin.length === 24 ) {
      AuthService.autoLogin(autoLogin)
        .then(res => {
            Cookies.set('authToken', JSON.stringify(res));
            props.history.push(route?route:Routes.DashboardOverview.path);
        },
        err => {
          setErrorMessage('Login link is expired.');
        });
    }
  }, [autoLogin]);


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Homepage.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">

                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Sign in to REC-X</h3>
                </div>

                <Form className="mt-4" onSubmit={doLogin}>

                  <Form.Group id="username" className="mb-4">
                    <Form.Label>Your Username</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <Form.Control autoFocus required type="text" placeholder="Username" onChange={(event) => { setUsername(event.target.value) }} />
                    </InputGroup>
                  </Form.Group>
                  
                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" onChange={(event) => { setPassword(event.target.value) }} />
                    </InputGroup>
                  </Form.Group>


                  {errorMessage && 
                    <Alert variant="danger" className="text-center">
                      {errorMessage}
                    </Alert>
                  }

                  <Button variant="primary" type="submit" className="w-100">
                    Sign in
                  </Button>

                </Form>


                <div className="text-center mt-4">
                  <Card.Link as={Link} to={Routes.ForgotPassword.path} className="small text-end">Forgot password?</Card.Link>
                </div>

                <div className="d-flex justify-content-center align-items-center mt-3">
                  <span className="fw-normal">
                    Not registered?
                    <Card.Link as={Link} to={Routes.Signup.path} className="fw-bold">
                      {` Create account `}
                    </Card.Link>
                  </span>
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default SignIn;
