import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class NotificationService {

  customerSupport(data) {
    return axios.post(API_URL + '/contact', data);
  }

  guestHostMessenger(data, token) {
    return axios.post(API_URL + '/guestHostMessenger', data, { headers: authHeader(token) });
  }

}

export default new NotificationService();
