import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class BookingService {

  getBooking(id, token) {
    return fetch(API_URL + '/booking/' + id, { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }

  addBooking(id, data, token) {
    return axios.post(API_URL + '/public/listing/' + id + '/book', data, { headers: authHeader(token) });
  }

  updateBooking(id, data, token) {
    return axios.put(API_URL + '/booking/' + id, data, { headers: authHeader(token) });
  }

  removeTentativeBooking(id) { // ToDo : Check for errors on API
    return axios.delete(API_URL + '/booking/tentative/' + id);
  }

/*
  removeBooking(id, token) { // ToDo : Check for errors on API
    return axios.delete(API_URL + '/booking/' + id, { headers: authHeader(token) });
  }
*/

}

export default new BookingService();
