import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Routes } from '../../routes';
import queryString from 'query-string';
import Cookies from "js-cookie";

import PropertyService from '../../services/property.service';

import { listingTypesDefinitions } from '../../listingTypes/listingTypes';

import { SearchListings } from '../../components/forms/searchPublicListings';
import PublicFooter from '../../components/PublicFooter';
import { ImageCarousel } from '../../components/ImageCarousel';
import PropertyMap from "../map/PropertyMap";

import Preloader from '../../components/Preloader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faEdit } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Button, Container } from 'react-bootstrap';


const PropertyInfo = (props) => {
  let currentUserData = Cookies.get('authToken');
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
  } else {
    currentUserData = false;
  }

  const thisPropertyID = useParams();

  const [queryStatusProperty, setQueryStatusProperty] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  useEffect(() => {
    if ( !queryStatusProperty ) {
      PropertyService.getPropertyPublic(thisPropertyID.id)
        .then(res => {
          setQueryStatusProperty(res.status);
          if ( res.status === 200 ) {
            setPropertyData(res.body);
            setListingsList(res.body.listingsData);
          }
        })
        .catch(err => {
          // console.log(err);
        });
      }
  }, [thisPropertyID]);

  useEffect(() => {
    if ( queryStatusProperty === 200 ) document.title = propertyData.title+" | Property | REC-X";
  }, [queryStatusProperty, propertyData]);

  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Send to search page w/ hash parameters
  }


  // Check Property ID Exists
  if ( queryStatusProperty === 200 ) {

    return (
      <>
        <Row className="d-flex justify-content-center bg-primary pt-4 pb-5">
          <Col xl={11}>
            <Card>
              <Card.Body className="d-md-flex justify-content-between align-items-center">

                <h1 className="h5">
                  <FontAwesomeIcon icon={faMapMarkedAlt} className="me-2" />
                  <span className="text-secondary">{propertyData.title}</span>
                </h1>

                {currentUserData && // Check User viewing Profile is an admin or the User themself
                (currentUserData.id === propertyData.host || currentUserData.roles.includes('ROLE_ADMIN')) &&
                  <Button variant="outline-secondary" href={"/dashboard/property/"+propertyData._id}>
                    <FontAwesomeIcon icon={faEdit} className="me-2" /> Edit
                  </Button>
                }

              </Card.Body>
            </Card>
          </Col>
        </Row>


        {propertyData.hasOwnProperty('gallery') && Object.keys(propertyData.gallery).length > 0 &&
          <Row className="justify-content-center px-4 my-5">
            <Col>
              <Card className="bg-transparent border-0 overflow-hidden">
                <Card.Body className="p-0">

                  <ImageCarousel galleryData={propertyData.gallery} />

                </Card.Body>
              </Card>
            </Col>
          </Row>
        }


        {propertyData.description &&
          <Row className="px-4 my-5">
            <Col>
              <Card>
                <Card.Body>

                  <Row className="mb-3">
                    <Col>
                      <h6>Description:</h6>
                      {propertyData.description}
                    </Col>
                  </Row>

                </Card.Body>
              </Card>
            </Col>
          </Row>
        }


        <Row className="px-4 my-5">
          <Col>
            {listingsList &&
              <>
                <h2 className="fs-4 text-dark text-decoration-underline mb-4">Listings</h2>

                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3">
                  {
                    listingsList.map( (listing) => {
                      return (
                        <Col key={listing._id}>
                          <Card as="a" href={`/listing/${listing._id}`} className="h-100 position-relative">
                            <Card.Img variant="top" src={listing.gallery[0]?listing.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                            <Card.Body>
                              <Card.Title>{listing.title}</Card.Title>
                            </Card.Body>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              </>
            }
          </Col>
        </Row>


        <Row className="justify-content-center px-4 my-5">
          <Col>
            <Card>
              <Card.Body className="w-100 p-0">

                {propertyData.parcels &&
                  <PropertyMap thisPropertyData={propertyData} isMapSelected={true} />
                }

              </Card.Body>
            </Card>
          </Col>

        </Row>

        <PublicFooter />
      </>
    );

  } else if ( [204, 400, 404].includes(queryStatusProperty) ) {
    return (
      <>
        <section className="section-header pt-5 pt-lg-6 pb-9 pb-lg-12 bg-primary overflow-hidden" id="home">
          <Container>
            <Row className="text-white">
              <Col xs={12} className="text-center mt-6">
                <div className="react-big-icon d-none d-lg-block pt-4"><span className="flaticon-fishing"></span></div>
              </Col>
            </Row>

            <figure className="position-absolute bottom-0 left-0 w-100 d-none d-md-block mb-n2">
              <svg className="fill-soft" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3000 185.4">
                <path d="M3000,0v185.4H0V0c496.4,115.6,996.4,173.4,1500,173.4S2503.6,115.6,3000,0z" />
              </svg>
            </figure>

            <Row className="justify-content-center">
              <Col xl={4}>
                <Card>
                  <Card.Body className="text-center py-5">
                    <h4>Property Not Found</h4>
                    <h5 className="mb-0 text-danger">{thisPropertyID.id}</h5>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            <div className="text-white">
              <SearchListings listingTypesDefinitions={listingTypesDefinitions} formData={searchListingsFormData} searchListings={searchListings} />
            </div>

          </Container>
        </section>

        <PublicFooter />
      </>
    );
  } else {
    return (
      <div className="mt-7">
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }

};
export default PropertyInfo;
