import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "../../routes";
import Cookies from "js-cookie";

import NotificationService from "../../services/notification.service";

import { CustomerSupportForm } from "../../components/forms/customerSupport";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card } from 'react-bootstrap';


const CustomerSupport = (props) => {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  // let authToken = currentUserData.accessToken;

  document.title = "Customer Support | REC-X";

  const submitForm = (formData) => {
    NotificationService.customerSupport(formData)
      .then( async (res) => {
        window.alert( res.data.message);
        window.location.reload();
      },
      err => {
        window.alert("Customer Support message could not be submitted.");
      });
  }


  return (
    <>
      <Row className="justify-content-center mb-4">
        <Col md={10} xl={6}>
          <Card>
            <Card.Body className="d-flex justify-content-between align-items-center" style={{minHeight:'84px'}}>
              <h5 className="mb-0">
                <FontAwesomeIcon icon={faQuestionCircle} className="me-2" />
                Customer Support
              </h5>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Routes.DashboardOverview.path}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={Routes.CustomerSupport.path}>Customer Support</Link>
            </li>
          </ul>
        </Col>
      </Row>

      <Row className="justify-content-center mb-4">
        <Col md={10} xl={6}>

          <Card>
            <Card.Body>
      				<CustomerSupportForm currentUserData={currentUserData} submitForm={submitForm} />
    			  </Card.Body>
    			</Card>

        </Col>
      </Row>
    </>
  );

};
export default CustomerSupport;
