import { useState } from "react";
import { montanaCountyNames } from "./countyNames";
import { Form, Button } from "react-bootstrap";

const ParcelSearch = (props) => {
  const [validated, setValidated] = useState(false)

  const handleSubmit = (e) => {
    const form = e.currentTarget
    e.preventDefault()
    e.stopPropagation()
    if (form.checkValidity() === true) {
      props.setSearchModalOpen(false)
      props.setSidebarsVisible(true)
    }
    setValidated(true)
    props.handleSearch()
  }

  return (
    <div id="parcel-search" className={`w-100 h-100 bg-primary position absolute z-5 ${props.searchModalOpen ? "d-flex" : "d-none"} justify-content-center z-3`}>
      <div className={`w-75 d-flex flex-column text-white d-flex justify-content-center align-items-center`}>
        <h2 className="text-secondary fw-bolder mb-4 fs-3">PROPERTY SEARCH</h2>
        <Form noValidate validated={validated} onSubmit={handleSubmit} className="w-100 mb-3">
            <Form.Group >
              <Form.Label className="d-block text-center fs-5 form-label fw-medium">State</Form.Label>
              <Form.Control className="text-gray-600" as="select" value={props.stateName} disabled onChange={(event) => props.setStateName(event.target.value)}>
                <option value={"Montana"}>Montana</option>
              </Form.Control>
            </Form.Group>
            <Form.Group className="w-100 mt-2 mb-3">
              <Form.Label className="d-block text-center fs-5 form-label fw-medium">County</Form.Label>
              <Form.Control 
                className="form-select"
                as="select" 
                name="county"
                onChange={(event) => props.setCountyName(event.target.value)} 
                required>
              <option value="" disabled selected>Select a County...</option>
                {montanaCountyNames.map((county) => (
                  <option key={county} value={county}>
                    {county}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group className="w-100 mt-2 mb-3">
              <Form.Label className="d-block text-center fs-5 form-label fw-medium">Owner Name (Optional)</Form.Label>
              <Form.Control type="text" onChange={(event) => props.setOwnerName(event.target.value)} />
            </Form.Group>
            <Button className="mt-3 px-5 btn btn-outline-secondary mx-auto w-100" type="submit">
              Submit
            </Button>
        </Form>
      </div>
    </div>
  );
}
export default ParcelSearch;
