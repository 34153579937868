import { useState, useEffect, useRef, useParams } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import * as turf from '@turf/turf';
import { mapStyles } from "./mapStyles"
import MapboxDraw from "@mapbox/mapbox-gl-draw"
import { lineLabelStyles, defaultLabelStyles } from "./labelStyles"

const ListingMap = (props) => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN
  const mapContainer = useRef(null)
  const map = useRef(null)
  const draw = useRef(null)
  const defaultCenter = [-109.418, 47.037]
  const propertyCentroid = props.listingData.propertyData.property_centroid
  const propertyBbox = props.listingData.propertyData.property_bbox

  useEffect(() => {    
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: `mapbox://styles/mapbox/satellite-streets-v12?optimize=true`,
        center: propertyCentroid || defaultCenter,
        zoom: 10,
        pitch: 90
      })

      const handleMapLoad = () => {
        map.current.off("load", handleMapLoad);

        draw.current = new MapboxDraw({
          userProperties: true,
          displayControlsDefault: false,
          styles: mapStyles
        });
  
        if (props.listingData) {
          map.current.getLayer('property-line') 
            && map.current.removeLayer('property-line');
  
          map.current.getSource('montana-parcels')
            && map.current.removeSource('montana-parcels')
  
          map.current.addSource('montana-parcels', {
            type: "vector",
            url: process.env.REACT_APP_MAPBOX_TILESET_URL,
          }); 
  
          map.current.addLayer({
              source: 'montana-parcels',
              id: 'property-line',
              "source-layer": "montana-parcels",
              type: "line",
              paint: {
                "line-color": "#ff00ff",
                "line-opacity": 0.9,
                "line-width": 3,
              },
              minzoom: 10,
              filter: [
                "in",
                "PARCELID",
                ...props.listingData.propertyData.parcels
              ],
          });
  
          const addedMarkers = new Set();
  
          props.listingData.map_geoJSON.features && props.listingData.map_geoJSON.features.forEach((feature, index) => {
            if (addedMarkers.has(feature.id)) return
            addedMarkers.add(feature.id)
            
            const layerId = `feature-${index}`;
            const layers = [layerId, `${layerId}-line`, `${layerId}-fill`];
            
            layers.forEach(layer => {
              if (map.current.getLayer(layer)) {
                map.current.removeLayer(layer);
                map.current.removeSource(layer);
              }
            })
  
            // Polygons
            if (feature.geometry.type === 'Polygon') {
              map.current.addLayer({
                id: `${layerId}-line`,
                type: 'line',
                source: {
                  type: 'geojson',
                  data: feature
                },
                minzoom: 10,
                paint: {
                  'line-color': feature.properties?.color || '#00ffff',
                  'line-opacity': 1,
                  'line-width': 3,
                }
              }); 
  
              map.current.addLayer({
                id: `${layerId}-fill`,
                type: 'fill',
                source: {
                  type: 'geojson',
                  data: feature
                },
                minzoom: 10,
                paint: {
                  'fill-color': feature.properties?.color || '#00ffff',
                  'fill-opacity': feature.properties?.polygonFill ? 0.7 : 0.1
                }
              }); 
            }
  
            // LineStrings
            if (feature.geometry.type === 'LineString') {
              map.current.addLayer({
                id: layerId,
                type: 'line',
                source: {
                  type: 'geojson',
                  data: feature
                },
                minzoom: 10,
                paint: {
                  'line-color': feature.properties?.color || '#00ffff',
                  'line-opacity': 1,
                  'line-width': 3,
                }
              }); 
            } 
  
            // Points
            if (feature.geometry.type === 'Point' && feature.properties?.type === 'drawn-feature') {
              if (!map.current.getLayer(`point-${index}`)) {
                map.current.addLayer({
                  id: `point-${index}`,
                  type: 'circle',
                  source: {
                    type: 'geojson',
                    data: feature
                  },
                  minzoom: 10,
                  paint: {
                    'circle-radius': 6,
                    'circle-color': feature.properties?.color || '#00ffff',
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#ffffff'
                  }
                });
              }
            }
  
            // Markers with icons
            if (feature.geometry.type === 'Point' && feature.properties?.type === 'marker' ) {
              var el = document.createElement('div');
              el.className = 'marker';
              el.style.width = '40px';
              el.style.height = '40px';
              
              fetch(feature.properties.icon)
              .then(response => response.text())
              .then(svgContent => {
                  const parser = new DOMParser();
                  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
                  const svgElement = svgDoc.documentElement;
                  svgElement.setAttribute('fill', feature.properties.color || '#00ffff');
                  const svgString = new XMLSerializer().serializeToString(svgElement);
                  el.style.backgroundImage = `url('data:image/svg+xml;base64,${btoa(svgString)}')`;
              });
    
              el.style.backgroundSize = 'fit';
              el.style.backgroundRepeat = 'no-repeat';
              new mapboxgl.Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .addTo(map.current);
            }
            
            if (feature.properties && feature.properties.label) {
              const labelCoordinates = turf.centroid(feature).geometry.coordinates;
  
              if (!map.current.getSource(`label-source-${index}`)) {
                map.current.addSource(`label-source-${index}`, {
                  type: 'geojson',
                  data: {
                    type: 'Feature',
                    geometry: feature.geometry,
                    properties: {
                      label: feature.properties.label !== "Click to add label..." ? feature.properties.label : "",
                    }
                  }
                });
              }
          
              if (!map.current.getLayer(`label-layer-${index}`)) {
                const isLine = feature.geometry.type === 'LineString';
                map.current.addLayer({
                  type: 'symbol',
                  id: `label-layer-${index}`,
                  source: `label-source-${index}`,
                  layout: isLine ? lineLabelStyles.layout : defaultLabelStyles.layout,
                  paint: isLine ? lineLabelStyles.paint : defaultLabelStyles.paint,
                  minzoom: defaultLabelStyles.minzoom,
                });
              }
            }
          })   
        }
      }
      map.current.on('load', handleMapLoad)    

      return () => {
        map.current.off("load", handleMapLoad);
        map.current.remove();
        map.current = null;
        if (draw.current) draw.current = null
      }
    }
  }, [])

  useEffect(() => {
    if (props.isMapSelected && map.current) {
      map.current.resize()
      map.current.scrollZoom.disable()
      const zoomControlExists = map.current._controls && map.current._controls.some(control => control instanceof mapboxgl.NavigationControl)
      if (!zoomControlExists) map.current.addControl(new mapboxgl.NavigationControl())
      const currentZoom = map.current.getZoom()
      map.current.setZoom(currentZoom)
      
      const onIdle = () => {        
        map.current.fitBounds(propertyBbox, { padding: 80 })
        map.current.off('idle', onIdle)
      }
      map.current.on('idle', onIdle);
    }
  }, [props.isMapSelected])
        
  return (
    <div id="listing-map">
        <div id="map-container" ref={mapContainer} className="w-100 h-100 rounded"></div>
    </div>
  )
}

export default ListingMap;