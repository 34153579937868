import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const AddProperty = (props) => {

  const setTitle = props.setTitle;
  const setDescription = props.setDescription;

  return (
    <>
      <Row>
        <Col>
          <Form id="addProperty" onSubmit={ (e) => props.addProperty(e.preventDefault()) }>

            <Row>
              <Col className="mb-3">
                <Form.Group id="property-title">
                  <Form.Control required autoFocus type="text" placeholder="Property Title" onChange={(e) => { setTitle(e.target.value) }} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col className="mb-3">
                <Form.Group id="property-description">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={5} required type="text" placeholder="Description" onChange={(e) => { setDescription(e.target.value) }} />
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Add Property</Button>
            </div>

          </Form>
        </Col>
      </Row>
    </>
  );
};

export const RegisterUser = (props) => {

  function setFormValues(e) {
    props.formData[e.target.name] = e.target.value;
  }

  return (
    <Form id="registerUser" className="mt-4" onSubmit={ (e) => props.doRegister(e.preventDefault()) }>
      <Form.Group id="email" className="mb-4">
        <Form.Label>Email</Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
          <Form.Control name="email" required autoFocus type="email" placeholder="example@company.com" onChange={setFormValues} onBlur={setFormValues} />
        </InputGroup>
      </Form.Group>

      <Form.Group id="firstName" className="mb-4">
        <Form.Label>First Name</Form.Label>
        <InputGroup>
          <InputGroup.Text/>
          <Form.Control name="firstName" required type="text" placeholder="First Name" onChange={setFormValues} onBlur={setFormValues} />
        </InputGroup>
      </Form.Group>

      <Form.Group id="lastName" className="mb-4">
        <Form.Label>Last Name</Form.Label>
        <InputGroup>
          <InputGroup.Text/>
          <Form.Control name="lastName" required type="text" placeholder="Last Name" onChange={setFormValues} onBlur={setFormValues} />
        </InputGroup>
      </Form.Group>
{/*

      <Form.Group id="lasttname" className="mb-4">
        <Form.Label>Last Name</Form.Label>
        <InputGroup>
          <InputGroup.Text/>
          <Form.Control name="lastname" type="text" placeholder="Last Name" onChange={setFormValues} onBlur={setFormValues} />
        </InputGroup>
      </Form.Group>
      <Form.Group id="role" className="mb-4">
        <Form.Label>Select User Role</Form.Label>
        <Form.Select name="role" onChange={setFormValues} onBlur={setFormValues}>
          <option value="guest">Guest</option>
          <option value="host">Host</option>
          <option value="admin">Admin</option>
        </Form.Select>
      </Form.Group>
*/}

      <Form.Group id="password" className="mb-4">
        <Form.Label>Password <small className="text-italic">(optional)</small></Form.Label>
        <InputGroup>
          <InputGroup.Text>
            <FontAwesomeIcon icon={faUnlockAlt} />
          </InputGroup.Text>
          <Form.Control name="password" type="password" placeholder="Password" onChange={setFormValues} onBlur={setFormValues} />
        </InputGroup>
{/*
        <div className="fs-7 text-italic px-4">
          Password must contain:
          <ul>
            <li className="fs-7">At least 8 characters</li>
            <li className="fs-7">
              At least 3 of the following:
              <ul>
                <li className="fs-7">Lower case letters (a-z)</li>
                <li className="fs-7">Upper case letters (A-Z)</li>
                <li className="fs-7">Numbers (0-9)</li>
                <li className="fs-7">Special characters (ex. !@#$%^&*)</li>
              </ul>
            </li>
          </ul>
        </div>
*/}
      </Form.Group>

      <Button variant="primary" type="submit" className="w-100">
        Register User
      </Button>
    </Form>
  ) 

}
