import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import _ from "lodash";

import { statesList } from "../../components/data/statesList";
import { masterCategoryDefinitions, listingTypesDefinitions } from '../../listingTypes/listingTypes';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faSearch } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";


export const SearchListings = (props) => {

  const [highlightSubmitButton, setHighlightSubmitButton] = useState(false);

  const [searchListingsFormData, setSearchListingsFormData] = useState({});
  useEffect(() => {
    setSearchListingsFormData(props.formData);
  }, [props.formData]);

  function setFormValues(e) {
    // update state of searchListingsFormData
    let currentSearchListingsFormData = searchListingsFormData;
    currentSearchListingsFormData[e.target.name] = e.target.value;
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }


  const [searchState, setSearchState] = useState(0);
  useEffect(() => {
    setSearchState(props.formData.state);
  }, [props.formData.state]);
  function setStateValue(e) {
    let currentSearchListingsFormData = searchListingsFormData;
    if ( Object.values(statesList).includes(e.target.value) ) {
      currentSearchListingsFormData[e.target.name] = e.target.value;
    } else {
      delete currentSearchListingsFormData[e.target.name];
    }
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    setSearchState(e.target.value);
  }

/*
  const [activityFilterValues, setActivityFilterValues] = useState([]);
  useEffect(() => {
    setActivityFilterValues(props.formData.activity);
  }, [props.formData.activity]);

  function setActivityFilter(e) {
    const checkedState = e.target.checked;
    // store state into local
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};

    // if only 1 'activity' parsed from URL, convert to an array with that single value, then add subsequent values
    if ( typeof currentSearchListingsFormData['activity'] === 'string' ) {
      currentSearchListingsFormData['activity'] = [currentSearchListingsFormData['activity']];
      setActivityFilterValues([currentSearchListingsFormData['activity']]);
    }
    // if no 'activity' filters set, create array
    if ( !currentSearchListingsFormData.hasOwnProperty('activity') ) {
      currentSearchListingsFormData['activity'] = [];
    }

    if ( !currentSearchListingsFormData['activity'].includes(e.target.value) && checkedState ) {
      // Add new 'activity' value to array
      currentSearchListingsFormData['activity'] = _.concat(currentSearchListingsFormData['activity'], e.target.value);
      setActivityFilterValues(_.concat(currentSearchListingsFormData['activity'], e.target.value));
    } else {
      // Remove 'activity' from array if present
      currentSearchListingsFormData['activity'] = currentSearchListingsFormData['activity'].filter(el => el !== e.target.value);
      setActivityFilterValues(currentSearchListingsFormData['activity'].filter(el => el !== e.target.value));
    }

    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  }
*/

  const setCategoryButton = (c) => {
    // clear all active classes
    document.querySelectorAll('.category-wrapper').forEach(el => el.classList.remove('checked'));

    // add active class for category selected
    document.getElementById(c.id+'-label').parentNode.classList.toggle('checked');

    // remove selected activities to clear the way for selected group
    [...document.querySelectorAll('.activity-wrapper.checked > label')].map((a) => { a.click(); });

    // store state into local
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};

    currentSearchListingsFormData['category'] = c.id;

    // set activities for URL
    let category = _.find(masterCategoryDefinitions, {'id': c.id});
    currentSearchListingsFormData['activity'] = category.listingTypes;

    setHighlightSubmitButton(true);
  }


  const [dateRangeStart, setDateRangeStart] = useState(0);
  useEffect(() => {
    setDateRangeStart(props.formData.start);
  }, [props.formData.start]);
  const changeDateRangeStart = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeStart(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['start'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);

    // set next day after dateRangeStart
    changeDateRangeEnd(moment(e).add(1, 'day'));

    // auto-click End Date field
    document.querySelector('#dateRangeEnd input').focus();
  };

  const [dateRangeEnd, setDateRangeEnd] = useState(0);
  useEffect(() => {
    setDateRangeEnd(props.formData.end);
  }, [props.formData.end]);
  const changeDateRangeEnd = (e) => {
    let currentSearchListingsFormData = searchListingsFormData?searchListingsFormData:{};
    setDateRangeEnd(e.format('MM-DD-YYYY'));
    currentSearchListingsFormData['end'] = e.format('MM-DD-YYYY');
    setSearchListingsFormData(currentSearchListingsFormData);
    setHighlightSubmitButton(true);
  };

  var isValidEndDate = function(current) {
    // check date is NOT BEFORE user-selected start
    if ( dateRangeStart ) {
      if ( !current.isAfter( moment(dateRangeStart).add(-1, 'day') ) ) { return false; }
    }

    // check date is NOT BEFORE TODAY
    if ( !current.isAfter( moment().add(-1, 'day') ) ) { return false; }

    return true;
  };


  return (
    <>
      <Row className="justify-content-center my-1 mx-3 mx-md-0">
        <Col>
          <Form id="searchListings" onSubmit={ (e) => props.searchListings(e.preventDefault()) }>

            <Row>
              <Col className="mb-4 px-0">
                <h5 className="text-center mb-3">Select a Category:</h5>
                <Form.Group id="searchCategories" className="d-flex flex-row flex-wrap flex-fill justify-content-center justify-content-xl-between">
                  {
                    Object.values(masterCategoryDefinitions).map(function (c, i) {
                      return (
                        <Form.Check
                          type="checkbox"
                          // label={c.title}
                          name="category"
                          key={c.id}
                          id={c.id}
                          value={c.id}
                          className={`category-wrapper ${c.id}-wrapper text-center px-0 mx-1 mb-2`}
                        >
                          <Form.Check.Label 
                            id={`${c.id}-label`}
                            role="button"
                            onClick={() => setCategoryButton(c)}
                            className="border-2 border-bottom"
                          >
                            {c.title}
                          </Form.Check.Label>
                          <Form.Check.Input type="checkbox" isValid className="d-none" />
                        </Form.Check>
                      );
                    })
                  }
                </Form.Group>
              </Col>
            </Row>

            <Row className="d-flex justify-content-evenly justify-content-lg-between">
              <Col xs={12} lg={4} className="d-flex ps-0 mb-3">
                <Form.Group id="dateRangeStart" className="w-50">
                  {/*<Form.Label className="d-block text-center fs-5">Start Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeStart}
                    closeOnSelect={true}
                    isValidDate={(current) => {return current.isAfter( moment().add(-1, 'day') )}} // check date is NOT BEFORE TODAY

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeStart ) ? moment(dateRangeStart).format("MM/DD/YYYY") : "" }
                          placeholder="Start Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeStart(e.target.value) }}
                          className="border-end-0"
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>

                <Form.Group id="dateRangeEnd" className="w-50">
                  {/*<Form.Label className="d-block text-center fs-5">End Date</Form.Label>*/}
                  <Datetime
                    className="text-dark"
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={changeDateRangeEnd}
                    closeOnSelect={true}
                    isValidDate={isValidEndDate}

                    renderInput={(props, openCalendar, closeCalendar) => (
                      <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                        <Form.Control
                          type="text"
                          defaultValue={ ( dateRangeEnd ) ? moment(dateRangeEnd).format("MM/DD/YYYY") : "" }
                          placeholder="End Date"
                          onFocus={openCalendar}
                          onChange={(e) => { changeDateRangeEnd(e.target.value) }}
                        />
                      </InputGroup>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col xs={6} lg={2} className="px-0 mb-3">
                <Form.Group id="searchState">
                  {/*<Form.Label className="d-block text-center fs-5">State</Form.Label>*/}
                  <Form.Select id="state" name="state" value="MT" /*value={searchState}*/ onChange={setStateValue} onBlur={setStateValue}>
                    <option value="MT">Montana</option>
{/*
                    <option value={false}>State</option>
                    {
                      Object.keys(statesList).map(function (key, i) {
                        return (
                          <option value={statesList[key]} key={i}>{key}</option>
                        );
                      })
                    }
*/}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col xs={6} lg={4} className="pe-0 pe-lg-2 mb-3">
                <Form.Group id="searchText">
                  <InputGroup>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroup.Text>
                    <Form.Control name="searchText" defaultValue={props.formData.searchText && props.formData.searchText} type="text" placeholder="Search Term" onChange={setFormValues} onBlur={setFormValues} />
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col className="ps-lg-2 pe-lg-0 mb-3">
                <Button variant={highlightSubmitButton?'secondary':'outline-secondary'} type="submit" className="px-5 w-100">Search</Button>
              </Col>
            </Row>

          </Form>
        </Col>
      </Row>
    </>
  );
}
