import "mapbox-gl/dist/mapbox-gl.css";

function MapTooltip(props) {

  return (
    <div id="map-tooltip" className="position-absolute left-2 right-2 mx-auto w-max-75 my-4 py-1 px-2 text-center text-primary bg-warning fw-bold rounded z-1 h-0">
        {props.text}
    </div>
  )
}

export default MapTooltip;