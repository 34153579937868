export const defaultLabelStyles = {
    layout: {
        'text-field': ['get', 'label'],
        'text-variable-anchor': ['top', 'bottom', 'left', 'right'],
        'text-radial-offset': 1.5,
        'text-justify': 'auto',  
      },
    paint: {
        'text-color': '#ffffff',
        'text-halo-color': '#000000',
        'text-halo-width': 2,
    },
    minzoom: 10,
}

export const lineLabelStyles = {
    layout: {
        'text-field': ['get', 'label'],
        'symbol-placement': 'line-center',    
        'symbol-spacing': 1000000,
    },
    paint: {
        'text-color': '#ffffff',
        'text-halo-color': '#000000',
        'text-halo-width': 2,
    },
}


