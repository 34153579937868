import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faDollarSign, faEnvelope, faPaperPlane } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup, Alert, Modal } from "react-bootstrap";

import {formDataFormatter} from "../data/formDataFormatter";

import Preloader from "../Preloader";


export const ManageBooking = (props) => {

  function setFormValues(e) {
    props.formData[e.target.name] = formDataFormatter(e);
    props.thisBookingData[e.target.name] = formDataFormatter(e);
  }


  const [status, setStatus] = useState(0);
  useEffect(() => {
    setStatus(props.thisBookingData.status);
  }, [props.thisBookingData.status]);

  function setStatusValue(e) {
    setFormValues(e);
    setStatus(e.target.value);
  }


  const [beginDate, setBeginDate] = useState("");
  useEffect(() => {
    setBeginDate(props.thisBookingData.start);
  }, [props.thisBookingData.start]);
  const changeBeginDate = (e) => {
    setBeginDate(e.toDate());
    // props.updateBookingFormData.start = e.toDate();
    props.formData.start = e.toDate();

    // auto-set end date
    changeEndDate(moment(e));
  };

  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    setEndDate(props.thisBookingData.end);
  }, [props.thisBookingData.end]);
  const changeEndDate = (e) => {
    setEndDate(e.toDate());
    // props.updateBookingFormData.end = e.toDate();
    props.formData.end = e.toDate();
  };

  var isValidEndDate = function(current) {
    // check date is AFTER START DATE
    if ( beginDate ) {
      if ( current < moment(beginDate) ) return false;
    }
    return true;
  };

  return (
    <>
      <Row>
        <Col>
          {(props.queryStatus !== 200)
          ?
            <Preloader message="Loading..." logoSize={50} show={true} />
          :
            <>
              <Form id="updateBooking" onSubmit={ (e) => props.updateBooking(e.preventDefault()) }>

                {props.thisBookingData.transaction && props.thisBookingData.transaction.status === 'succeeded' && props.thisBookingData.status === 'pending' &&
                  <Row className="justify-content-center">
                    <Col>
                      <Alert variant="warning" className="text-center">
                        Final approval is pending.
                      </Alert>
                    </Col>
                  </Row>
                }

                <Row className="justify-content-center">
                  <Col md={4} className="mb-3">
                    <Form.Group>
                      <Form.Label className="d-block text-center">Booking Status</Form.Label>
                      <Form.Select disabled={(props.currentUserData.id === props.thisBookingData.guest)?true:false} name="status" id="booking-status" value={status} onChange={setStatusValue} onBlur={setStatusValue}>
                        <option value="pending">Pending</option>
                        <option value="approved">Approved</option>
                        <option value="completed">Completed</option>
                        <option value="cancelled">Cancelled</option>
                        <option value="declined">Declined</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>

                <hr />

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="final_price">
                      <Form.Label>Total Price</Form.Label>
                      <InputGroup>
                        <InputGroup.Text>
                          <FontAwesomeIcon icon={faDollarSign} />
                        </InputGroup.Text>
                        <Form.Control disabled={(props.currentUserData.id === props.thisBookingData.guest)?true:false} name="final_price" type="number" placeholder="Price" step="0.01" defaultValue={props.thisBookingData.final_price} onChange={setFormValues} onBlur={setFormValues} />
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="occupants">
                      <Form.Label>Number of Guests</Form.Label>
                      <Form.Control disabled={(props.currentUserData.id === props.thisBookingData.guest)?true:false} name="occupants" type="number" placeholder="0" min="1" defaultValue={props.thisBookingData.occupants} onChange={setFormValues} onBlur={setFormValues} />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className="mb-3">
                    <Form.Group id="start_date">
                      <Form.Label>Start Date</Form.Label>
                      {(props.currentUserData.id === props.thisBookingData.guest)
                      ?
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            disabled
                            type="text"
                            defaultValue={ ( beginDate ) ? moment(beginDate).format("MM/DD/YYYY") : "" }
                            placeholder="MM/DD/YYYY"
                          />
                        </InputGroup>
                      :
                        <Datetime
                          // input
                          dateFormat="MM/DD/YYYY"
                          timeFormat={false}
                          onChange={changeBeginDate}
                          closeOnSelect={true}

                          renderInput={(props, openCalendar, closeCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                type="text"
                                defaultValue={ ( beginDate ) ? moment(beginDate).format("MM/DD/YYYY") : "" }
                                placeholder="MM/DD/YYYY"
                                onFocus={openCalendar}
                                onChange={(e) => { setBeginDate(e.target.value) }}
                              />
                            </InputGroup>
                          )}
                        />
                      }
                    </Form.Group>
                  </Col>
                  <Col md={6} className="mb-3">
                    <Form.Group id="end_date">
                      <Form.Label>End Date</Form.Label>
                      {(props.currentUserData.id === props.thisBookingData.guest)
                      ?
                        <InputGroup>
                          <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                          <Form.Control
                            disabled
                            type="text"
                            defaultValue={ ( endDate ) ? moment(endDate).format("MM/DD/YYYY") : "" }
                            placeholder="MM/DD/YYYY"
                          />
                        </InputGroup>
                      :
                        <Datetime
                          // input
                          dateFormat="MM/DD/YYYY"
                          timeFormat={false}
                          onChange={changeEndDate}
                          closeOnSelect={true}
                          isValidDate={isValidEndDate}

                          renderInput={(props, openCalendar, closeCalendar) => (
                            <InputGroup>
                              <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                              <Form.Control
                                type="text"
                                defaultValue={ ( endDate ) ? moment(endDate).format("MM/DD/YYYY") : "" }
                                placeholder="MM/DD/YYYY"
                                onFocus={openCalendar}
                                onChange={(e) => { setEndDate(e.target.value) }}
                              />
                            </InputGroup>
                          )}
                        />
                      }
                    </Form.Group>
                  </Col>
                </Row>

                <hr />
                
                <Row>
                  <Col className="mb-3">
                    <Form.Group id="emergency_contact">
                      <Form.Label>Emergency Contact</Form.Label>
                      <Form.Control name="emergency_contact" as="textarea" rows={3} type="text" placeholder="Name & Phone number" defaultValue={props.thisBookingData.emergency_contact} onChange={setFormValues} onBlur={setFormValues} />
                    </Form.Group>
                  </Col>
                </Row>

                {(props.currentUserData.id === props.thisBookingData.guest)
                ?
                  null
                :
                  <Row>
                    <Col className="mb-3">
                      <Form.Group id="host_notes">
                        <Form.Label>Host Notes <small><em>(not shared with Guest)</em></small></Form.Label>
                        <Form.Control name="host_notes" as="textarea" rows={3} type="text" placeholder="Host Notes" defaultValue={props.thisBookingData.host_notes} onChange={setFormValues} onBlur={setFormValues} />
                      </Form.Group>
                    </Col>
                  </Row>
                }

                <div className="mt-3 d-flex justify-content-evenly">

                  <Button variant="success" type="submit" className="px-4">Update</Button>
                  {/*<Button variant="danger" className="px-4" onClick={() => props.removeBooking(id)}>Delete</Button>*/}

                  <Button
                    variant="warning"
                    onClick={() => props.setMessageModal(true)}
                    className="text-white px-4"
                  >
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" /> 
                    Send Message to {(props.currentUserData.id === props.thisBookingData.guest)?'Host':'Guest'}
                  </Button>

                </div>

              </Form>


              <Modal 
                size="lg"
                centered
                show={props.messageModal} 
                onHide={() => props.setMessageModal(false)}
              >
                <Modal.Header closeButton>
                  <Modal.Title>
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" /> Message {(props.currentUserData.id === props.thisBookingData.guest)?'Host':'Guest'}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form 
                    id="sendMessage" 
                    onSubmit={(e) => props.sendMessage(e)}
                  >
                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="message_content">
                          <Form.Control 
                            name="message_content" 
                            as="textarea" 
                            rows={10} 
                            type="text" 
                            placeholder="Your message&hellip;" 
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="text-center">
                        <Button 
                          variant="secondary" 
                          type="submit" 
                          className="text-white px-4"
                        >
                          <FontAwesomeIcon icon={faPaperPlane} className="me-2" /> 
                          Send Email
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Modal.Body>
              </Modal>

            </>
          }
        </Col>
      </Row>
    </>
  );
}
