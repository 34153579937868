export const montanaCountyNames = [
  "Beaverhead",
  "Big Horn",
  "Blaine",
  "Broadwater",
  "Carbon",
  "Carter",
  "Cascade",
  "Chouteau",
  "Custer",
  "Daniels",
  "Dawson",
  "Deer Lodge",
  "Fallon",
  "Fergus",
  "Flathead",
  "Gallatin",
  "Garfield",
  "Glacier",
  "Golden Valley",
  "Granite",
  "Hill",
  "Jefferson",
  "Judith Basin",
  "Lake",
  "Lewis and Clark",
  "Liberty",
  "Lincoln",
  "Madison",
  "McCone",
  "Meagher",
  "Mineral",
  "Missoula",
  "Musselshell",
  "Park",
  "Petroleum",
  "Phillips",
  "Pondera",
  "Powder River",
  "Powell",
  "Prairie",
  "Ravalli",
  "Richland",
  "Roosevelt",
  "Rosebud",
  "Sanders",
  "Sheridan",
  "Silver Bow",
  "Stillwater",
  "Sweet Grass",
  "Teton",
  "Toole",
  "Treasure",
  "Valley",
  "Wheatland",
  "Wibaux",
  "Yellowstone",
];
