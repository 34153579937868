import React from 'react';

import { Image } from 'react-bootstrap';

import RecXHero from "../assets/img/rec-x/deer-color.png";

const Preloader = (props) => {

  const { show, message, messageClasses, fullscreen, logoSize, bg } = props;

  return (
    <div className={`preloader_recX flex-column justify-content-center align-items-center ${show ? "" : "show"} ${fullscreen ? "fullscreen" : ""} ${!bg ? "" : "bg-soft"}`}>
      <span className={messageClasses}>{message}</span>
      <Image className="loader-element" src={RecXHero} height={logoSize} />
    </div>
  );
};
export default Preloader;
