import React from 'react';
import { Route } from "react-router-dom";
import { Routes } from "../../routes";
import Cookies from "js-cookie";
import ReactGA from "react-ga4";

// import Preloader from "../Preloader";
import { GlobalNavbar } from '../Navbar';
import PublicFooter from '../PublicFooter';

const ProtectedRoute = ({ component, ...args }) => {

  let authToken = Cookies.get('authToken');
  authToken = authToken ? JSON.parse(authToken) : null;
  authToken = authToken ? authToken.data : null;
  if ( authToken === null ) window.location.replace(Routes.Signin.path+'?route='+window.location.pathname);

/*
  // Restrict User from using Dashboard until User Profile required fields are set
  let excludePages = ['/dashboard/support'];
  if ( !excludePages.includes(window.location.pathname) && authToken ) {
    let redirectURL = process.env.REACT_APP_WEBAPP_URL_ROOT+'/dashboard/user/'+authToken.id;

    if ( authToken.hasOwnProperty('firstName') || authToken.hasOwnProperty('lastName') || authToken.hasOwnProperty('address') ) {
      const requiredUserData = ['firstName', 'lastName', 'address'];
      for ( let i = 0; i < requiredUserData.length; i++ ) {
        let requiredUserDataField = requiredUserData[i];
        if ( !authToken.hasOwnProperty(requiredUserDataField) || !authToken[requiredUserDataField] || !authToken.hasOwnProperty('address') || Object.keys(authToken.address).length < 4 ) {
          if ( redirectURL !== window.location.href ) {
            window.location.href = redirectURL;
          }
          break;
        }
      }
    }
  }
*/


  if ( typeof(authToken) !== 'undefined' && authToken.accessToken !== null ) {
    // Wait for page title to be set
    setTimeout(() => {
      ReactGA.initialize([
        {
          trackingId: "G-68Y57DFMWE",
          gaOptions: {
            userId: authToken.email
          },
          gtagOptions: {
            userHash: authToken.id,
            // userRole: authToken.role
          }
        }
      ]);
      ReactGA.send({
        hitType: "pageview",
        page: window.location.href
      });
    }, 2000);
  } else {
    // If missing authToken, redirect to login page
    window.location.replace(Routes.Signin.path);
  }


  return (
    <>
      <GlobalNavbar variant="dashboard" currentUserData={authToken} showDashboardModeToggle={true} />
      <Route component={component} {...args} />
      <PublicFooter />
    </>
  );
}

export default ProtectedRoute;
