import React, { useState, useEffect } from "react";
import { Link, useParams } from 'react-router-dom';
import { Routes } from "../../routes";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import BookingService from "../../services/booking.service";

import { ManageBooking } from "../../components/forms/manageBooking";
import { StripePaymentForm } from "../../components/forms/stripePaymentForm";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faChartPie } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Badge, Button } from 'react-bootstrap';


const BookingDetail = (props) => {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const thisBookingID = useParams();

  const [queryStatusBooking, setQueryStatusBooking] = useState(false);
  const [thisBookingData, setThisBookingData] = useState(false);
  useEffect(() => {
    BookingService.getBooking(thisBookingID.id, authToken)
      .then(res => {
        setQueryStatusBooking(res.status);

        if ( res.status === 200 ) {
          setThisBookingData(res.body);
        } else {
          props.history.push(Routes.NotFound.path);
        }
      })
  }, [thisBookingID.id, authToken]);

  useEffect(() => {
    if ( thisBookingData.start ) document.title = "Booking at "+thisBookingData.listingData.title+" for "+((thisBookingData.guestData.firstName || thisBookingData.guestData.lastName) ? `${thisBookingData.guestData.firstName} ${thisBookingData.guestData.lastName}` : "")+" on "+moment(thisBookingData.start).format('MM/DD/YYYY')+" | REC-X";
  }, [thisBookingData]);


  let updateBookingFormData = {}
  const updateBooking = (e) => {

    if ( Object.keys(updateBookingFormData).length > 0 ) {
      BookingService.updateBooking(thisBookingData._id, updateBookingFormData, authToken)
        .then(res => {
          window.alert( res.data.message + '\nFields updated: ' + Object.keys(updateBookingFormData) );
        },
        err => {
          window.alert("Booking could not be updated.");
        }
      )
    }

  }

  const removeBooking = (id) => {
    if ( window.confirm("Remove booking " + id + "?") ) {
      const result = BookingService.removeBooking(id);
      if ( result ) {
        window.location.reload();
      } else {
        alert("Booking " + id + " was not removed.");
      }
    }
  }


  if ( queryStatusBooking !== 204 ) { // Check Booking ID Exists

    return (
      <>
        <Row className="justify-content-center mb-4">
          <Col md={10} xl={6}>
            <Card>
              <Card.Body className="d-flex justify-content-between align-items-center" style={{minHeight:'84px'}}>
                <h5 className="mb-0"><FontAwesomeIcon icon={faCalendarAlt} className="me-2" /> Booking Details</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {thisBookingData &&
          <Row>
            <Col>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to={Routes.DashboardOverview.path}>Dashboard</Link>
                </li>
                {(currentUserData.id !== thisBookingData.guest) &&
                  <>
                    {currentUserData.roles.includes('ROLE_ADMIN') && thisBookingData.host &&
                      <li className="breadcrumb-item">
                        <Link to={"/dashboard/user/"+thisBookingData.host}>{(thisBookingData.hostData.firstName || thisBookingData.hostData.lastName)?`${thisBookingData.hostData.firstName} ${thisBookingData.hostData.lastName}`:thisBookingData.hostData.username}</Link>
                      </li>
                    }
                    <li className="breadcrumb-item">
                      <Link to={((currentUserData.id !== thisBookingData.guest)?'/dashboard':'')+"/property/"+thisBookingData.property}>{thisBookingData.propertyData.title}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to={((currentUserData.id !== thisBookingData.guest)?'/dashboard':'')+"/listing/"+thisBookingData.listingData._id}>{thisBookingData.listingData.title}</Link>
                    </li>
                  </>
                }
                <li className="breadcrumb-item">
                  <Link to={"/dashboard/booking/"+thisBookingData._id}>Booking on {thisBookingData.start && moment(thisBookingData.start).format('MM/DD/YYYY')}</Link>
                </li>
              </ul>
            </Col>
          </Row>
        }
        <Row className="justify-content-center mb-4">

          <Col xl={6}>
            <Card>
              <Card.Body>
                <Row>
                  <Col className="position-relative">

                    {thisBookingData.refund && thisBookingData.refund.status === 'succeeded' &&
                      <Badge bg="danger" className="fs-6 position-absolute top-0 end-0 mt-n4 me-n3 px-3 py-2">Refunded</Badge>
                    }
                    {thisBookingData.transaction && !thisBookingData.hasOwnProperty('refund') && thisBookingData.transaction.status === 'succeeded' &&
                      <Badge bg="success" className="fs-6 position-absolute top-0 end-0 mt-n4 me-n3 px-3 py-2">Paid</Badge>
                    }

                    {thisBookingData &&
                      <Row>
                        <Col className="text-center mb-2">
                          <h1 className="fs-4">
                            <Link to={((currentUserData.id !== thisBookingData.guest)?'/dashboard':'')+"/listing/"+thisBookingData.listingData._id} className="text-success">{thisBookingData.listingData.title}</Link>
                            &nbsp;at <Link to={((currentUserData.id !== thisBookingData.guest)?'/dashboard':'')+"/property/"+thisBookingData.propertyData._id} className="text-success">{thisBookingData.propertyData.title}</Link>
                            {thisBookingData.start?' on '+moment(thisBookingData.start).format('MM/DD/YYYY'):null}
                            &nbsp;for <Link to={"/dashboard/user/"+thisBookingData.guestData._id} className="text-success">{(thisBookingData.guestData.firstName || thisBookingData.guestData.lastName) ? `${thisBookingData.guestData.firstName} ${thisBookingData.guestData.lastName}` : thisBookingData.guestData.username}</Link>
                          </h1>
                        </Col>
                      </Row>
                    }

                    <ManageBooking queryStatus={queryStatusBooking} updateBooking={updateBooking} removeBooking={removeBooking} thisBookingData={thisBookingData} formData={updateBookingFormData} currentUserData={currentUserData} />

                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {thisBookingData.hasOwnProperty('transaction') &&
            <Col xl={6} className="mt-4 mt-xl-0">
              <Card>
                <Card.Body>
                  <Row>
                    <Col>

                      <StripePaymentForm queryStatus={queryStatusBooking} thisBookingData={thisBookingData} />

                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          }

        </Row>
      </>
    );

  } else {
    return (
      <>
        <Row className="justify-content-center">
          <Col xl={4}>
            <Card>
              <Card.Body className="text-center py-5">
                <h4>Booking Not Found</h4>
                <h5 className="text-danger">{thisBookingID.id}</h5>
                <Button variant="outline-primary" href={Routes.DashboardOverview.path} className="mt-3">
                  <FontAwesomeIcon icon={faChartPie} className="me-2" />
                  Return to your Dashboard
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
};
export default BookingDetail;
