import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class AuthService {

  login(username, password) {
    return axios.post(API_URL + '/signin', {usernameEmail: username, password: password});
  }

  register(data) {
    return axios.post(API_URL + '/signup', data);
  }

  updatePassword(id, data, token) {
    if (token) {
      return axios.put(API_URL + '/user/'+id+'/password', data, { headers: authHeader(token) })
    }
  }

  forgotPassword(data) {
    return axios.post(API_URL + '/forgotPassword', data);
  }

  autoLogin(autoLogin) {
    return axios.get(API_URL + '/autoLogin/' + autoLogin);
  }

}

export default new AuthService();
