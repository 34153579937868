import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class PaymentService {

  stripeRefund(data, token) {
    return axios.post(API_URL + "/payment/refund", data, { headers: authHeader(token) });
  }

}

export default new PaymentService();
