const formDataFormatter = (e) => {


  if ( e.hasOwnProperty('target') ) {

    if ( e.target.value ) {
      switch ( e.target.value ) {
        case 'true':
          return Boolean(true);
        case 'false':
          return Boolean(false);
        default:
          return e.target.value;
      }
    }

    if ( e.target.type ) {
      switch ( e.target.type ) {
        case 'tel':
          return Number(e.target.value);
        case 'number':
          return parseFloat(e.target.value);
        default:
          return e.target.value;
      }
    }

  } else {
    return e;
  }

}

module.exports = {
  formDataFormatter
};
