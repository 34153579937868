import React from 'react';
import { Link } from 'react-router-dom';

import { Col, Row, Container, Navbar } from 'react-bootstrap';

const PublicFooter = () => {

  return (
    <footer className="footer py-6 bg-dark text-white">
      <Container>
        <Row className="d-flex text-center justify-content-center align-items-center">
          <Col md={6}>
            <Navbar.Brand as={Link} to="/" className="me-lg-3 mb-3 d-flex justify-content-center align-items-center">
               <span className="icon icon-secondary icon-lg">
                <span className="flaticon-deer"></span>
              </span>
              <span className="ms-2 brand-text">REC-X</span>
            </Navbar.Brand>
            <p>REC-X is a marketplace for recreational experiences on private properties. Use our tools to find your next activity and connect with property owners offering unique experiences catered to your goals!</p>
          </Col>
        </Row>
        <hr className="bg-gray my-5" />
        <Row>
          <Col className="mb-md-2">
            <div className="d-flex text-center justify-content-center align-items-center" role="contentinfo">
              <p className="font-weight-normal font-small mb-0">Copyright REC-X &copy; <span className="current-year">{new Date().getFullYear()}</span>. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
export default PublicFooter;
