import React from "react";
import { Link } from 'react-router-dom';
import { Routes } from "../routes";
import Cookies from "js-cookie";
import queryString from "query-string";
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faSignInAlt, faChartPie, faSearch, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

import logoIcon from "../assets/img/rec-x/logo/logo-icon-color-rec-x.svg";
import logoText from "../assets/img/rec-x/logo/logo-text-color-rec-x.svg";

import { SearchListings } from "./forms/searchPublicListingsHeader";

import { Row, Col, Nav, Card, Image, Navbar, Dropdown, Container } from 'react-bootstrap';


export const GlobalNavbar = (props) => {

  let currentUserData = Cookies.get('authToken');
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
  } else {
    currentUserData = false;
  }


  const dashboardModeToggle = () => {
    if ( window.location.pathname.includes('guest') ) {
      window.location = Routes.DashboardOverview.path;
    } else {
      window.location = Routes.GuestDashboard.path;
    }
  }


  let searchListingsFormData = {};
  const searchListings = () => {
    let queryHash = queryString.stringify(searchListingsFormData);
    window.location.href = Routes.Search.path+'#'+queryHash; // Send to search page w/ hash parameters
    // console.log(Routes.Search.path+'#'+queryHash);
  }


  return (
    <Row 
      id="global-nav-bar" 
      className={`py-3 px-3 variant-${props.variant} ${props.variant === 'public'?'':''} ${props.variant === 'overlay'?'position-absolute top-0 w-100':''}`}
    >
      <Col>
        <div className="m-0 py-2">
          <Navbar expanded className="p-0">
            <Container fluid className="px-0">
              <div className="d-flex justify-content-between align-items-center w-100 flex-wrap">

                <div className="d-flex align-items-center flex-shrink-1">
                  <h3 className="text-secondary mb-0">
                    <Card.Link as={Link} to={props.variant === 'dashboard' ? Routes.DashboardOverview.path : Routes.Homepage.path} title="REC-X" className="d-flex align-items-center justify-content-center my-3">
                      <Image src={logoIcon} height="50" className="me-2 me-md-4" alt="REC-X icon" />
                      <Image src={logoText} height="50" alt="REC-X logo" />
                    </Card.Link>
                  </h3>
                </div>

                { props.variant === 'public' &&
                  <div className="order-3 order-xl-0 d-flex align-items-center border border-gray-900 rounded-pill shadow p-1 mx-auto">
                    <SearchListings formData={searchListingsFormData} searchListings={searchListings} />
                  </div>
                }

                { props.variant === 'dashboard' &&
                  <div className="d-flex flex-row align-items-center">

                    { currentUserData && 
                      !currentUserData.roles.includes('ROLE_ADMIN') &&
                      props.showDashboardModeToggle &&

                      <div id="dashboard-mode-wrapper" className="me-1 me-sm-5 text-center">
                        <Form.Check
                          checked={!window.location.pathname.includes('guest')}
                          type="switch"
                          id="dashboard-mode"
                          label={`Switch to ${window.location.pathname.includes('guest')?'Host':'Guest'} Mode`}
                          className="d-flex flex-column align-items-center"
                          onChange={dashboardModeToggle}
                        />
                      </div>

                    }

                  </div>
                }

                <UserNavbarDropdown variant={props.variant} currentUserData={currentUserData} />

              </div>
            </Container>
          </Navbar>
        </div>
      </Col>
    </Row>
  );
}


export const UserNavbarDropdown = (props) => {
  return (
    <Nav className={`align-items-center ${props.variant}`}>
      {props.currentUserData && Object.keys(props.currentUserData).length 
        ? 
        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
            <div className="d-flex align-items-center">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <FontAwesomeIcon icon={faUserCircle} className="text-primary fs-2" />
                <span className="fs-6 mt-1">{props.currentUserData && props.currentUserData.username}</span>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
            <Dropdown.Item className="fw-bold" as={Link} to="/search">
              <FontAwesomeIcon icon={faSearch} className="me-2" /> Search Listings
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to="/dashboard">
              <FontAwesomeIcon icon={faChartPie} className="me-2" /> My Dashboard
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to={"/dashboard/user/"+props.currentUserData.id}>
              <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to="/dashboard/support">
              <FontAwesomeIcon icon={faQuestionCircle} className="me-2" /> Customer Support
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="fw-bold" onClick={() => {Cookies.set('authToken', null); window.location.replace(Routes.Homepage.path);}}>
              <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        : 
        <Nav.Item>
          <Nav.Link as={Link} to={Routes.Signin.path} className="btn btn-outline-primary d-flex align-items-center">
            <FontAwesomeIcon icon={faSignInAlt} />
            <span className="ms-2 mb-0 font-small fw-bold">Login</span>
          </Nav.Link>
        </Nav.Item>
      }
    </Nav>
  );
}
