import React from "react";
import Cookies from "js-cookie";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons";

import PaymentService from "../../services/payment.service";

import { Col, Row, Form, InputGroup, Button, Alert } from "react-bootstrap";


export default function RefundForm(props) {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const handleRefund = async (e) => {
    e.preventDefault();

    let refundAmount = e.target['amount'].value;

    let refundItem = {
      booking: props.thisBookingData._id,
      payment_intent: props.thisBookingData.transaction.id,
      amount: refundAmount
    };
    PaymentService.stripeRefund(refundItem, authToken)
      .then(res => {
        if ( res.status === 200 ) {
          window.alert('Refund of $'+Number(res.data.refund.amount / 100).toFixed(2)+' '+res.data.refund.status);
          window.location.reload();
        } else {
// console.log(res);
          window.alert('Refund unsuccessful');
        }
      });
  };


  return (
    <>
      {props.thisBookingData && !props.thisBookingData.hasOwnProperty('refund') &&
        <Form id="refund-form" onSubmit={handleRefund}>

          <Row className="justify-content-center">
            <Col sm={4} className="mb-3">
              <Form.Group id="amount">
                <Form.Label className="d-block text-center">Refund Amount</Form.Label>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faDollarSign} />
                  </InputGroup.Text>
                  <Form.Control required name="amount" type="number" step="0.01" min="0.01" max={Number(props.thisBookingData.transaction.amount / 100).toFixed(2)} defaultValue={Number(props.thisBookingData.transaction.amount / 100).toFixed(2)} placeholder="Refund Amount" />
                </InputGroup>
              </Form.Group>
            </Col>
            <Col sm={4} className="mb-3">
              <div className="py-3"></div>
              <Button variant="danger" type="submit" id="submit" className="px-4">Refund</Button>
            </Col>
          </Row>


        </Form>
      }


      {props.thisBookingData && props.thisBookingData.hasOwnProperty('refund') && 
        <Alert variant='warning' className="text-center">
          Refund issued for ${Number(props.thisBookingData.refund.amount / 100).toFixed(2)}.
        </Alert>
      }
    </>

  );
}
