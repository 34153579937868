import React, { useState, useEffect } from "react";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import bootstrapPlugin from "@fullcalendar/bootstrap";

import { Col, Row, Button } from "react-bootstrap";

import Preloader from "./Preloader";


export const BookingsCalendar = (props) => {

  const queryStatus = props.queryStatus;
  const calEventSelected = props.calEventSelected;

  const [bookingsList, setBookingsList] = useState([]);
  useEffect(() => {
    const formatted = props.bookingsList.map( e => {
      e.id = e._id;
      e.title = e.guestData ? `${e.guestData.firstName} ${e.guestData.lastName} (${e.occupants}) @ ${e.listingData.title}` : e._id;
      return e;
    });
    setBookingsList(formatted);
  }, [props.bookingsList]);


  return (
    <div className="bookings-calendar">

      <Row className="mb-3">
        <Col md={8} className="d-flex align-items-center">
          <h4>{props.heading ? props.heading : 'Bookings'} ({bookingsList.length})</h4>
        </Col>

        {(props.thisUserData && props.thisUserData.role !== 'admin') &&
          <Col md={4} className="text-md-right">
            <Button variant="secondary" className="text-gray" target="_blank" href={props.thisUserData.calendar && 'https://calendar.google.com/calendar/r?cid=webcal%3A%2F%2F'+process.env.REACT_APP_API_URL_ROOT.replace('https://','')+'/calendar/'+props.thisUserData.calendar+'.ics'}>
              Import to Google Calendar
            </Button>
          </Col>
        }

      </Row>

      <Row>
        <Col>
          {/*{( queryStatus && ![200,204].indexOf(queryStatus) )*/}
          {( queryStatus !== 200 && queryStatus !== 204 && queryStatus !== 401 && queryStatus !== 404 )
            ?
              <Preloader message="Loading..." logoSize={50} show={true} />
            :
              <FullCalendar
                plugins={[ dayGridPlugin, listPlugin, bootstrapPlugin ]}
                themeSystem="bootstrap"
                initialView="dayGridMonth"
                height={1050}
                stickyHeaderDates={true}
                headerToolbar={{
                  left: 'dayGridMonth,listMonth,listYear',
                  center: 'title',
                  right: 'today prev,next'
                }}
                buttonText={{
                  today: 'Today',
                  month: 'Month',
                  listMonth: 'Month List',
                  listYear: 'Year List',
                }}
                // buttonText={{list: 'list'}}
                events={bookingsList}
                defaultAllDay={true}
                eventClick={calEventSelected}
                // initialDate={initialDate}
                timeZone="UTC"
                nextDayThreshold="00:00:00"
              />
          }
        </Col>
      </Row>

    </div>
  );

};
