import React, { useState, useEffect } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Cookies from "js-cookie";

import { Col, Row, Alert } from "react-bootstrap";

import CheckoutForm from "./stripeCheckoutForm";
import RefundForm from "./stripeRefundForm";

import Preloader from "../Preloader";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);

export const StripePaymentForm = (props) => {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;

  const [clientSecret, setClientSecret] = useState("");
  useEffect(() => {
    if (props.thisBookingData.transaction) {
      setClientSecret(props.thisBookingData.transaction.client_secret);
    }
  }, [props.thisBookingData.transaction]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <>
          {(props.queryStatus !== 200) || !clientSecret
            ?
              <Preloader message="Loading..." logoSize={50} show={true} />
            :

              <>
                <Row>
                  <Col className="text-center mb-3">
                    <h5>Payment</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>

                    {props.thisBookingData.transaction && !props.thisBookingData.hasOwnProperty('refund') &&
                      <Alert variant={props.thisBookingData.transaction.status === 'succeeded'?'success':'warning'} className="text-center">
                        Payment {props.thisBookingData.transaction.status?props.thisBookingData.transaction.status:'required'}.
                      </Alert>
                    }

                    {(!props.thisBookingData.transaction || props.thisBookingData.transaction.status !== 'succeeded') &&
                      <Elements stripe={stripePromise} options={options} key={clientSecret}>
                        <CheckoutForm thisBookingData={props.thisBookingData} />
                      </Elements>
                    }
                    {currentUserData.id !== props.thisBookingData.guest && props.thisBookingData.transaction && props.thisBookingData.transaction.status === 'succeeded' && 
                      <RefundForm thisBookingData={props.thisBookingData} />
                    }
                    
                  </Col>
                </Row>
              </>

          }
    </>
  );
}
