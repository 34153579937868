import React, { useState } from "react";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import Alert from "react-bootstrap/Alert";

import AuthService from "../../services/auth.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faEnvelope, faUnlockAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, FormCheck, Container, InputGroup } from "react-bootstrap";
import BgImage from "../../assets/img/illustrations/signin.svg";


const SignUp = (props) => {

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState(false);

  const doRegister = (e) => {
    e.preventDefault();

    const data = {
      'email': email,
      'password': password,
      'firstName': firstName,
      'lastName': lastName
    }

    AuthService.register(data)
      .then(res => {
          Cookies.set('authToken', JSON.stringify(res));
          props.history.push('/dashboard/user/'+res.data.id);
      })
      .catch(err => {
        setErrorMessage(err.response.data.message);
      });
  }
/*
  const doConfirmPassword = (e) => {
    if ( e.target.value !== (password || null) ) {
      document.getElementById('matchPasswords').classList.remove("d-none");
      document.getElementById('matchPasswords').classList.add("d-block");
    } else {
      document.getElementById('matchPasswords').classList.add("d-none");
      document.getElementById('matchPasswords').classList.remove("d-block");
    }
  }
*/


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <p className="text-center">
            <Card.Link as={Link} to={Routes.Homepage.path} className="text-gray-700">
              <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to homepage
            </Card.Link>
          </p>
          <Row className="justify-content-center form-bg-image" style={{ backgroundImage: `url(${BgImage})` }}>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="mb-4 mb-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <div className="text-center text-md-center mb-4 mt-md-0">
                  <h3 className="mb-0">Create an account</h3>
                </div>
                <Form className="mt-4" onSubmit={doRegister}>

                  <Form.Group id="email" className="mb-4">
                    <Form.Label>Your Email</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroup.Text>
                      <Form.Control required autoFocus type="email" placeholder="example@company.com" onChange={(e) => { setEmail(e.target.value) }} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="firstName" className="mb-4">
                    <Form.Label>First Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text/>
                      <Form.Control required type="text" placeholder="First Name" onChange={(e) => { setFirstName(e.target.value) }} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="lasttName" className="mb-4">
                    <Form.Label>Last Name</Form.Label>
                    <InputGroup>
                      <InputGroup.Text/>
                      <Form.Control required type="text" placeholder="Last Name" onChange={(e) => { setLastName(e.target.value) }} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group id="password" className="mb-4">
                    <Form.Label>Your Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Password" onChange={(e) => { setPassword(e.target.value) }} />
                    </InputGroup>
                  </Form.Group>
{/*
                  <Form.Group id="confirmPassword" className="mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUnlockAlt} />
                      </InputGroup.Text>
                      <Form.Control required type="password" placeholder="Confirm Password" onChange={(e) => { doConfirmPassword(e) }} />
                    </InputGroup>
                    <div className="px-5">
                      <small className="text-italic">Password must be at least 8 characters including a number, a symbol, & a capital letter.</small>
                    </div>
                    <small id="matchPasswords" className="d-none text-white bg-warning rounded-2 py-2 px-3 mt-2">
                      Passwords must match.
                    </small>
                  </Form.Group>
*/}
                  <FormCheck type="checkbox" className="d-flex mb-4">
                    <FormCheck.Input required id="terms" className="me-2" />
                    <FormCheck.Label htmlFor="terms">
                      I agree to the <Card.Link>terms and conditions</Card.Link>
                    </FormCheck.Label>
                  </FormCheck>


                  {errorMessage && 
                    <Alert variant="danger" className="text-center" style={{whiteSpace: 'pre-line'}}>
                      {errorMessage}
                    </Alert>
                  }


                  <Button variant="primary" type="submit" className="w-100">
                    Sign up
                  </Button>
                </Form>

                <div className="d-flex justify-content-center align-items-center mt-4">
                  <span className="fw-normal">
                    Already have an account?
                    <Card.Link as={Link} to={Routes.Signin.path} className="fw-bold">
                      {` Login here `}
                    </Card.Link>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default SignUp;
