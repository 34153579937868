import bigGameHunting from "./bigGameHunting.json";
import varmintHunting from "./varmintHunting.json";
import birdHunting from "./birdHunting.json";
import fishing from "./fishing.json";
import camping from "./camping.json";
import hiking from "./hiking.json";
import photography from "./photography.json";
import wildlifeViewing from "./wildlifeViewing.json";
import farmTours from "./farmTours.json";
import waterSports from "./waterSports.json";
import horsebackRiding from "./horsebackRiding.json";
import rockClimbing from "./rockClimbing.json";
import snowshoeing from "./snowshoeing.json";
import orienteering from "./orienteering.json";
import foraging from "./foraging.json";
import shedHunting from "./shedHunting.json";
import targetShooting from "./targetShooting.json";
import snowsports from "./snowsports.json";
import gliding from "./gliding.json";
import diving from "./diving.json";
import discGolf from "./discGolf.json";
import golf from "./golf.json";
import racketSports from "./racketSports.json";
import airsoftPainball from "./airsoftPainball.json";
import running from "./running.json";
import cycling from "./cycling.json";
import yoga from "./yoga.json";
import events from "./events.json";
import offRoading from "./offRoading.json";
import generalRecreation from "./generalRecreation.json";

import { faDrumstickBite, faFrog, faCrow, faFish, faCampground, faBaseballBall, faTree, faTruckMonster, faMountain } from "@fortawesome/free-solid-svg-icons";


export const masterCategoryDefinitions = [
	{
		'id': 'hunting',
		'title': 'Hunting',
		'icon': faDrumstickBite,
		'listingTypes': ['bigGameHunting', 'varmintHunting', 'birdHunting']
	},
	{
		'id': 'fishing',
		'title': 'Fishing',
		'icon': faFish,
		'listingTypes': ['fishing']
	},
	{
		'id': 'campingRVing',
		'title': 'Camping & RV\'ing',
		'icon': faCampground,
		'listingTypes': ['camping']
	},
	{
		'id': 'natureActivities',
		'title': 'Nature Activities',
		'icon': faFrog,
		'listingTypes': ['hiking', 'photography', 'wildlifeViewing', 'farmTours', 'waterSports', 'horsebackRiding', 'rockClimbing', 'snowshoeing', 'orienteering', 'foraging', 'shedHunting']
	},
	{
		'id': 'sportsExercise',
		'title': 'Sports Exercise',
		'icon': faBaseballBall,
		'listingTypes': ['targetShooting', 'snowsports', 'gliding', 'diving', 'discGolf', 'golf', 'racketSports', 'airsoftPainball', 'running', 'cycling', 'yoga']
	},
	{
		'id': 'events',
		'title': 'Events',
		'icon': faTree,
		'listingTypes': ['events']
	},
	{
		'id': 'offRoadAdventures',
		'title': 'Off-Road Adventures',
		'icon': faTruckMonster,
		'listingTypes': ['offRoading']
	},
	{
		'id': 'generalRecreation',
		'title': 'General Recreation',
		'icon': faMountain,
		'listingTypes': ['generalRecreation']
	}
];


export const listingTypesDefinitions = {
	'bigGameHunting': bigGameHunting,
	'varmintHunting': varmintHunting,
	'birdHunting': birdHunting,
	'fishing': fishing,
	'camping': camping,
	'hiking': hiking,
	'photography': photography,
	'wildlifeViewing': wildlifeViewing,
	'farmTours': farmTours,
	'waterSports': waterSports,
	'horsebackRiding': horsebackRiding,
	'rockClimbing': rockClimbing,
	'snowshoeing': snowshoeing,
	'orienteering': orienteering,
	'foraging': foraging,
	'shedHunting': shedHunting,
	'targetShooting': targetShooting,
	'snowsports': snowsports,
	'gliding': gliding,
	'diving': diving,
	'discGolf': discGolf,
	'golf': golf,
	'racketSports': racketSports,
	'airsoftPainball': airsoftPainball,
	'running': running,
	'cycling': cycling,
	'yoga': yoga,
	'events': events,
	'offRoading': offRoading,
	'generalRecreation': generalRecreation
};


export const categoriesIcons = {
	'hunting': faDrumstickBite,
	'fishing': faFish,
	'camping':  faCampground,
	'natureActivities': faFrog,
	'sportsExercise': faDrumstickBite,
	'events': faDrumstickBite,
	'offRoadAdventures': faTruckMonster,
	'generalRecreation': faDrumstickBite
};


export const listingTypesIcons = {
	'bigGameHunting': faDrumstickBite,
	'varmintHunting': faFrog,
	'birdHunting': faCrow,
	'fishing': faFish,
	'camping':  faCampground
};
