import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Routes } from "../../routes";
import _ from "lodash";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import ListingService from "../../services/listing.service";
import BookingService from "../../services/booking.service";

import { listingTypesDefinitions, listingTypesIcons } from "../../listingTypes/listingTypes";

import { ManageListing } from "../../components/forms/manageListing";
import { AddBooking } from "../../components/forms/addBooking";
import { BookingsCalendar } from "../../components/Calendars";
import { ManageImageGallery } from "../../components/forms/manageImageGallery";
import Map from "../map/Map";
import ListingMap from "../map/ListingMap";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRunning, faCalendarAlt, faChartPie, faArrowAltCircleUp, faPlusCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Button, Modal, Nav, Tab, Badge } from 'react-bootstrap';


const ListingDetail = (props) => {
  let currentUserData = Cookies.get('authToken');
  let authToken = false;
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
    authToken = currentUserData.accessToken;
  } else {
    currentUserData = false;
  }

  const { id } = useParams();

  const [queryStatusListing, setQueryStatusListing] = useState(false);
  const [listingData, setListingData] = useState([]);
  const [bookingsList, setBookingsList] = useState([]);
  const [upcomingBookingsList, setUpcomingBookingsList] = useState([]);
  useEffect(() => {
    ListingService.getListing(id, authToken)
      .then(
        res => {
          setQueryStatusListing(res.status);

          if ( res.status === 200 ) {
            setListingData(res.body);

            // attach Listing data & Property Data
            let relevantListingData = {};
            relevantListingData._id = res.body._id;
            relevantListingData.title = res.body.title;
            relevantListingData.host = res.body.host;
            relevantListingData.gallery = res.body.gallery;
            for ( let i = 0; i < res.body.bookings.length; i++ ) {
              res.body.bookings[i].listingData = relevantListingData;
            }

            // set All Bookings data
            setBookingsList(res.body.bookings);

            // exclude old bookings
            let upcomingBookings = _.filter(res.body.bookings, function(b) { // Filter out past Bookings beyond ~2 days before today
              if ( b.start >= new Date(Date.now() - 1728e5).toISOString() ) return b;
            });
            // sort upcoming bookings
            upcomingBookings = _.orderBy(upcomingBookings, ['start', 'status'], ['asc', 'asc']);
            // set Upcoming Bookings data
            setUpcomingBookingsList(upcomingBookings);
          } else {
            props.history.push(Routes.NotFound.path);
          }
        }
      )
  }, [id, authToken]);

  const [activeKey, setActiveKey] = useState('bookings');

  useEffect(() => {
    if ( listingData.title ) document.title = listingData.title + " | Listing | REC-X";
    if ( queryStatusListing && !listingData.active ) setActiveKey('listing-details');
  }, [listingData]);


  const [listingIcon, setListingIcon] = useState(faRunning);
  useEffect(() => {
    if ( Object.keys(listingData).length > 0 && listingData.hasOwnProperty('listingType') && listingTypesIcons.hasOwnProperty(listingData.listingType[0]) ) {
      setListingIcon( listingTypesIcons[listingData.listingType[0]] );
    }
  }, [listingData]);


  const [apiResponseMessage, setApiResponseMessage] = useState(false);
  const updateListing = ( formData ) => {

    if ( Object.keys(formData).length > 0 ) {

      // Merge updated formData into listingData
      let updatedData = {};
      _.assign(updatedData, listingData, formData);
      setListingData(updatedData);

      ListingService.updateListing(id, formData, authToken)
        .then(res => {
          delete formData.gallery; // No window.alert message for Gallery Images
          if ( Object.keys(formData).length ) setApiResponseMessage({status: res.status, message: res.data.message, updatedFields: Object.keys(formData)});
        },
        err => {
          setApiResponseMessage({status: err.status, message: 'Listing could not be updated.'});
        })
    }

  }


  const updateListingStatus = (boolean) => {
    if ( window.confirm(boolean?'Publish this listing?':'Deactivate this listing?') ) {
      ListingService.updateListing(listingData._id, {active: boolean}, authToken)
        .then((res) => {
          if ( res.status === 200 ) {
            setApiResponseMessage({status: res.status, message: res.data.message, updatedFields: [boolean?'Listing Activated':'Listing Deactivated']});
            setListingData({...listingData, 'active': boolean});
            document.activeElement.blur();
          }
        },
        err => {
          setApiResponseMessage({status: err.status, message: 'Listing could not be updated.'});
        });
    }
  }


  const duplicateListing = (e) => {
    if ( window.confirm("Duplicate listing "+listingData.title+"?") ) {
      const duplicateListingFormData = listingData;
      delete duplicateListingFormData._id;
      duplicateListingFormData.title += " (DUPLICATE)";
      duplicateListingFormData.active = false;

      ListingService.addListing(duplicateListingFormData, authToken)
        .then(res => {
          alert("Listing " + duplicateListingFormData.title + " was successfully created.");
          props.history.push("/dashboard/listing/"+res.data.id);
        },
          err => {
            alert("Listing " + duplicateListingFormData.title + " could not be created.");
          });
    }
  }


  const removeListing = (id) => {
    if ( window.confirm("Remove listing "+listingData.title+"?") ) {
      ListingService.removeListing(id, authToken)
        .then(res => {
            props.history.push("/dashboard/property/"+listingData.property);
            alert("Listing " + listingData.title + " was successfully removed. All Bookings associated with this Listing have been removed.");
        })
        .catch(err => {
          alert(err.response.data.message ? err.response.data.message : "Listing " + listingData.title + " was not removed.");
        });
    }
  }


  const calEventSelected = (e) => {
    window.open('/dashboard/booking/'+e.event.id,'_blank');
  }


  let addBookingFormData = {
    'listing': listingData._id,
    'property': listingData.property,
    'host': listingData.host
  }
  const addBooking = (e) => {
    // e.preventDefault();
    if ( !addBookingFormData.hasOwnProperty('final_price') ) {
      addBookingFormData.final_price = addBookingFormData.calcPrice;
    }
    delete addBookingFormData.calcPrice;

      BookingService.addBooking(listingData._id, addBookingFormData, authToken)
        .then((res) => {
            props.history.push("/dashboard/booking/"+res.data._id);
          },
          err => {
            // console.log(err);
          });
  }


  const [addBookingModalOpen, setAddBookingModalOpen] = useState(false);
  const [editMapModalOpen, setEditMapModalOpen] = useState(false);

  const [isMapSelected, setIsMapSelected] = useState(false);
  useEffect(() => {
    activeKey === 'map'  ?  setIsMapSelected(true) : setIsMapSelected(false);
  }, [activeKey]);

  if ( queryStatusListing && queryStatusListing !== 204 ) { // Check Listing ID Exists

    return (
      <>
        <Row className="justify-content-center mb-4">
          <Col>
            <Card>
              <Card.Img variant="top" src={listingData.hasOwnProperty('gallery') && listingData.gallery.length ? listingData.gallery[0] : '/images/smith-river-mt-paradise-bend.jpg'} className="h-15vw" />
              <Card.Body>

                <Row>
                  <Col className="d-md-flex justify-content-between align-items-center">
                    <h5>
                      <FontAwesomeIcon icon={faRunning} className="me-2" />
                      Listing Details: <span className="text-secondary">{listingData.title}</span> 
                      { listingData.hasOwnProperty('propertyData') &&
                        <>
                          &nbsp;at <a href={`/dashboard/property/${listingData.propertyData._id}`} className="text-underline">{listingData.propertyData.title}</a>
                        </>
                      }
                    </h5>

                    <a href="/dashboard" className="btn btn-outline-secondary px-3">
                      <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2" />
                      Dashboard
                    </a>
                  </Col>
                </Row>

                <Row>
                  <Col className="py-4">
                    <Tab.Container
                      activeKey={activeKey}
                      onSelect={(e) => setActiveKey(e)}
                      id="property-tabs"
                      className="my-3"
                    >

                      <Row>
                        <Col>
                          <Nav variant="tabs" fill>
                            <Nav.Item>
                              <Nav.Link eventKey="bookings">Upcoming Bookings</Nav.Link>
                            </Nav.Item>
                            { bookingsList.length > 0 &&
                              <Nav.Item>
                                <Nav.Link eventKey="calendar">Calendar</Nav.Link>
                              </Nav.Item>
                            }
                            <Nav.Item>
                              <Nav.Link eventKey="photos">Photos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="map">Map</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="listing-details">Listing Details</Nav.Link>
                            </Nav.Item>
                            { listingData.active
                              ?
                                <Nav.Item>
                                  <Nav.Link 
                                    onClick={(e) => { window.open(`/listing/${listingData._id}`, '_blank') || window.location.replace(`/listing/${listingData._id}`) }} 
                                    className="btn-outline-secondary"
                                  >
                                    View Live
                                  </Nav.Link>
                                </Nav.Item>
                              :
                                <Nav.Item>
                                  <Nav.Link onClick={() => updateListingStatus(true)} className="btn-outline-secondary">Publish Listing</Nav.Link>
                                </Nav.Item>
                            }
                          </Nav>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pt-5">
                          <Tab.Content>

                            <Tab.Pane eventKey="bookings">

                              <h2 className="fs-4 text-dark text-decoration-underline mb-4">Upcoming Bookings</h2>

                              <Row className="row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3">
                                {
                                  upcomingBookingsList.map( (booking) => {
                                    return (
                                      <Col key={booking._id}>
                                        <Card as="a" href={`/dashboard/booking/${booking._id}`} className="h-100">
                                          <Badge 
                                            bg={['pending', 'cancelled', 'declined'].includes(booking.status)?'warning':'success'} 
                                            className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2"
                                          >
                                              {booking.status}
                                          </Badge>
                                          <Card.Img variant="top" src={booking.listingData.gallery?booking.listingData.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                                          <Card.Body>
                                            <Card.Title>{booking.listingData.title}</Card.Title>
                                            <Card.Text>{booking.guestData.firstName} {booking.guestData.lastName} on {moment(booking.start).format('MM/DD/YYYY')}</Card.Text>
                                          </Card.Body>
                                        </Card>
                                      </Col>
                                    )
                                  })
                                }

                                <Col>
                                  <Card 
                                    role="button" 
                                    onClick={() => setAddBookingModalOpen(true)} 
                                    className="h-100 align-items-center justify-content-center py-5"
                                  >
                                    <FontAwesomeIcon icon={faPlusCircle} className="fa-2x text-secondary" />
                                    <h3 className="fs-5 mt-3">Add New Booking</h3>
                                  </Card>
                                </Col>
                              </Row>

                              <Modal show={addBookingModalOpen} onHide={() => setAddBookingModalOpen(false)}>
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    <FontAwesomeIcon icon={faCalendarAlt} className="me-2" /> New Booking
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <AddBooking addBooking={() => addBooking()} listingData={listingData} formData={addBookingFormData} />
                                </Modal.Body>
                              </Modal>

                            </Tab.Pane>

                            { bookingsList.length > 0 &&
                              <Tab.Pane eventKey="calendar">
                                <BookingsCalendar queryStatus={queryStatusListing} bookingsList={bookingsList} calEventSelected={calEventSelected} />
                              </Tab.Pane>
                            }

                            <Tab.Pane eventKey="photos">
                              <ManageImageGallery data={listingData} updateData={updateListing} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="map">

                              { Object.keys(listingData).length > 0 &&
                                <ListingMap listingData={listingData} isMapSelected={isMapSelected}/>
                              }

                              <Row>
                                <Col className="text-center mt-4">
                                  <Button variant="secondary" className="d-block d-sm-inline-block mx-auto mx-sm-1 my-2 my-sm-0 px-4 text-white" onClick={() => setEditMapModalOpen(true)}>Edit Map</Button>
                                </Col>
                              </Row>

                              <Modal show={editMapModalOpen} onHide={() => setEditMapModalOpen(false)} fullscreen>
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    <FontAwesomeIcon icon={faPencilAlt} className="me-2" /> Edit Listing Map
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Map mapMode="editListing" listingData={listingData} />
                                </Modal.Body>
                              </Modal>

                            </Tab.Pane>

                            <Tab.Pane eventKey="listing-details">
                              <h2 className="fs-4 text-dark text-decoration-underline mb-4">Listing Details</h2>
                              <ManageListing 
                                queryStatus={queryStatusListing} 
                                listingData={listingData} 
                                updateListing={updateListing} 
                                updateListingStatus={updateListingStatus} 
                                apiResponseMessage={apiResponseMessage} 
                                setApiResponseMessage={setApiResponseMessage} 
                                duplicateListing={() => duplicateListing()} 
                                removeListing={removeListing} 
                                listingTypesDefinitions={listingTypesDefinitions} 
                              />
                            </Tab.Pane>

                          </Tab.Content>
                        </Col>
                      </Row>

                    </Tab.Container>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );

  } else if ( queryStatusListing && queryStatusListing === 204 ) {
    return (
      <>
        <Row className="justify-content-center">
          <Col xl={4}>
            <Card>
              <Card.Body className="text-center py-5">
                <h4>Listing Not Found</h4>
                <h5 className="text-danger">{id}</h5>
                <Button variant="outline-primary" href={Routes.DashboardOverview.path} className="mt-3">
                  <FontAwesomeIcon icon={faChartPie} className="me-2" />
                  Return to your Dashboard
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div className="mt-5" style={{height: '40vh'}}>
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }

};
export default ListingDetail;
