import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class NotificationService {


  customerSupport(data) {
    return axios.post(API_URL + '/contact', data);
  }

}

export default new NotificationService();
