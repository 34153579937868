import React, { useState } from "react";
import { Routes } from "../../routes";
import { Link } from "react-router-dom";
import queryString from "query-string";
import Alert from "react-bootstrap/Alert";

import AuthService from "../../services/auth.service";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Card, Button, Container, InputGroup } from "react-bootstrap";


const ForgotPassword = (props) => {

  let queryVars = queryString.parse(props.location.search);
  const [email, setEmail] = useState(queryVars.email);

  const [queryStatus, setQueryStatus] = useState(false);

  const forgotPassword = (e) => {
    e.preventDefault();

    const data = {
      'email': email
    }

    AuthService.forgotPassword(data)
      .then(res => {
        setQueryStatus(res.status);
      },
      (err) => {
        // console.log(err);
      });
  }


  return (
    <main>
      <section className="d-flex align-items-center my-5 mt-lg-6 mb-lg-5">
        <Container>
          <Row className="justify-content-center">
            <p className="text-center">
              <Card.Link as={Link} to={Routes.Signin.path} className="text-gray-700">
                <FontAwesomeIcon icon={faAngleLeft} className="me-2" /> Back to sign in
            </Card.Link>
            </p>
            <Col xs={12} className="d-flex align-items-center justify-content-center">
              <div className="signin-inner my-3 my-lg-0 bg-white shadow-soft border rounded border-light p-4 p-lg-5 w-100 fmxw-500">
                <h3>Forgot your password?</h3>
                <p className="mb-4">Don't fret! Just type in your email and we will send you a code to reset your password!</p>

                <Form onSubmit={forgotPassword}>

                  <div className="mb-4">
                    <Form.Label htmlFor="email">Your Email</Form.Label>
                    <InputGroup id="email">
                      <Form.Control autoFocus required type="email" defaultValue={email} placeholder="example@company.com" onChange={(e) => { setEmail(e.target.value) }} />
                    </InputGroup>
                  </div>

                  {queryStatus === 204 &&
                    <Alert variant="success" className="text-center">
                      Check your email for a login link!
                    </Alert>
                  }

                  <Button variant="primary" type="submit" className="w-100">
                    Recover password
                  </Button>

                </Form>

              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </main>
  );
};
export default ForgotPassword;
