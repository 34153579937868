import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Routes } from "../../routes";
import _ from "lodash";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import PropertyService from "../../services/property.service";

import { ManageProperty } from "../../components/forms/manageProperty";
import Map from "../map/Map";
import { BookingsCalendar } from "../../components/Calendars";
import { ManageImageGallery } from "../../components/forms/manageImageGallery";
import PropertyMap from "../map/PropertyMap";

import Preloader from "../../components/Preloader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkedAlt, faRunning, faChartPie, faPlusCircle, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

import { Col, Row, Card, Button, Modal, Tab, Nav, Badge } from 'react-bootstrap';


const PropertyDetail = (props) => {
  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;

  const thisPropertyID = useParams();

  const [queryStatusProperty, setQueryStatusProperty] = useState(false);
  const [thisPropertyData, setThisPropertyData] = useState([]);
  const [bookingsList, setBookingsList] = useState([]);
  const [upcomingBookingsList, setUpcomingBookingsList] = useState([]);
  const [listingsList, setListingsList] = useState([]);
  useEffect(() => {
    if ( !queryStatusProperty && currentUserData ) {
      PropertyService.getProperty(thisPropertyID.id, authToken)
        .then(res => {
          setQueryStatusProperty(res.status);
          if ( res.status === 200 ) {
            setThisPropertyData(res.body);

            // set Listings Data
            const sortedListingsList = _.orderBy(res.body.listings, ['active', 'bookings.length', 'title'], ['desc', 'desc', 'asc']);
            setListingsList(sortedListingsList);

            // compile Bookings Data
            let bookings = [];
            // loop listings
            for ( let iA = 0; iA < res.body.listings.length; iA++ ) {
              if ( Object.keys(res.body.listings[iA]).length ) {
                // loop those bookings
                for ( let iB = 0; iB < res.body.listings[iA].bookings.length; iB++ ) {
                  if ( Object.keys(res.body.listings[iA].bookings[iB]).length ) {
                    res.body.listings[iA].bookings[iB]['listingData'] = { gallery: res.body.listings[iA].gallery };
                    bookings.push(res.body.listings[iA].bookings[iB]);
                  }
                }
              }
            }

            // set All Bookings data
            setBookingsList(bookings);

            // exclude old bookings
            bookings = _.filter(bookings, function(b) { // Filter out past Bookings beyond ~2 days before today
              if ( b.start >= new Date(Date.now() - 1728e5).toISOString() ) return b;
            });
            // sort bookings
            bookings = _.orderBy(bookings, ['start', 'status'], ['asc', 'asc']);
            // set Upcoming Bookings data
            setUpcomingBookingsList(bookings);
          } else {
            props.history.push(Routes.NotFound.path);
          }
        });
    }
  }, [thisPropertyID, authToken]);

  const [activeKey, setActiveKey] = useState('listings');

  useEffect(() => {
    if ( thisPropertyData.title ) document.title = thisPropertyData.title+" | Property | REC-X";
  }, [thisPropertyData]);

  const calEventSelected = (e) => {
    props.history.push("/dashboard/booking/"+e.event.id);
    window.location.reload();
  }

  const [apiResponseMessage, setApiResponseMessage] = useState(false);
  const updateProperty = (formData) => {

    if ( Object.keys(formData).length > 0 ) {

      // Merge updated formData into thisPropertyData
      let updatedData = {};
      _.assign(updatedData, thisPropertyData, formData);
      setThisPropertyData(updatedData);

      PropertyService.updateProperty(thisPropertyID.id, formData, authToken)
        .then((res) => {
          delete formData.gallery; // No window.alert message for Gallery Images
          if ( Object.keys(formData).length ) setApiResponseMessage({status: res.status, message: res.data.message, updatedFields: Object.keys(formData)});
        },
        err => {
          setApiResponseMessage({status: err.status, message: 'Property could not be updated.'});
        });
    }

  }


  const updatePropertyStatus = (boolean) => {
    if ( window.confirm(boolean?'Publish this property?':'Deactivate this property?') ) {
      PropertyService.updateProperty(thisPropertyID.id, {active: boolean}, authToken)
        .then((res) => {
          if ( res.status === 200 ) {
            setApiResponseMessage({status: res.status, message: res.data.message, updatedFields: [boolean?'Property Activated':'Property Deactivated']});
            setThisPropertyData({...thisPropertyData, 'active': boolean});
            document.activeElement.blur();
          }
        },
        err => {
          setApiResponseMessage({status: err.status, message: 'Property could not be updated.'});
        });
    }
  }


  const removeProperty = (id) => {
    if ( window.confirm("Remove property "+thisPropertyData.title+"?") ) {
      PropertyService.removeProperty(id, authToken)
        .then(res => {
          if ( currentUserData._id === thisPropertyData.host || currentUserData.roles.includes('ROLE_HOST') ) {
            props.history.push("/dashboard");
          } else {
            props.history.push("/dashboard/user/"+thisPropertyData.host);
          }
          alert("Property " + thisPropertyData.title + " was removed. All Listings and Bookings associated with this Property have been removed.");
        })
        .catch(err => {
          alert(err.response.data.message ? err.response.data.message : "Property " + thisPropertyData.title + " was not removed.");
        });
    }
  }


  const [addListingModalOpen, setAddListingModalOpen] = useState(false);

  const [isMapSelected, setIsMapSelected] = useState(false);
  useEffect(() => {
    activeKey === 'map'  ?  setIsMapSelected(true) : setIsMapSelected(false);
  }, [activeKey]);


  if ( queryStatusProperty && queryStatusProperty !== 204 ) { // Check Property ID Exists

    return (
      <>
        <Row className="justify-content-center mb-4">
          <Col>
            <Card>
              <Card.Img variant="top" src={thisPropertyData.hasOwnProperty('gallery') && thisPropertyData.gallery.length ? thisPropertyData.gallery[0] : '/images/smith-river-mt-paradise-bend.jpg'} className="h-15vw" />
              <Card.Body>

                <Row>
                  <Col className="d-md-flex justify-content-between align-items-center">
                    <h5>
                      <FontAwesomeIcon icon={faMapMarkedAlt} className="me-2" />
                      Property Details: <span className="text-secondary">{thisPropertyData.title}</span>
                    </h5>

                    <a href="/dashboard" className="btn btn-outline-secondary px-3">
                      <FontAwesomeIcon icon={faArrowAltCircleUp} className="me-2" />
                      Dashboard
                    </a>
                  </Col>
                </Row>

                <Row>
                  <Col className="py-4">
                    <Tab.Container
                      activeKey={activeKey}
                      onSelect={(e) => setActiveKey(e)}
                      id="property-tabs"
                      className="my-3"
                    >

                      <Row>
                        <Col>
                          <Nav variant="tabs" fill>
                            { upcomingBookingsList.length > 0 &&
                              <Nav.Item>
                                <Nav.Link eventKey="bookings">Upcoming Bookings</Nav.Link>
                              </Nav.Item>
                            }
                            <Nav.Item>
                              <Nav.Link eventKey="listings">Listings</Nav.Link>
                            </Nav.Item>
                            { bookingsList.length > 0 &&
                              <Nav.Item>
                                <Nav.Link eventKey="calendar">Calendar</Nav.Link>
                              </Nav.Item>
                            }
                            <Nav.Item>
                              <Nav.Link eventKey="photos">Photos</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="map">Map</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="property-details">Property Details</Nav.Link>
                            </Nav.Item>
                            { listingsList.some(o => o['active'])
                              ?
                                <Nav.Item>
                                  <Nav.Link onClick={(e) => { window.open(`/property/${thisPropertyData._id}`, '_blank') || window.location.replace(`/listing/${thisPropertyData._id}`) }} className="btn-outline-secondary">View Live</Nav.Link>
                                </Nav.Item>
                              :
                                <Nav.Item>
                                  <Nav.Link onClick={() => setAddListingModalOpen(true)} className="btn-outline-secondary"><FontAwesomeIcon icon={faPlusCircle} className="me-2" /> New Listing</Nav.Link>
                                </Nav.Item>
                            }
                          </Nav>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="pt-5">
                          <Tab.Content>

                            { upcomingBookingsList.length > 0 &&
                              <Tab.Pane eventKey="bookings">

                                <h2 className="fs-4 text-dark text-decoration-underline mb-4">Upcoming Bookings</h2>

                                <Row className="row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3">
                                  {
                                    upcomingBookingsList.map( (booking) => {
                                      return (
                                        <Col key={booking._id}>
                                          <Card as="a" href={`/dashboard/booking/${booking._id}`} className="h-100">
                                            <Badge bg={['pending', 'cancelled', 'declined'].includes(booking.status)?'warning':'success'} className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">{booking.status}</Badge>
                                            <Card.Img variant="top" src={booking.listingData.gallery?booking.listingData.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                                            <Card.Body>
                                              <Card.Title>{booking.listingData.title}</Card.Title>
                                              <Card.Text>{booking.guestData.firstName} {booking.guestData.lastName} on {moment(booking.start).format('MM/DD/YYYY')}</Card.Text>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      )
                                    })
                                  }
                                </Row>

                              </Tab.Pane>
                            }

                            <Tab.Pane eventKey="listings">

                              <h2 className="fs-4 text-dark text-decoration-underline mb-4">Listings</h2>

                              {listingsList &&
                                <Row className="row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 g-3">
                                  {
                                    listingsList.map( (listing) => {

                                      let listingPrimaryImage = 
                                        ( listing.hasOwnProperty('propertyData') && listing.propertyData.hasOwnProperty('gallery') && listing.propertyData.gallery.length )
                                          ? listing.propertyData.gallery[0]
                                          : ( listing.hasOwnProperty('gallery') && listing.gallery.length )
                                            ? listing.gallery[0]
                                            : `https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`

                                      return (
                                        <Col key={listing._id}>
                                          <Card as="a" href={`/dashboard/listing/${listing._id}`} className="h-100 position-relative">
                                            {!listing.active &&
                                              <Badge bg="warning" className="text-uppercase position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">Inactive</Badge>
                                            }
                                            <Card.Img variant="top" src={listingPrimaryImage} />
                                            <Card.Body>
                                              <Card.Title>{listing.title}</Card.Title>
                                            </Card.Body>
                                          </Card>
                                        </Col>
                                      )
                                    })
                                  }

                                  <Col>
                                    <Card 
                                      role="button" 
                                      onClick={() => setAddListingModalOpen(true)} 
                                      className="h-100 align-items-center justify-content-center py-5"
                                    >
                                      <FontAwesomeIcon icon={faPlusCircle} className="fa-2x text-secondary" />
                                      <h3 className="fs-5 mt-3">Add New Listing</h3>
                                    </Card>
                                  </Col>
                                </Row>
                              }

                              <Modal show={addListingModalOpen} onHide={() => setAddListingModalOpen(false)} size="fullscreen">
                                <Modal.Header closeButton>
                                  <Modal.Title>
                                    <FontAwesomeIcon icon={faRunning} className="me-2" /> New Listing
                                  </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  <Map mapMode="addListing" thisPropertyData={thisPropertyData} />
                                </Modal.Body>
                              </Modal>

                            </Tab.Pane>

                            { bookingsList.length > 0 &&
                              <Tab.Pane eventKey="calendar">
                                <BookingsCalendar queryStatus={queryStatusProperty} bookingsList={bookingsList} calEventSelected={calEventSelected} />
                              </Tab.Pane>
                            }

                            <Tab.Pane eventKey="photos">
                              <ManageImageGallery data={thisPropertyData} updateData={updateProperty} />
                            </Tab.Pane>

                            <Tab.Pane eventKey="map">
                              { Object.keys(thisPropertyData).length > 0 &&
                                <PropertyMap thisPropertyData={thisPropertyData} isMapSelected={isMapSelected}/>
                              }
                            </Tab.Pane>

                            <Tab.Pane eventKey="property-details">
                              <h2 className="fs-4 text-dark text-decoration-underline mb-4">Property Details</h2>
                              <ManageProperty queryStatus={queryStatusProperty} updateProperty={updateProperty} apiResponseMessage={apiResponseMessage} setApiResponseMessage={setApiResponseMessage} updatePropertyStatus={updatePropertyStatus} removeProperty={removeProperty} thisPropertyData={thisPropertyData} />
                            </Tab.Pane>

                          </Tab.Content>
                        </Col>
                      </Row>

                    </Tab.Container>
                  </Col>
                </Row>

              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );

  } else if ( queryStatusProperty && queryStatusProperty === 204 ) {
    return (
      <>
        <Row className="justify-content-center">
          <Col xl={4}>
            <Card>
              <Card.Body className="text-center py-5">
                <h4>Property Not Found</h4>
                <h5 className="text-danger">{thisPropertyID.id}</h5>
                <Button variant="outline-primary" href={Routes.DashboardOverview.path} className="mt-3">
                  <FontAwesomeIcon icon={faChartPie} className="me-2" />
                  Return to your Dashboard
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    );
  } else {
    return (
      <div className="mt-5" style={{height: '40vh'}}>
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }

};
export default PropertyDetail;
