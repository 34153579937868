import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import Datetime from "react-datetime";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";

import { Col, Row, Form, Button, InputGroup, ListGroup, Alert } from "react-bootstrap";

import {formDataFormatter} from "../data/formDataFormatter";

import Preloader from "../Preloader";


export const ManageListing = (props) => {
  const [formData, setFormData] = useState({});

  function setFormValues(e) {
    formData[e.target.name] = formDataFormatter(e);
    setFormData(formData);
  }


  const [beginDate, setBeginDate] = useState(0);
  useEffect(() => {
    setBeginDate(props.listingData.startAvailable);
  }, [props.listingData.startAvailable]);
  const changeBeginDate = (e) => {
    setBeginDate(e.toDate());
    formData['startAvailable'] = e.toDate();
    setFormData(formData);
  };

  const [endDate, setEndDate] = useState(0);
  useEffect(() => {
    setEndDate(props.listingData.endAvailable);
  }, [props.listingData.endAvailable]);
  const changeEndDate = (e) => {
    setEndDate(e.toDate());
    formData['endAvailable'] = e.toDate();
    setFormData(formData);
  };

  var isValidEndDate = function(current) {
    // check date ISN'T BEFORE START DATE
    if ( beginDate || props.listingData.startAvailable ) {
      let startAvailable = beginDate?moment(beginDate):moment(props.listingData.startAvailable);
      if ( current < startAvailable ) return false;
    }
    return true;
  };


  const weekdaysList = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  const [weekdaysAvailable, setWeekdaysAvailable] = useState([]);
  useEffect(() => {
    setWeekdaysAvailable(props.listingData.weekdaysAvailable);
  }, [props.listingData.weekdaysAvailable]);

  function setWeekdaysAvailableValue(e) {
    const checkedState = e.target.checked;
    // store state into local
    let currentWeekdaysAvailable = weekdaysAvailable?weekdaysAvailable:[];

    if ( !currentWeekdaysAvailable.includes(e.target.value) && checkedState ) {
      currentWeekdaysAvailable = _.concat(currentWeekdaysAvailable, e.target.value);
    } else {
      currentWeekdaysAvailable = currentWeekdaysAvailable.filter(day => day !== e.target.value);
    }

    setWeekdaysAvailable(currentWeekdaysAvailable);
    formData['weekdaysAvailable'] = currentWeekdaysAvailable;
    setFormData(formData);
  };



  const [checkInTimeForm, setCheckInTimeForm] = useState("");
  useEffect(() => {
    let isMounted = true;
    if (isMounted) setCheckInTimeForm(props.listingData.check_in_time);
    return () => { isMounted = false };
  }, [props.listingData.check_in_time]);
  const changeCheckInTime = (e) => {
    setCheckInTimeForm(e);
  };

  const [checkOutTimeForm, setCheckOutTimeForm] = useState("");
  useEffect(() => {
    let isMounted = true;
    if (isMounted) setCheckOutTimeForm(props.listingData.check_out_time);
    return () => { isMounted = false };
  }, [props.listingData.check_out_time]);
  const changeCheckOutTime = (e) => {
    setCheckOutTimeForm(e);
  };


  const [autoApproval, setAutoApproval] = useState(0);
  useEffect(() => {
    setAutoApproval(props.listingData.auto_approval);
  }, [props.listingData.auto_approval]);

  function setAutoApprovalValue(e) {
    setFormValues(e);
    setAutoApproval(e.target.auto_approval);
  }


  const [state, setState] = useState(0);
  useEffect(() => {
    setState(props.listingData.address && props.listingData.address.state);
  }, [props.listingData.address]);

  function setStateValue(e) {
    setFormValues(e);
    setState(e.target.value);
  }


  const [listingTypeOptionsSelected, setListingTypeOptionsSelected] = useState([]);
  useEffect(() => {
    setListingTypeOptionsSelected(props.listingData.listingType);
  }, [props.listingData.listingType]);


  function listingTypeCheckboxChange(e, refreshOnly = false) {
    const checkedState = e.target.checked;
    // store state into local
    let currentListingTypeOptionsSelected = listingTypeOptionsSelected?listingTypeOptionsSelected:[];

    if ( !refreshOnly && currentListingTypeOptionsSelected && !currentListingTypeOptionsSelected.includes(e.target.value) && checkedState ) {
      currentListingTypeOptionsSelected = _.concat(currentListingTypeOptionsSelected, e.target.value);
    } else {
      currentListingTypeOptionsSelected = currentListingTypeOptionsSelected.filter(type => type !== e.target.value);
    }

    setListingTypeOptionsSelected(currentListingTypeOptionsSelected);
    formData['listingType'] = currentListingTypeOptionsSelected;
    setFormData(formData);
  };


  const updateListingData = (e) => {
    // call parent update listing with form data
    props.updateListing(formData);
    // clear submitted data for next form submit
    setFormData({});
  }


  const listingTypeOptions = (props) => {
    return (
      <>
        <Row>
          <Col className="mb-1">
            <h5>Listing Categories & Searchable Terms</h5>
          </Col>
        </Row>

        <Row>
          <Col className="mb-3">
            <Form.Group>
              <ListGroup horizontal className="flex-wrap">
                {
                  Object.keys(props.listingTypesDefinitions).map(function (l, i) {
                    let thisListingType = props.listingTypesDefinitions[l];
                    return (
                      <ListGroup.Item key={i}>
                        <Form.Check
                          name="listingType"
                          type="switch"
                          id={thisListingType.id}
                          label={thisListingType.type.title}
                          value={thisListingType.id}
                          checked={ listingTypeOptionsSelected && listingTypeOptionsSelected.includes(l) }
                          onChange={listingTypeCheckboxChange}
                        />
                      </ListGroup.Item>
                    );
                  })
                }
              </ListGroup>
            </Form.Group>
          </Col>
        </Row>
      </>
    );
  }


  const [listingTypeDataObj, setListingTypeDataObj] = useState({});
  useEffect(() => {
    if ( props.listingData.listingTypeData ) {
      setListingTypeDataObj(props.listingData.listingTypeData);
    }
  }, [props.listingData.listingTypeData]);

  const updateListingTypeFormField = (e, field, listingTypeId) => {

    const checkedState = e.target.checked;
    // store state into local
    let currentListingTypeDataObjSelected = listingTypeDataObj;

/*
    // without listener, input loses focus upon each keystroke
    if ( ['string', 'number'].includes(field.type) ) {
      const updatedField = document.getElementById('listingTypeFieldInput-'+listingTypeId+'-'+fieldId);
      updatedField.addEventListener('focusout', (e) => {
        if ( !currentListingTypeDataObjSelected.hasOwnProperty(listingTypeId) ) currentListingTypeDataObjSelected[listingTypeId] = {};
        currentListingTypeDataObjSelected[listingTypeId][fieldId] = e.target.value;
      });
    }
*/
    if ( field.type === 'checkbox' ) {
      // Create array if not set up yet for checkbox group
      if ( !currentListingTypeDataObjSelected.hasOwnProperty(listingTypeId)  ) currentListingTypeDataObjSelected[listingTypeId] = {};
      if ( !currentListingTypeDataObjSelected[listingTypeId].hasOwnProperty(e.target.name) || !currentListingTypeDataObjSelected[listingTypeId][e.target.name].length ) {
        currentListingTypeDataObjSelected[listingTypeId][e.target.name] = [];
      }
      if ( !currentListingTypeDataObjSelected[listingTypeId][e.target.name].includes(e.target.value) && checkedState ) {
        // Add value to array for checkbox group
        currentListingTypeDataObjSelected[listingTypeId][e.target.name].push(e.target.value);
      } else {
        // Remove value from array for checkbox group
        currentListingTypeDataObjSelected[listingTypeId][e.target.name] = currentListingTypeDataObjSelected[listingTypeId][e.target.name].filter(el => el !== e.target.value);
      }

    } else if ( currentListingTypeDataObjSelected && !currentListingTypeDataObjSelected.hasOwnProperty(e.target.value) && checkedState ) {
      // Add item+value to object
      currentListingTypeDataObjSelected = _.merge(currentListingTypeDataObjSelected, {[listingTypeId]: {[e.target.name]: e.target.value}});
    } else {
      // Remove item+value from object
      delete currentListingTypeDataObjSelected[listingTypeId][e.target.name];
    }

    setListingTypeDataObj(currentListingTypeDataObjSelected);
    formData['listingTypeData'] = currentListingTypeDataObjSelected;
    setFormData(formData);

    // Nudge data to force live refresh
    listingTypeCheckboxChange(e, true);

  }

  const updateListingTypeSpecifcationFormField = (e, field, listingTypeId, fieldId) => {
    const checkedState = e.target.checked;
    // store state into local
    let currentListingTypeDataObjSelected = listingTypeDataObj;

    // Create array if not set up yet for checkbox group
    if ( !currentListingTypeDataObjSelected.hasOwnProperty(listingTypeId) ) currentListingTypeDataObjSelected[listingTypeId] = {};
    if ( !currentListingTypeDataObjSelected[listingTypeId].hasOwnProperty(fieldId+'_specifications') || !currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'].length ) {
      currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'] = [];
    }
    if ( !currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'].includes(e.target.value) && checkedState ) {
      // Add value to array for checkbox group
      currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'].push(e.target.value);
    } else {
      // Remove value from array for checkbox group
      currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'] = currentListingTypeDataObjSelected[listingTypeId][fieldId+'_specifications'].filter(el => el !== e.target.value);
    }

    setListingTypeDataObj(currentListingTypeDataObjSelected);
    formData['listingTypeData'] = currentListingTypeDataObjSelected;
    setFormData(formData);
    // Nudge data to force live refresh
    listingTypeCheckboxChange(e, true);
  }

  const formFields = (field, fieldId, listingTypeId) => {

    switch ( field.type ) {

      default:
        return null;

      case 'boolean':
        return (
          <>
            <Row>
              <Col className="mb-3">
                <Form.Group className={'listingType-field-'+fieldId}>
                  <Form.Check
                    type="switch" 
                    label={field.title} 
                    name={fieldId}
                    value="true" 
                    id={'listingTypeFieldInput-'+listingTypeId+'-'+fieldId} 
                    checked={(listingTypeDataObj[listingTypeId] && listingTypeDataObj[listingTypeId].hasOwnProperty(fieldId) && listingTypeDataObj[listingTypeId][fieldId] === "true")?true:null} 
                    onChange={(e) => { updateListingTypeFormField(e, field, listingTypeId) }}
                  >
                  </Form.Check>
                </Form.Group>
              </Col>
            </Row>

            {
            field.hasOwnProperty('specifications') && 
            listingTypeDataObj && listingTypeDataObj.hasOwnProperty(listingTypeId) && 
            listingTypeDataObj[listingTypeId].hasOwnProperty(fieldId) && 
            listingTypeDataObj[listingTypeId][fieldId] === 'true' &&
              <Row>
                <Col className="mb-3">
                  <Form.Group className={'listingType-field-'+fieldId+'-specifications'}>
                    <Form.Label className="d-block mb-1">{field.title} Specifications:</Form.Label>
                    {
                      Object.keys(field.specifications).map(function (specification, i) {
                        return (
                          <Form.Check
                            inline
                            className="text-capitalize"
                            key={fieldId+'_'+specification}
                            name={fieldId+'_'+specification}
                            type="checkbox"
                            id={fieldId+'_'+specification}
                            label={field.specifications[specification]}
                            value={specification}
                            checked={ (listingTypeDataObj[listingTypeId] && listingTypeDataObj[listingTypeId][fieldId] && listingTypeDataObj[listingTypeId][fieldId+'_specifications'] && listingTypeDataObj[listingTypeId][fieldId+'_specifications'].includes(specification)) ? true : null }
                            onChange={(e) => { updateListingTypeSpecifcationFormField(e, field, listingTypeId, fieldId) }}
                          />
                        );
                      })
                    }
                  </Form.Group>
                </Col>
              </Row>
            }

          </>
        );


      case 'checkbox':
        return (
          <Row>
            <Col className="mb-3">
              <Form.Group className={'listingType-field-'+fieldId}>
                <Form.Label className="d-block mb-1">{field.title}:</Form.Label>
                {
                  Object.keys(field.specifications).map(function (specification, i) {
                    return (
                      <Form.Check
                        inline
                        className="text-capitalize"
                        key={fieldId+'_'+specification}
                        name={fieldId}
                        type="checkbox"
                        id={fieldId+'_'+specification}
                        label={field.specifications[specification]}
                        value={specification}
                        checked={listingTypeDataObj[listingTypeId] && listingTypeDataObj[listingTypeId][fieldId] && listingTypeDataObj[listingTypeId][fieldId].includes(specification)}
                        onChange={(e) => { updateListingTypeFormField(e, field, listingTypeId) }}
                      />
                    );
                  })
                }
              </Form.Group>
            </Col>
          </Row>
        );
/*
      case 'string':
      default:
        return (
          <Row>
            <Col className="mb-3">
              <Form.Group className={'listingType-field-'+fieldId}>
                <Form.Label>{field.title}:</Form.Label>
                <Form.Control 
                  as="textarea" 
                  rows={3} 
                  type="text" 
                  id={'listingTypeFieldInput-'+listingTypeId+'-'+fieldId} 
                  defaultValue={listingTypeDataObj[listingTypeId] && listingTypeDataObj[listingTypeId][fieldId]} 
                  onChange={(e) => { updateListingTypeFormField(e, field, listingTypeId, fieldId) }} 
                />
              </Form.Group>
            </Col>
          </Row>
        );

      case 'number':
        return (
          <Row>
            <Col className="mb-3">
              <Form.Group className={'listingType-field-'+fieldId}>
                <Form.Label>{field.title}:</Form.Label>
                <Form.Control 
                  type="number" 
                  id={'listingTypeFieldInput-'+listingTypeId+'-'+fieldId} 
                  defaultValue={listingTypeDataObj[listingTypeId] && listingTypeDataObj[listingTypeId][fieldId]} 
                  onChange={(e) => { updateListingTypeFormField(e, field, listingTypeId, fieldId) }} 
                />
              </Form.Group>
            </Col>
          </Row>
        );
*/
    }

  }


  const ListingTypes = (props) => {
    const listingTypeObj = props.listingTypesDefinitions;

    if ( listingTypeOptionsSelected && Object.keys(listingTypeOptionsSelected).length > 0 ) {

      return (
        <>
          {

            Object.values(listingTypeOptionsSelected).map(function(fieldId) {
              return (

                <div key={'listingType-'+fieldId}>

                  { listingTypeObj[fieldId] &&
                    <>
                      <Row>
                        <Col className="mb-1">
                          <h5>{listingTypeObj[fieldId].type.title} Details</h5>
                          {listingTypeObj[fieldId].type.description &&
                            <div className="mb-3 mx-2 text-italic">
                              {listingTypeObj[fieldId].type.description}
                            </div>
                          }
                        </Col>
                      </Row>

                      <div id={'listingType-group-'+listingTypeObj[fieldId].id}>
                          {
                            Object.keys(listingTypeObj[fieldId].fields).map(function (field) {
                              return (
                                <>
                                  {formFields(listingTypeObj[fieldId].fields[field], field, fieldId)}
                                </>
                              );
                            })
                          }
                      </div>
                    </>
                  }

                </div>
              );
            })
          }
        </>
      );

    } else {
      return (<></>);
    }

  }

  return (
    <>
      <Row>
        <Col>
          {(props.queryStatus !== 200)
            ?
              <Preloader message="Loading..." logoSize={50} show={true} />
            :
              <Form id="updateListing" onSubmit={ (e) => updateListingData(e.preventDefault()) }>

                <Row>
                  <Col md={12} lg={6}>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="title">
                          <Form.Label>Listing Title</Form.Label>
                          <Form.Control name="title" required type="text" defaultValue={props.listingData.title} placeholder="Listing Title" onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="text-center">
                        <h5>Dates of Availability:</h5>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="startDate">
                          <Form.Label>Start Date</Form.Label>
                          <Datetime
                            dateFormat="MM/DD/YYYY"
                            timeFormat={false}
                            onChange={changeBeginDate}
                            closeOnSelect={true}

                            renderInput={(props, openCalendar, closeCalendar) => (
                              <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  defaultValue={ ( beginDate ) ? moment(beginDate).format("MM/DD/YYYY") : "" }
                                  placeholder="MM/DD/YYYY"
                                  onFocus={openCalendar}
                                  onChange={(e) => { setBeginDate(e.target.value) }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="endDate">
                          <Form.Label>End Date</Form.Label>
                          <Datetime
                            dateFormat="MM/DD/YYYY"
                            timeFormat={false}
                            onChange={changeEndDate}
                            closeOnSelect={true}
                            isValidDate={isValidEndDate}

                            renderInput={(props, openCalendar, closeCalendar) => (
                              <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  defaultValue={ ( endDate ) ? moment(endDate).format("MM/DD/YYYY") : "" }
                                  placeholder="MM/DD/YYYY"
                                  onFocus={openCalendar}
                                  onChange={(e) => { setEndDate(e.target.value) }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <label className="d-block text-center mb-1">Weekdays Available:</label>
                        <Form.Group className="weekdaysAvailable d-flex flex-wrap">
                          {
                            weekdaysList.map(function (day, i) {
                              return (
                                <Form.Check
                                  inline
                                  className="text-capitalize"
                                  key={day}
                                  name="weekdaysAvailable"
                                  type="checkbox"
                                  id={day}
                                  label={day}
                                  value={day}
                                  checked={weekdaysAvailable && weekdaysAvailable.includes(day)}
                                  onChange={setWeekdaysAvailableValue}
                                />
                              );
                            })
                          }
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control name="description" as="textarea" rows={5} type="text" placeholder="Description" defaultValue={props.listingData.description} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col md={12} lg={6}>
                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="price">
                          <Form.Label>Price (daily rate)</Form.Label>
                          <Form.Control name="base_rate" type="number" step="0.01" min="0" placeholder="Price" defaultValue={props.listingData.base_rate && props.listingData.base_rate} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="maxGuests">
                          <Form.Label>Guests Limit</Form.Label>
                          <Form.Control name="max_guests" type="number" step="1" min="0" defaultValue={props.listingData.max_guests !== 0 ? props.listingData.max_guests:null} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="minDays">
                          <Form.Label>Minimum Days</Form.Label>
                          <Form.Control name="min_days" type="number" step="1" min="0" defaultValue={props.listingData.min_days} placeholder="1" onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="maxDays">
                          <Form.Label>Maximum Days</Form.Label>
                          <Form.Control name="max_days" type="number" step="1" min="0" defaultValue={props.listingData.max_days} placeholder="1" onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="check-in-time">
                          <Form.Label>Check-In Time</Form.Label>
                          <Datetime
                            dateFormat={false}
                            timeFormat={true}
                            onChange={changeCheckInTime}
                            closeOnSelect={true}

                            renderInput={(props, openCalendar, closeCalendar) => (
                              <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  value={ checkInTimeForm ? moment(checkInTimeForm).format("hh:mm A") : "" }
                                  onFocus={openCalendar}
                                  onChange={(e) => { props.setCheckInTime(e.target.value) }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="check-out-time">
                          <Form.Label>Check-Out Time</Form.Label>
                          <Datetime
                            dateFormat={false}
                            timeFormat={true}
                            onChange={changeCheckOutTime}
                            closeOnSelect={true}

                            renderInput={(props, openCalendar, closeCalendar) => (
                              <InputGroup>
                                <InputGroup.Text><FontAwesomeIcon icon={faCalendarAlt} /></InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  value={ checkOutTimeForm ? moment(checkOutTimeForm).format("hh:mm A") : "" }
                                  onFocus={openCalendar}
                                  onChange={(e) => { props.setCheckOutTime(e.target.value) }}
                                />
                              </InputGroup>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={6} className="mb-3">
                        <Form.Group id="booking-lead-time" onChange={setFormValues} onBlur={setFormValues}>
                          <Form.Label>Booking Lead Time (days)</Form.Label>
                          <Form.Control name="booking_lead_time" type="number" step="1" min="0" defaultValue={props.listingData.booking_lead_time} />
                        </Form.Group>
                      </Col>
                      <Col sm={6} className="mb-3">
                        <Form.Group>
                          <Form.Label>Auto Approval</Form.Label>
                          <Form.Select name="auto_approval" id="auto-approval" value={autoApproval} onChange={setAutoApprovalValue}>
                            <option value={true}>Auto</option>
                            <option value={false}>Manual</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>
                </Row>

                <hr />

                { listingTypeOptions(props) }

                <ListingTypes {...props} />

                <hr />

                <Row>
                  <Col>
                    <h5>Additional Details</h5>
                  </Col>
                </Row>

                <Row>
                  <Col md={12} lg={6}>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="rules">
                          <Form.Label>Rules</Form.Label>
                          <Form.Control name="rules" as="textarea" rows={3} type="text" placeholder="Rules" defaultValue={props.listingData.rules} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="check-in-protocol">
                          <Form.Label>Check-In Protocol</Form.Label>
                          <Form.Control name="check_in_protocol" as="textarea" rows={3} type="text" placeholder="Check-In Protocol" defaultValue={props.listingData.check_in_protocol} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="check-out-protocol">
                          <Form.Label>Check-Out Protocol</Form.Label>
                          <Form.Control name="check_out_protocol" as="textarea" rows={3} type="text" placeholder="Check-Out Protocol" defaultValue={props.listingData.check_out_protocol} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="cancellation-policy">
                          <Form.Label>Cancellation Policy</Form.Label>
                          <Form.Control name="cancellation_policy" as="textarea" rows={3} type="text" placeholder="Cancellation Policy" defaultValue={props.listingData.cancellation_policy} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="regulations-requirements-waivers">
                          <Form.Label>Regulations, Requirements, & Waivers</Form.Label>
                          <Form.Control name="rules_regulations_waivers" as="textarea" rows={3} type="text" placeholder="Regulations, Requirements, & Waivers" defaultValue={props.listingData.rules_regulations_waivers} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col md={12} lg={6}>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="welcome-message">
                          <Form.Label>Welcome Email</Form.Label>
                          <Form.Control name="welcome_message" as="textarea" rows={3} type="text" placeholder="Welcome Email" defaultValue={props.listingData.welcome_message} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="thankyou-message">
                          <Form.Label>Thank You Email</Form.Label>
                          <Form.Control name="thankyou_message" as="textarea" rows={3} type="text" placeholder="Thank You Email" defaultValue={props.listingData.thankyou_message} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="driving-directions">
                          <Form.Label>Driving Directions</Form.Label>
                          <Form.Control name="driving_directions" as="textarea" rows={3} type="text" placeholder="Driving Directions" defaultValue={props.listingData.driving_directions} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={12} className="mb-3">
                        <Form.Group id="address">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control name="street_address" type="text" placeholder="Street address" defaultValue={props.listingData.address && props.listingData.address.street_address} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4} className="mb-3">
                        <Form.Group id="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control name="city" type="text" placeholder="City" defaultValue={props.listingData.address && props.listingData.address.city} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                      <Col sm={4} className="mb-3">
                        <Form.Group className="mb-2">
                          <Form.Label>State</Form.Label>
                          <Form.Select name="state" id="state" value={state} onChange={setStateValue} onBlur={setStateValue}>
                            <option value="0">State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group id="zip">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control name="zipcode" type="tel" placeholder="Zip Code" defaultValue={props.listingData.address && props.listingData.address.zipcode} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="county">
                          <Form.Label>County</Form.Label>
                          <Form.Control name="county" type="text" placeholder="County" defaultValue={props.listingData.address && props.listingData.address.county} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>

                      <Col md={6} className="mb-3">
                        <Form.Group id="nearest_city">
                          <Form.Label>Nearest Major City</Form.Label>
                          <Form.Control name="nearest_city" type="text" placeholder="Major City" defaultValue={props.listingData.address && props.listingData.address.nearest_city} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>
                </Row>

                <div className="fixed-bottom bg-white border-top border-2 py-2 mt-3">
                  { props.apiResponseMessage && 
                    props.apiResponseMessage.updatedFields.length > 0 &&
                    <Row className="justify-content-center">
                      <Col xs={11} md={10} className="d-flex justify-content-center text-center">
                        <Alert 
                          variant={props.apiResponseMessage.status === 200 ? 'secondary' : 'warning'}
                          onClose={() => props.setApiResponseMessage(false)}
                          dismissible
                          className="w-100"
                        >
                          Fields updated: {props.apiResponseMessage.updatedFields.map(String).join(', ')}
                        </Alert>
                      </Col>
                    </Row>
                  }

                  <Row className="justify-content-center">
                    <Col lg={6} className="d-sm-flex justify-content-evenly text-center">

                      <Button variant="success" type="submit" className="d-block d-sm-inline-block mx-auto mx-sm-1 my-2 my-sm-0 px-4">Update</Button>
                      <Button variant="secondary" className="d-block d-sm-inline-block mx-auto mx-sm-1 my-2 my-sm-0 px-4 text-white" onClick={() => props.duplicateListing()}>Duplicate Listing</Button>

                      { props.listingData.active
                        ?
                          <Button variant="warning" className="px-4" onClick={() => props.updateListingStatus(false)}>Deactivate</Button>
                        :
                          <Button variant="danger" className="d-block d-sm-inline-block mx-auto mx-sm-1 my-2 my-sm-0 px-4" onClick={() => props.removeListing(props.listingData._id)}>Delete</Button>
                      }

                    </Col>
                  </Row>
                </div>

              </Form>
          }
        </Col>
      </Row>
    </>
  );
}