import React from "react";
import { Link } from 'react-router-dom';
import { Routes } from "../routes";
import Cookies from "js-cookie";
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faUser, faSignInAlt, faChartPie, faSearch, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";

import RecXHero from "../assets/img/rec-x/deer-circle-white.png";

import { Row, Col, Nav, Card, Image, Navbar, Dropdown, Container } from 'react-bootstrap';


export const GlobalNavbar = (props) => {

  let currentUserData = Cookies.get('authToken');
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
  } else {
    currentUserData = false;
  }


  const dashboardModeToggle = () => {
    if ( window.location.pathname.includes('guest') ) {
      window.location = Routes.DashboardOverview.path;
    } else {
      window.location = Routes.GuestDashboard.path;
    }
  }


  return (
    <Row 
      id="global-nav-bar" 
      className={`pt-3 pb-4 variant-${props.variant} ${props.variant === 'public'?'bg-primary':''} ${props.variant === 'overlay'?'position-absolute top-0 w-100':''}`}
    >
      <Col>
        <Card className="m-0">
          <Card.Body className="py-2">
            <Navbar expanded className="p-0">
              <Container fluid className="px-0">
                <div className="d-flex justify-content-between align-items-center w-100">

                  <div className="d-flex align-items-center">
                    <h3 className="text-secondary mb-0">
                      <Card.Link as={Link} to={props.variant === 'dashboard' ? Routes.DashboardOverview.path : Routes.Homepage.path} title="REC-X" className="d-flex align-items-center justify-content-center">
                        <Image src={RecXHero} thumbnail roundedCircle width="75" className="me-4" alt="REC-X logo" />
                        REC-X
                      </Card.Link>
                    </h3>
                  </div>

                  <div className="d-flex flex-row align-items-center">

                    { currentUserData && 
                      !currentUserData.roles.includes('ROLE_ADMIN') &&
                      props.showDashboardModeToggle &&

                      <div id="dashboard-mode-wrapper" className="me-1 me-sm-5 text-center">
                        <Form.Check
                          checked={!window.location.pathname.includes('guest')}
                          type="switch"
                          id="dashboard-mode"
                          label={`Switch to ${window.location.pathname.includes('guest')?'Host':'Guest'} Mode`}
                          className="d-flex flex-column align-items-center"
                          onChange={dashboardModeToggle}
                        />
                      </div>

                    }

                    <UserNavbarDropdown variant={props.variant} currentUserData={currentUserData} />

                  </div>

                </div>
              </Container>
            </Navbar>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}


export const UserNavbarDropdown = (props) => {
  return (
    <Nav className={`align-items-center ${props.variant}`}>
      {props.currentUserData && Object.keys(props.currentUserData).length 
        ? 
        <Dropdown as={Nav.Item}>
          <Dropdown.Toggle as={Nav.Link} className="pt-1 px-0">
            <div className="media d-flex align-items-center">
              <FontAwesomeIcon icon={faUser} />
              <div className="media-body ms-2 align-items-center d-none d-lg-block">
                <span className="mb-0 font-small fw-bold">{props.currentUserData && props.currentUserData.username}</span>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu className="user-dropdown dropdown-menu-right mt-2">
            <Dropdown.Item className="fw-bold" as={Link} to="/search">
              <FontAwesomeIcon icon={faSearch} className="me-2" /> Search Listings
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to="/dashboard">
              <FontAwesomeIcon icon={faChartPie} className="me-2" /> My Dashboard
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to={"/dashboard/user/"+props.currentUserData.id}>
              <FontAwesomeIcon icon={faUserCircle} className="me-2" /> My Profile
            </Dropdown.Item>
            <Dropdown.Item className="fw-bold" as={Link} to="/dashboard/support">
              <FontAwesomeIcon icon={faQuestionCircle} className="me-2" /> Customer Support
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item className="fw-bold" onClick={() => {Cookies.set('authToken', null); window.location.replace(Routes.Homepage.path);}}>
              <FontAwesomeIcon icon={faSignOutAlt} className="text-danger me-2" /> Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        : 
        <Nav.Item>
          <Nav.Link as={Link} to={Routes.Signin.path} className="btn btn-outline-secondary d-flex align-items-center">
            <FontAwesomeIcon icon={faSignInAlt} />
            <span className="ms-2 mb-0 font-small fw-bold">Login</span>
          </Nav.Link>
        </Nav.Item>
      }
    </Nav>
  );
}
