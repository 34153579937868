import React from "react";

import { Col, Row, Form, Button } from "react-bootstrap";


export const AddListing = (props) => {


  return (
    <>
      <Row>
        <Col>
          <Form id="addListing" onSubmit={ (e) => props.addListing(e) }>

            <Row>
              <Col className="mb-3">
                <Form.Group id="property">
                  <Form.Label>Which Property is this Listing for?</Form.Label>
                  <Form.Select name="propertyID" id="propertyID" onChange={ (e) => props.setPropertyIDforNewListing(e.target.value) }>
                    {
                      props.propertiesList.map( (property) => {
                        return (
                          <option key={property._id} value={property._id}>{property.title}</option>
                        )
                      })
                    }
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <div className="mt-3 d-flex justify-content-evenly">
              <Button variant="success" type="submit" className="px-4">Create Listing</Button>
            </div>

          </Form>
        </Col>
      </Row>
    </>
  );
}
