import React, { useState, useEffect } from "react";

import { Col, Row, Form, Button, Alert } from "react-bootstrap";

import {formDataFormatter} from "../data/formDataFormatter";

import Preloader from "../Preloader";


let managePropertyFormData = {};


export const ManageProperty = (props) => {


  function setFormValues(e) {
    managePropertyFormData[e.target.name] = formDataFormatter(e);
    props.thisPropertyData[e.target.name] = formDataFormatter(e);
  }


  function setFormAddressValues(e) {
    managePropertyFormData.address = managePropertyFormData.address ? managePropertyFormData.address : props.thisPropertyData.address;
    managePropertyFormData.address[e.target.name] = formDataFormatter(e);
    props.thisPropertyData.address[e.target.name] = formDataFormatter(e);
  }


  const [state, setState] = useState(0);
  useEffect(() => {
    if ( Object.keys(props.thisPropertyData).length ) setState(props.thisPropertyData.address.state);
  }, [props.thisPropertyData, props.thisPropertyData.address]);

  function setStateValue(e) {
    managePropertyFormData.address = managePropertyFormData.address ? managePropertyFormData.address : props.thisPropertyData.address;
    managePropertyFormData.address[e.target.name] = formDataFormatter(e);
    props.thisPropertyData.address[e.target.name] = formDataFormatter(e);
    setState(e.target.value);
  }


  function formSubmit() {
    props.updateProperty(managePropertyFormData);
    managePropertyFormData = {};
  }

  return (
    <>
      <Row>
        <Col>
          {(props.queryStatus !== 200)
            ?
              <Preloader message="Loading..." logoSize={50} show={true} />
            :
              <Form id="updateProperty" onSubmit={ (e) => formSubmit(e.preventDefault()) }>

                <Row>
                  <Col md={12} lg={6}>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="title">
                          <Form.Label>Property Title</Form.Label>
                          <Form.Control name="title" required type="text" defaultValue={props.thisPropertyData && props.thisPropertyData.title} placeholder="Property Title" onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="description">
                          <Form.Label>Description</Form.Label>
                          <Form.Control name="description" as="textarea" rows={5} type="text" placeholder="Description" defaultValue={props.thisPropertyData && props.thisPropertyData.description} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col className="mb-3">
                        <Form.Group id="rules">
                          <Form.Label>Property Rules</Form.Label>
                          <Form.Control name="property_rules" as="textarea" rows={3} type="text" placeholder="Property Rules" defaultValue={props.thisPropertyData && props.thisPropertyData.property_rules} onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>

                  <Col md={12} lg={6}>

                    <Row>
                      <Col sm={12} className="mb-3">
                        <Form.Group id="address">
                          <Form.Label>Street Address</Form.Label>
                          <Form.Control name="street_address" type="text" placeholder="Street address" defaultValue={props.thisPropertyData && props.thisPropertyData.address && props.thisPropertyData.address.street_address} onChange={setFormAddressValues} onBlur={setFormAddressValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm={4} className="mb-3">
                        <Form.Group id="city">
                          <Form.Label>City</Form.Label>
                          <Form.Control name="city" type="text" placeholder="City" defaultValue={props.thisPropertyData && props.thisPropertyData.address && props.thisPropertyData.address.city} onChange={setFormAddressValues} onBlur={setFormAddressValues} />
                        </Form.Group>
                      </Col>
                      <Col sm={4} className="mb-3">
                        <Form.Group className="mb-2">
                          <Form.Label>State</Form.Label>
                          <Form.Select name="state" id="state" value={state} onChange={setStateValue} onBlur={setStateValue}>
                            <option value="0">Select State</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AZ">Arizona</option>
                            <option value="AR">Arkansas</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DE">Delaware</option>
                            <option value="DC">District Of Columbia</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col sm={4}>
                        <Form.Group id="zip">
                          <Form.Label>Zip Code</Form.Label>
                          <Form.Control name="zipcode" type="tel" placeholder="Zip Code" defaultValue={props.thisPropertyData && props.thisPropertyData.address && props.thisPropertyData.address.zipcode} onChange={setFormAddressValues} onBlur={setFormAddressValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="county">
                          <Form.Label>County</Form.Label>
                          <Form.Control name="county" type="text" placeholder="County" defaultValue={props.thisPropertyData && props.thisPropertyData.address && props.thisPropertyData.address.county} onChange={setFormAddressValues} onBlur={setFormAddressValues} />
                        </Form.Group>
                      </Col>
                      <Col md={6} className="mb-3">
                        <Form.Group id="nearest_city">
                          <Form.Label>Nearest Major City</Form.Label>
                          <Form.Control name="nearest_city" type="text" placeholder="Major City" defaultValue={props.thisPropertyData && props.thisPropertyData.address && props.thisPropertyData.address.nearest_city} onChange={setFormAddressValues} onBlur={setFormAddressValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} className="mb-3">
                        <Form.Group id="phone">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control name="phone" type="tel" defaultValue={props.thisPropertyData && props.thisPropertyData.phone} placeholder="(406) 555-1200" onChange={setFormValues} onBlur={setFormValues} />
                        </Form.Group>
                      </Col>
                    </Row>

                  </Col>
                </Row>

                <div className="fixed-bottom bg-white border-top border-2 py-2 mt-3">
                  { props.apiResponseMessage && 
                    props.apiResponseMessage.updatedFields.length > 0 &&
                    <Row className="justify-content-center">
                      <Col xs={11} md={10} className="d-flex justify-content-center text-center">
                        <Alert 
                          variant={props.apiResponseMessage.status === 200 ? 'secondary' : 'warning'}
                          onClose={() => props.setApiResponseMessage(false)}
                          dismissible
                          className="w-100"
                        >
                          Field(s) updated: {props.apiResponseMessage.updatedFields.map(String).join(', ')}
                        </Alert>
                      </Col>
                    </Row>
                  }

                  <Row className="justify-content-center">
                    <Col lg={6} className="d-sm-flex justify-content-evenly text-center">

                      <Button variant="success" type="submit" className="px-4">Update</Button>

                      { props.thisPropertyData.active
                        ?
                          <Button variant="warning" className="px-4" onClick={() => props.updatePropertyStatus(false)}>Deactivate</Button>
                        :
                          <Button variant="danger" className="px-4" onClick={() => props.removeProperty(props.thisPropertyData._id)}>Delete</Button>
                      }

                    </Col>
                  </Row>
                </div>

              </Form>
          }
        </Col>
      </Row>
    </>
  );
};
