import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.REACT_APP_API_URL_ROOT;
// const API_URL = "http://localhost:3001/v3";

class UserService {

  getUsersList(token) {
    return fetch(API_URL + '/users', { headers: authHeader(token) })
      .then(res => 
        res.json()
        .then(data => (
          {
            status: res.status,
            body: data
          }
        ))
        .catch(err => (
          {
            status: res.status
          }
        ))
      )
      .catch(err => err)
  }

  async getUser(token) {
    if (token) {
      return await fetch(API_URL + '/user', { headers: authHeader(token) })
        .then(res => 
          res.json()
          .then((data) => {
            return data;
          })
          .then(data => (
            {
              status: res.status,
              body: data
            }
          ))
          .catch(err => (
            {
              status: res.status
            }
          ))
        )
        .catch(err => err)
    }
  }

  async getUserFromId(id, token) {
    if (token) {
      return await fetch(API_URL + '/user/'+id, { headers: authHeader(token) })
        .then(res => 
          res.json()
          .then(data => (
            {
              status: res.status,
              body: data
            }
          ))
          .catch(err => (
            {
              status: res.status
            }
          ))
        )
        .catch(err => err)
    }
  }

  updateUser(id, data, token) {
    return axios.put(API_URL + '/user/'+id, data, { headers: authHeader(token) });
  }

  updateUserRole(id, data, token) {
    return axios.put(API_URL + '/user/'+id+'/role', data, { headers: authHeader(token) });
  }

  removeUser(id, token) { // ToDo : Check for errors on API
    return axios.delete(API_URL + '/user/'+id, { headers: authHeader(token) });
  }

}

export default new UserService();
