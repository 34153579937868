import React, { useState, useEffect } from "react";
import _ from "lodash";
import Cookies from "js-cookie";
import moment from "moment-timezone";

import UserService from "../../services/user.service";

import Preloader from "../../components/Preloader";

import { Col, Row, Card, Badge } from 'react-bootstrap';


const GuestDashboard = (props) => {
  document.title = "Guest Dashboard | REC-X";

  let currentUserData = Cookies.get('authToken');
  currentUserData = currentUserData ? JSON.parse(currentUserData).data : null;
  let authToken = currentUserData.accessToken;


  const [queryStatusUser, setQueryStatusUser] = useState(false);
  const [guestBookingsList, setGuestBookingsList] = useState([]);
  const [historicGuestBookingsList, setHistoricGuestBookingsList] = useState([]);
  useEffect(() => {
      if ( !queryStatusUser && currentUserData && currentUserData.roles.includes('ROLE_USER') ) {
        UserService.getUser(authToken)
          .then((res) => {
            setQueryStatusUser(res.status);
            if ( res.status === 200 ) {
              // exclude old bookings
              let sortedGuestBookings = _.filter(res.body.guest_bookings, function(b) { // Filter out past Bookings beyond ~2 days before today
                if ( b.start >= new Date(Date.now() - 1728e5).toISOString() ) return b;
              });
              // sort bookings
              sortedGuestBookings = _.orderBy(sortedGuestBookings, ['start', 'status'], ['asc', 'asc']);
              // set Guest Bookigns data
              setGuestBookingsList(sortedGuestBookings);

              // set historic bookings
              let sortedHistoricGuestBookings = _.filter(res.body.guest_bookings, function(b) { // Filter out past Bookings beyond ~2 days before today
                if ( b.start < new Date(Date.now() - 1728e5).toISOString() ) return b;
              });
              // sort bookings
              sortedHistoricGuestBookings = _.orderBy(sortedHistoricGuestBookings, ['start', 'status'], ['asc', 'asc']);
              // set Guest Bookigns data
              setHistoricGuestBookingsList(sortedHistoricGuestBookings);
            }
          })
          .catch((err) => console.log(err));
      }
  }, [currentUserData]);


  if ( queryStatusUser ) { // show Preloader while data loads
    return (
      <>
        <Row>
          <Col className="my-4">

            <h2 className="fs-4 text-dark text-decoration-underline ms-3 mb-4">My Upcoming Trips</h2>

            { guestBookingsList &&
              guestBookingsList.length > 0
              ?
                <Row className="row-cols-sm-1 row-cols-lg-3 row-cols-xl-4 g-3">
                  {
                    guestBookingsList.map( (booking) => {
                      return (
                        <Col key={booking._id}>
                          <Card as="a" href={`/dashboard/booking/${booking._id}`} className="h-100">
                            <Badge bg="success" className="fs-6 position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">{moment(booking.start).format('MM/DD/YYYY')}</Badge>
                            <Card.Img variant="top" src={booking.listingData.gallery[0]?booking.listingData.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} className="h-15vw" />
                            <Card.Body>
                              <Card.Title>{booking.listingData.title}</Card.Title>
                              <Card.Text>
                                Hosted by: <u>{booking.propertyData.title}</u>
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className="border-0 text-right p-2">
                              <Badge bg={['pending', 'cancelled', 'declined'].includes(booking.status)?'warning':'success'} className="text-uppercase px-3 py-2">{booking.status}</Badge>
                            </Card.Footer>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>
              :
                <Row>
                  <Col>
                    <Card as="a" href="/search">
                      <Card.Img src="/images/gnp-from-ford-cabin-on-north-fork-flathead-river.jpg" />
                      <Card.ImgOverlay className="d-flex align-items-center justify-content-center text-center">
                        <div className="mt-sm-4">
                          <h3 className="text-white mb-sm-5" style={{textShadow: '0 0 2px #000'}}>You don't have any upcoming trips, let's find one!</h3>
                          <a href="/search" className="btn btn-outline-secondary bg-black text-secondary fs-5 py-3 px-5">Search Now!</a>
                        </div>
                      </Card.ImgOverlay>
                    </Card>
                  </Col>
                </Row>
            }

          </Col>
        </Row>


        {historicGuestBookingsList &&
          historicGuestBookingsList.length > 0 &&
          <>
            <hr />

            <Row>
              <Col className="my-4">

                <h2 className="fs-4 text-dark text-decoration-underline ms-3 mb-4">My Past Trips</h2>

                <Row className="row-cols-sm-1 row-cols-lg-3 row-cols-xl-4 g-3">
                  {
                    historicGuestBookingsList.map( (booking) => {
                      return (
                        <Col key={booking._id}>
                          <Card as="a" href={`/dashboard/booking/${booking._id}`} className="h-100">
                            <Badge bg="success" className="fs-6 position-absolute top-0 end-0 mt-n2 me-n2 px-3 py-2">{moment(booking.start).format('MM/DD/YYYY')}</Badge>
                            <Card.Img variant="top" src={booking.listingData.gallery[0]?booking.listingData.gallery[0]:`https://picsum.photos/600/500?${Math.floor(Math.random()*100)}`} />
                            <Card.Body>
                              <Card.Title>{booking.listingData.title}</Card.Title>
                              <Card.Text>
                                Hosted by: <u>{booking.propertyData.title}</u>
                              </Card.Text>
                            </Card.Body>
                            <Card.Footer className="border-0 text-right p-2">
                              <Badge bg={['pending', 'cancelled', 'declined'].includes(booking.status)?'warning':'success'} className="text-uppercase px-3 py-2">{booking.status}</Badge>
                            </Card.Footer>
                          </Card>
                        </Col>
                      )
                    })
                  }
                </Row>

              </Col>
            </Row>
          </>
        }
      </>
    );
  } else {
    return (
      <div className="mt-5" style={{height: '40vh'}}>
        <Preloader message="Loading..." logoSize={50} show={true} />
      </div>
    );
  }

};
export default GuestDashboard;
