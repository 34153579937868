import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Cookies from "js-cookie";

import BookingService from "../../services/booking.service";

import { Form, Button, Alert } from "react-bootstrap";

import Preloader from "../Preloader";


export default function CheckoutForm(props) {
  let currentUserData = Cookies.get('authToken');
  let authToken = null;
  if ( currentUserData && currentUserData !== 'null' ) {
    currentUserData = JSON.parse(currentUserData).data;
    authToken = currentUserData.accessToken;
  } else {
    currentUserData = false;
  }

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [transactionSuccess, setTransactionSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret)
      .then(
        ({ paymentIntent }) => {
          switch (paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              setTransactionSuccess(true);
              break;
            case "processing":
              setMessage("Your payment is processing.");
              setTransactionSuccess(true);
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              setTransactionSuccess(false);
              break;
            default:
              setMessage("Something went wrong.");
              setTransactionSuccess(false);
              break;
          }

          // let transactionData = _.merge( props.thisBookingData.transaction, {status: paymentIntent.status} );
          let transactionData = paymentIntent;
          return transactionData;
      })
      .then( (transactionData) => {
        BookingService.updateBooking(props.thisBookingData._id, {transaction: transactionData}, authToken);
      });
  }, [stripe, props, authToken]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: process.env.REACT_APP_WEBAPP_URL_ROOT+'/dashboard/booking/'+props.thisBookingData._id }
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  return (
    <>
      {(!message && message !== 'succeeded' && message !== 'processing') &&
        <Form id="payment-form" onSubmit={handleSubmit}>
          <PaymentElement id="payment-element" />

          <div className="d-flex justify-content-evenly mt-4">
            <Button variant="success" type="submit" disabled={isLoading || !stripe || !elements} id="submit" className="px-4">
              {isLoading ? <Preloader message={false} logoSize={20} show={true} bg={false} /> : "Pay now"}
            </Button>
          </div>
        </Form>
      }

      {message &&
        <Alert variant={transactionSuccess?'success':'warning'} className="text-center">
          {message}
        </Alert>
      }
    </>

  );
}
